import { ethers } from 'ethers';
import { getAddress } from '@ethersproject/address';
import snsWebSdk from '@sumsub/websdk';
import moment from 'moment';
import showToast from 'utils/toast';
import { IPFSUris } from 'constants/ipfs.constants';
import MetamaskErrors from 'constants/errors';
//import { API_URL, FLOW_NAME } from 'constants/auth.constants';

export function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

const ONE_MIN = 60;
const ONE_HOUR = ONE_MIN * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_MONTH = ONE_DAY * 30;

const formatDiff = diff => {
  if (diff >= ONE_MONTH) {
    const m = Math.ceil(diff / ONE_MONTH);
    return `${m} mo${m > 1 ? 's' : ''}`;
  }
  if (diff >= ONE_DAY) {
    const d = Math.ceil(diff / ONE_DAY);
    return `${d} day${d > 1 ? 's' : ''}`;
  }
  if (diff >= ONE_HOUR) {
    const h = Math.ceil(diff / ONE_HOUR);
    return `${h} hr${h > 1 ? 's' : ''}`;
  }
  if (diff >= ONE_MIN) {
    const h = Math.ceil(diff / ONE_MIN);
    return `${h} min${h > 1 ? 's' : ''}`;
  }
  return `${diff} sec${diff > 1 ? 's' : ''}`;
};

export const formatDuration = time => {
  const now = new Date(Date.now());
  const convertedNow = Math.floor(now.getTime() / 1000);
  const diff = Math.abs(time - convertedNow);
  return formatDiff(diff);
};

export const formatDate = _date => {
  const date = _date ? new Date(_date) : new Date();
  const diff = Math.floor((new Date() - date.getTime()) / 1000);
  return diff > 0 ? `${formatDiff(diff)} ago` : 'just now';
};

function isValidCode(code) {
  return code in MetamaskErrors ? true : false;
}

export function shortenAddress(address, chars = 4) {
  if (!address) return '';

  const parsed = isAddress(address).toLowerCase();
  if (!parsed) {
    console.log(`Invalid 'address' parameter '${address}'.`);
    return '';
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

export const getHigherGWEI = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const price = (await provider.getGasPrice()) * 2;

  return price;
};

export const getRandomIPFS = (tokenURI, justURL = false) => {
  let random = Math.floor(Math.random() * IPFSUris.length);

  if (justURL) {
    return `${IPFSUris[random]}`;
  }

  if (
    tokenURI.includes('gateway.pinata.cloud') ||
    tokenURI.includes('cloudflare') ||
    tokenURI.includes('ipfs.io') ||
    tokenURI.includes('ipfs.infura.io')
  ) {
    return `${IPFSUris[random]}${tokenURI.split('ipfs/')[1]}`;
  } else if (tokenURI.includes('ipfs://')) {
    return `${IPFSUris[random]}${tokenURI.split('ipfs://')[1]}`;
  }

  return tokenURI;
};

export const formatNumber = num => {
  if (isNaN(num) || num === null) return '';
  let parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const formatCategory = category => {
  return category?.label || 'All';
};

export const formatError = (error, action = undefined) => {
  if (error?.data) {
    const errCode = error.data.code || error.code;
    if (isValidCode(errCode)) {
      return MetamaskErrors[String(errCode)];
    } else {
      return error.data.message;
    }
  } else {
    if (error?.message) {
      if (error.message.toString().includes('sender is not KYCed')) {
        return 'You need to get KYC authentication. You can get it in your profile.';
      } else if (
        error.message.toString().includes('user rejected transaction')
      ) {
        return action ? `${action} Rejection` : 'Reject transaction';
      } else {
        let message = error.message;
        let startIndex = message.indexOf('data');

        if (startIndex < 0) {
          if (isValidCode(error.code)) {
            return MetamaskErrors[String(error.code)];
          }
        }

        let code = String(message.substr(startIndex + 14, 6));

        if (isValidCode(code)) {
          return MetamaskErrors[code];
        }
      }
    }
  }

  return 'Error!';
};

const intlFormat = num => {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
};

export const formatFollowers = num => {
  if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
  if (num >= 1000) return intlFormat(num / 1000) + 'k';
  return intlFormat(num);
};

export const calculateGasMargin = value => {
  return (value * (10000 + 10000)) / 10000;
};

export const launchWebSdk = (accessToken, onMessage) => {
  try {
    let snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => this.getNewAccessToken())
      .withConf({
        lang: 'en',
        onMessage,
        onError: error => {
          console.error('WebSDK onError', error);
        },
      })
      .build();
    snsWebSdkInstance.launch('#sumsub-websdk-container');
  } catch (e) {
    console.log('sumsub error:', e);
  }
};

export const dateFormat = date => {
  if (date) {
    return new Intl.DateTimeFormat('en-Us', {
      dateStyle: 'short',
      timeStyle: 'long',
    }).format(new Date(date));
  }
  return null;
};

export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
  cropBanner = false,
  isBanner = false
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');
  // As a blob
  if (cropBanner) {
    if (isBanner) {
      const wantedSize = 200000;
      const originalSize = canvas.toDataURL().length;

      if (originalSize <= wantedSize) {
        return canvas.toDataURL();
      } else {
        return canvas.toDataURL(
          'image/jpeg',
          Number(Number((wantedSize * 10) / originalSize).toFixed(1))
        );
      }
    } else {
      return canvas.toDataURL();
    }
  }

  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, _) => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}

// maxDeviation is the difference that is allowed default: 50kb
// Example: targetFileSizeKb = 500 then result will be between 450kb and 500kb
// increase the deviation to reduce the amount of iterations.
export async function resizeImage(
  dataUrl,
  targetFileSizeKb,
  maxDeviation = 50
) {
  let originalFile = dataUrlToFile(dataUrl, 'test.png', 'image/png');
  if (originalFile.size / 1000 < targetFileSizeKb) return dataUrl; // File is already smaller

  let low = 0.0;
  let middle = 0.5;
  let high = 1.0;

  let result = dataUrl;

  let file = originalFile;

  while (Math.abs(file.size / 1000 - targetFileSizeKb) > maxDeviation) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const img = document.createElement('img');

    const promise = new Promise((resolve, reject) => {
      img.onload = () => resolve();
      img.onerror = reject;
    });

    img.src = dataUrl;

    await promise;

    canvas.width = Math.round(img.width * middle);
    canvas.height = Math.round(img.height * middle);
    context.scale(canvas.width / img.width, canvas.height / img.height);
    context.drawImage(img, 0, 0);
    file = dataUrlToFile(canvas.toDataURL(), 'test.png', 'image/png');

    if (file.size / 1000 < targetFileSizeKb - maxDeviation) {
      low = middle;
    } else if (file.size / 1000 > targetFileSizeKb) {
      high = middle;
    }

    middle = (low + high) / 2;
    result = canvas.toDataURL();
  }

  return result;
}

export const dataUrlToFile = (url, fileName) => {
  var arr = url.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

export const delayRun = (cb, timeout = 0) =>
  new Promise(resolve => {
    setTimeout(() => {
      cb();
      resolve();
    }, timeout);
  });

const checkForUnauthorizeErrors = res => {
  const firstError = res?.errors?.at(0);
  if (firstError?.message.toLowerCase().includes('unauthorized')) {
    showToast('error', 'Please log in');
  }
};

const checkForThrottlerErrors = res => {
  const firstError = res?.errors?.at(0);
  if (firstError?.message.toLowerCase().includes('throttler')) {
    showToast('error', 'Please wait and retry shortly!');
  }
};

const extractData = (res, name) => ({
  ...res,
  data: res.data?.[name] ?? {},
});

export const getResponseData = (res, name) => {
  checkForThrottlerErrors(res);
  checkForUnauthorizeErrors(res);
  return extractData(res, name);
};

export function uuidv4() {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
}

export const getItemsInPeriod = (
  items,
  fromDate,
  toDate,
  datetimeFieldName = 'datetime'
) => {
  const start = moment(fromDate);
  const end = moment(toDate);

  return items.filter(item => {
    const itemDate = moment(item[datetimeFieldName]);
    return itemDate.isSameOrAfter(start) && itemDate.isSameOrBefore(end);
  });
};

export const getOverallGainOrLoss = (items, field) => {
  if (items.length <= 1) {
    return 'gain';
  }

  const first = items[0][field];
  const last = items[items.length - 1][field];

  if (last >= first) {
    return 'gain';
  } else {
    return 'loss';
  }
};

export function getSourceFileName(sourceFile) {
  return sourceFile
    .split('?')[0]
    .split('-')
    .pop();
}
export function isImageFile(fileName) {
  const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp'];

  const extension = fileName
    .split('.')
    .pop()
    .toLowerCase()
    .split('?')[0];

  return imageExtensions.includes(extension);
}

export function getWsConnection() {
  const SOCKET_SERVER_URL = process.env.REACT_APP_REST_BASE_URL.replace(
    'https',
    'wss'
  ).replace('http', 'ws');
  const NAMESPACE = 'events'; // webSocket namespace

  return `${SOCKET_SERVER_URL}${NAMESPACE}`;
}

export const notificationBadgeContent = totalNotifications =>
  `${totalNotifications > 9 ? '9+' : totalNotifications}`;

export function isVideoFormat(videoUrl) {
  const videoExtensions = [
    'mp4',
    'webm',
    'ogg',
    'mov',
    'avi',
    'wmv',
    'flv',
    '3gp',
    'mkv',
  ];

  const extensionMatch = videoUrl?.match(/\.([^.]+)(?=[^.]*$)/i);
  if (extensionMatch) {
    const extension = extensionMatch[1].split('?')[0].toLowerCase();
    return videoExtensions.includes(extension);
  }

  return false;
}

export function isAudioFormat(audioUrl) {
  const audioExtensions = [
    'mp3',
    'wav',
    'ogg',
    'flac',
    'aac',
    'm4a',
    'wma',
    'alac',
    'aiff',
  ];

  const extensionMatch = audioUrl?.match(/\.([^.]+)(?=[^.]*$)/i);

  if (extensionMatch) {
    const extension = extensionMatch[1].toLowerCase();
    return audioExtensions.includes(extension);
  }

  return false;
}

export function isZipFile(url) {
  const extensionMatch = url?.match(/\.([^.]+)(?=[^.]*$)/i);

  if (extensionMatch) {
    const extension = extensionMatch[1].toLowerCase();
    return extension === 'zip';
  }

  return false;
}

export function getFileType(url) {
  const fileTypes = {
    jpg: 'image',
    jpeg: 'image',
    png: 'image',
    svg: 'image',
    bmp: 'image',
    tiff: 'image',
    mov: 'video',
    mp4: 'video',
    avi: 'video',
    mkv: 'video',
    wmv: 'video',
    flv: 'video',
    webm: 'video',
    m4v: 'video',
    mp3: 'audio',
    wav: 'audio',
    flac: 'audio',
    ogg: 'audio',
    aac: 'audio',
    m4a: 'audio',
    wma: 'audio',
    alac: 'audio',
    aiff: 'audio',
    zip: 'document',
  };

  return fileTypes[
    url
      .split('.')
      .at(-1)
      .toLowerCase()
  ];
}

export function getFileExtension(url, headers) {
  if (url) {
    return url.split('.').at(1);
  }
  return headers['content-type'].split('/').at(1);
}

export function fixUrl(url) {
  return url?.replace(/(\w+:\/\/[^/]+\/)|([^/]+)/g, (_, p1, p2) => {
    if (p1) return p1;
    return encodeURIComponent(p2);
  });
}

export function extractFilename(filePath) {
  // Sample URL: users/ebe0528d-d4a1-4b5b-8513-9d950158a8f5/res/1724295304685-nft (1).mp4
  const match = filePath.match(/\/\d+-(.+)$/);

  if (match && match[1]) {
    return match[1];
  }

  return null;
}

export const isEthereumAddress = address => {
  return address && /^0x[a-fA-F0-9]{40}$/.test(address);
};

export const nameRegex = /^[^!@#$%^&*(){}[\]`'".,?+=<>:;|/\\~]*$/;

export const emailRex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

export const handleOpenPlatform = platform => {
  switch (platform) {
    case 'twitter':
      window.open(
        process.env.REACT_APP_TWITTER,
        '_blank',
        'noopener,noreferrer'
      );
      return;
    case 'instagram':
      window.open(
        process.env.REACT_APP_INSTAGRAM,
        '_blank',
        'noopener,noreferrer'
      );
      return;
    case 'discord':
      window.open(
        process.env.REACT_APP_DISCORD,
        '_blank',
        'noopener,noreferrer'
      );
      return;
    case 'telegram':
      window.open(
        process.env.REACT_APP_TELEGRAM,
        '_blank',
        'noopener,noreferrer'
      );
      return;
  }
};

export function truncateBefore(str, length, delimiter) {
  return str.substring(0, str.lastIndexOf(' ', length)) + delimiter;
}

export function replaceLinksWithAnchors(text) {
  const urlPattern = /https?:\/\/[^\s]+/g; // Regular expression to find URLs
  return text.replace(urlPattern, url => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });
}
