import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import persistStore from './persistStore';
import persistReducer from './persistReducer';

export default (initialState = {}) => {
  const store = createStore(
    persistReducer(),
    initialState,
    compose(applyMiddleware(thunkMiddleware))
  );

  store.asyncReducers = {};

  const persistor = persistStore(store);

  return { store, persistor };
};
