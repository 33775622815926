import React from 'react';
import cx from 'classnames';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Chip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FilterActions from 'actions/filter.actions';
import IconStatus from 'assets/icons/iconStatus';

import {
  FilterStatusFullNameMap,
  FilterStatus,
} from 'constants/filter.constants';

const ExploreStatus = () => {
  const dispatch = useDispatch();

  const { status } = useSelector(state => state.Filter);

  const handleStatusChange = field => {
    dispatch(FilterActions.updateStatusFilter(field));
  };

  return (
    <Box
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'var(--place-holder-color)',
          color: 'var(--primary-text-color)',
          '& svg': {
            fill: 'var(--primary-text-color)',
          },
        },
        '& .MuiAccordionSummary-content': {
          display: 'flex',
          alignItems: 'center',
          gap: '2px',

          '& svg': {
            scale: '0.7',
          },
          '& .MuiTypography-root': {
            fontWeight: 500,
            fontSize: 15,
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        '& .MuiAccordionDetails-root': {
          boxShadow: 'none',
          m: 0,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          '& .MuiButtonBase-root': {
            height: 25,
            fontWeight: 500,
          },
        },
      }}
    >
      <Accordion
        sx={{
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <IconStatus />
          <Typography>Status</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '.MuiChip-root': {
              bgcolor: 'var(--secondary-background-color)',
              color: 'var(--primary-text-color)',
            },
            '& .MuiChip-root.selected': {
              bgcolor: 'var(--primary-color)',
              '&:hover': {
                bgcolor: 'var(--primary-color)',
              },
            },
          }}
        >
          <Chip
            label={FilterStatusFullNameMap.get(FilterStatus.BUY_NOW)}
            onClick={() => handleStatusChange(FilterStatus.BUY_NOW)}
            className={cx({ selected: status.includes(FilterStatus.BUY_NOW) })}
          />
          <Chip
            label={FilterStatusFullNameMap.get(FilterStatus.HAS_BIDS)}
            onClick={() => handleStatusChange(FilterStatus.HAS_BIDS)}
            className={cx({ selected: status.includes(FilterStatus.HAS_BIDS) })}
          />
          <Chip
            label={FilterStatusFullNameMap.get(FilterStatus.HAS_OFFERS)}
            onClick={() => handleStatusChange(FilterStatus.HAS_OFFERS)}
            className={cx({
              selected: status.includes(FilterStatus.HAS_OFFERS),
            })}
          />
          <Chip
            label={FilterStatusFullNameMap.get(FilterStatus.ON_AUCTION)}
            onClick={() => handleStatusChange(FilterStatus.ON_AUCTION)}
            className={cx({
              selected: status.includes(FilterStatus.ON_AUCTION),
            })}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ExploreStatus;
