import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FilterActions from 'actions/filter.actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutocompleteToken from 'components/AutocompleteToken';
import IconEth from 'assets/icons/iconEth';

const TokenFilter = ({ payTokens }) => {
  const dispatch = useDispatch();

  const { paytoken } = useSelector(state => state.Filter);

  const handleAutocompleteSelection = vals => {
    dispatch(FilterActions.updatePayTokenFilter(vals));
  };
  return (
    <Box
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'var(--place-holder-color)',
          color: 'var(--primary-text-color)',
          '& svg': {
            fill: 'var(--primary-text-color)',
          },
        },
        '& .MuiAccordionSummary-content': {
          display: 'flex',
          alignItems: 'center',
          gap: '2px',

          '& svg': {
            scale: '0.7',
            stroke: 'var(--primary-text-color)',
            fill: 'var(--primary-text-color)',
          },
          '& .MuiTypography-root': {
            fontWeight: 500,
            fontSize: 15,
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        '& .MuiAccordionDetails-root': {
          boxShadow: 'none',
          m: 0,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          '& .MuiButtonBase-root': {
            height: 25,
            fontWeight: 500,
          },
        },
      }}
    >
      <Accordion
        sx={{
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <IconEth />
          <Typography>Meme Tokens</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '& .MuiChip-root.selected': {
              bgcolor: 'var(--primary-color)',
              '&:hover': {
                bgcolor: 'var(--darker-primary-color)',
              },
            },
          }}
        >
          <AutocompleteToken
            tokens={payTokens}
            onSelect={handleAutocompleteSelection}
            value={paytoken}
            displayField="name"
            multiple
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TokenFilter;
