import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  IconButton,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatDate } from 'utils';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from 'api';
import NotiActions from 'actions/noti.actions';

import styles from '../styles.module.scss';

const NotificationItem = React.memo(({ notification, onNotificationRead }) => {
  const handleActionClick = () => {
    if (notification.link) window.location.href = notification.link;
    onNotificationRead(notification);
  };

  return (
    <MenuItem
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 0,
        '&:hover button': {
          borderColor: '#d9c8ff',
        },
      }}
      onClick={handleActionClick}
    >
      <ListItemText
        sx={{
          whiteSpace: 'normal',
          fontSize: '12px',
          '& span': {
            color: 'var(--primary-color) !important',
            fontWeight: notification.status === 'new' ? 600 : 500,
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <Box>{notification.title}</Box>
        {notification.status === 'new' && (
          <Box sx={{ position: 'absolute', right: 5, top: 5 }}>
            <Tooltip
              title="Mark as read"
              arrow
              placement="left"
              componentsProps={{
                popper: {
                  sx: {
                    zIndex: 99999,
                  },
                },
              }}
            >
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onNotificationRead(notification);
                }}
                sx={{
                  border: '1px solid var(--background-color)',
                  p: 1.4,
                }}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: 'var(--primary-color)',
                    position: 'absolute',
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </ListItemText>
      <ListItemText sx={{ whiteSpace: 'normal' }}>
        {notification.message}
      </ListItemText>
      <Typography
        variant="caption"
        sx={{
          display: 'block',
          color: 'gray',
          fontSize: '10px !important',
        }}
      >
        {formatDate(notification.createdAt)}
      </Typography>
    </MenuItem>
  );
});

NotificationItem.displayName = 'NotificationItem';

const MemoizedNotificationItems = ({ setOpenDrawer, onNotificationRead }) => {
  const dispatch = useDispatch();
  const observerRef = useRef(null);
  const { findAllUserNotifications } = useApi();

  const { authToken } = useSelector(state => state.Auth);
  const { notifications, from, count, total } = useSelector(
    state => state.Notifications
  );

  const totalUnread = notifications.filter(a => a.status === 'new').length;

  const fetchMoreNotifications = useCallback(
    async (from, count) => {
      if (!authToken) return;

      const { data } = await findAllUserNotifications(authToken, {
        from,
        count,
      });

      if (data) {
        dispatch(NotiActions.fetchNotifications(data));
      }
    },
    [authToken]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && totalUnread < total) {
          fetchMoreNotifications(from, count);
        }
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [fetchMoreNotifications, from, count, total, totalUnread]);

  const emptyNotification = useMemo(
    () =>
      !notifications.length && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center !important',
            gap: 0,
            width: '100%',
            cursor: 'default',
            height: '60px',
            bgcolor: 'white',
            '&:hover': {
              bgcolor: 'white',
            },
          }}
        >
          <Box
            sx={{
              color: '#000000de !important',
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
            }}
          >
            No new notifications
          </Box>
        </Box>
      ),
    [notifications.length]
  );

  const viewAllLink = useMemo(
    () => (
      <Box className={styles.viewAll}>
        <Link to="/profile/notifications">
          <MenuItem
            className="view-all"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0,
              mt: 1,
              bgcolor: '#f3edff',
              '&:hover': {
                bgcolor: '#ebe3fd',
                '& button': {
                  borderColor: '#d9c8ff',
                },
              },
              '& .MuiTouchRipple-child': {
                backgroundColor: 'blue !important',
              },
            }}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText
              sx={{
                whiteSpace: 'normal',
                fontSize: '12px',
                '& span': {
                  color: 'var(--primary-color) !important',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
            >
              View All
            </ListItemText>
          </MenuItem>
        </Link>
      </Box>
    ),
    [setOpenDrawer]
  );

  return (
    <>
      {notifications.map((notification, idx) => (
        <NotificationItem
          key={idx}
          notification={notification}
          onNotificationRead={onNotificationRead}
        />
      ))}
      <div ref={observerRef}></div>
      {emptyNotification}
      {viewAllLink}
    </>
  );
};

export default React.memo(MemoizedNotificationItems, (prevProps, nextProps) => {
  return prevProps.setOpenDrawer === nextProps.setOpenDrawer;
});
