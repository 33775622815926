import React, { useState } from 'react';
import styles from '../../styles.module.scss';
import BootstrapTooltip from 'components/BootstrapTooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputMedia from 'components/InputMedia';
import { isImageFile, isVideoFormat, isZipFile } from 'utils';
import { LinearProgress } from '@mui/material';
import { StyledButton } from 'components/StyledComponents';
import { ASSET_TYPES } from 'constants/asset.constants';
import { assetTransform } from 'pages/Create/utils';

const Asset = ({
  frontCover,
  setFrontCover,
  imageFrontCover,
  setImageFrontCover,
  waitingUploadThumbnailImage,
  uploadWatermarkedFrontCover,
  handleUseSourceFileAsPreview,
  removeAssetFrontCover,
  kycRequired,
  validateError,
  assetsToCheck,
  selectedImage,
  setSelectedImage,
  corruptedItemsErrors,
  setOpenCorruptedItemsModal,
}) => {
  const [frontCoverProgress, setFrontCoverProgress] = useState({});
  const [openImageSelection, setOpenImageSelection] = useState(false);

  const waitingUploadFrontCover = Object.keys(frontCoverProgress).length !== 0;
  const frontCoverBars = Object.entries(frontCoverProgress).map(el => {
    return (
      !!el[1] && (
        <LinearProgress variant="determinate" key={el[0]} value={el[1]} />
      )
    );
  });
  const renderSourceFileAction = () => {
    if (frontCover.length === 1 && isVideoFormat(frontCover[0].url)) {
      return <></>;
    }
    if (Object.keys(imageFrontCover).length > 0) {
      if (openImageSelection) {
        return (
          <div className={styles.buttonGroup}>
            <StyledButton
              onClick={async () => {
                await handleUseSourceFileAsPreview(
                  imageFrontCover[selectedImage]
                );
                setOpenImageSelection(false);
              }}
              disabled={waitingUploadThumbnailImage}
            >
              Confirm
            </StyledButton>
            <StyledButton
              secondary="true"
              onClick={() => setOpenImageSelection(false)}
              disabled={waitingUploadThumbnailImage}
            >
              Cancel
            </StyledButton>
          </div>
        );
      }
      if (Object.keys(imageFrontCover).length === 1) {
        return (
          <StyledButton
            className={styles.textButton}
            secondary="true"
            onClick={async () => {
              await handleUseSourceFileAsPreview(
                Object.values(imageFrontCover)[0]
              );
              setOpenImageSelection(false);
            }}
            disabled={waitingUploadThumbnailImage}
          >
            Use source file as a thumbnail
          </StyledButton>
        );
      } else {
        return (
          <StyledButton
            className={styles.textButton}
            secondary="true"
            onClick={() => setOpenImageSelection(true)}
            disabled={waitingUploadThumbnailImage}
          >
            Choose a source file as a thumbnail
          </StyledButton>
        );
      }
    }
    return <></>;
  };

  const updateFrontCover = asset => {
    if (isImageFile(asset.url)) {
      uploadWatermarkedFrontCover(asset, data => {
        const convertedAsset = {
          ...asset,
          relatedAssets: [data],
        };

        setFrontCover(prev =>
          prev.map(item => {
            if (item.assetId === convertedAsset.assetId) {
              return convertedAsset;
            } else {
              return item;
            }
          })
        );
        setImageFrontCover(prevState => ({
          ...prevState,
          [`${convertedAsset.assetId}`]: convertedAsset,
        }));
      });
    }
    setFrontCover(prevState => [...prevState, ...assetTransform([asset])]);
  };

  return (
    <div className={styles.uploadContainer}>
      <div className={styles.uploadTitle}>
        Asset{frontCover.length > 1 ? 's' : ''}*&nbsp;
        <BootstrapTooltip
          title="This is the asset you are selling."
          placement="top"
        >
          <HelpOutlineIcon />
        </BootstrapTooltip>
      </div>
      <InputMedia
        key={'FrontCover'}
        changeFunction={asset => updateFrontCover(asset)}
        assetType={ASSET_TYPES.FRONT_COVER}
        multiple={frontCover.length === 0 || !isZipFile(frontCover[0].url)}
        setProgressUpload={setFrontCoverProgress}
        waitingUpload={waitingUploadFrontCover}
        data={frontCover}
        removeCb={removeAssetFrontCover}
        disabled={kycRequired}
        hasError={validateError}
        assetsToCheck={assetsToCheck}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        openImageSelection={openImageSelection}
      />
      {renderSourceFileAction()}
      {corruptedItemsErrors?.length ? (
        <StyledButton
          className={styles.textButton}
          error="true"
          onClick={() => setOpenCorruptedItemsModal(true)}
          disabled={waitingUploadThumbnailImage}
        >
          Click to see a list of corrupted items
        </StyledButton>
      ) : (
        <></>
      )}
      <div className={styles.fullBarLoader}>{frontCoverBars}</div>
    </div>
  );
};

export default Asset;
