export default {
  SHOW_ACCOUNT_MODAL: 'SHOW_ACCOUNT_MODAL',
  HIDE_ACCOUNT_MODAL: 'HIDE_ACCOUNT_MODAL',
  SHOW_WFTM_MODAL: 'SHOW_WFTM_MODAL',
  HIDE_WFTM_MODAL: 'HIDE_WFTM_MODAL',
  SHOW_CONNECT_WALLET_MODAL: 'SHOW_CONNECT_WALLET_MODAL',
  SHOW_MOONPAY_MODAL: 'SHOW_MOONPAY_MODAL',
  HIDE_MOONPAY_MODAL: 'HIDE_MOONPAY_MODAL',
  UPDATE_MOONPAY_AMOUNT: 'UPDATE_MOONPAY_AMOUNT',
  HIDE_CONNECT_WALLET_MODAL: 'HIDE_CONNECT_WALLET_MODAL',
  SHOW_PHOTO_CAROUSEL_MODAL: 'SHOW_PHOTO_CAROUSEL_MODAL',
  HIDE_PHOTO_CAROUSEL_MODAL: 'HIDE_PHOTO_CAROUSEL_MODAL',
  SHOW_CSV_REPORT: 'SHOW_CSV_REPORT',
  HIDE_CSV_REPORT: 'HIDE_CSV_REPORT',
};
