import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import FilterActions from 'actions/filter.actions';
import { getContractTypeById } from 'constants/contracts.constants';
import { FilterStatusFullNameMap } from 'constants/filter.constants';

import './styles.css';

const FilterItem = ({ item, clearAction }) => {
  return (
    <div className="filterCollectionItem">
      <span className="filterCollectionItemName">{item}</span>
      <CloseIcon
        alt="close"
        className="filterCollectionRemoveItem"
        onClick={() => clearAction()}
      />
    </div>
  );
};

const ExploreFilterHeader = ({ searchKey, clearSearchKey }) => {
  const dispatch = useDispatch();
  const { status, category, license, paytoken, community } = useSelector(
    state => state.Filter
  );

  const handleClearCategoryFilter = categoryItem => {
    if (category.length > 0) {
      dispatch(FilterActions.updateCategoryFilter(categoryItem));
    }
  };

  const handleClearSearchPhrase = () => {
    if (clearSearchKey) {
      clearSearchKey();
    }
  };

  const handleClearLicenseFilter = licenseItem => {
    if (license) {
      dispatch(FilterActions.updateLicenseFilter(licenseItem));
    }
  };

  const handleClearStatusFilter = statusItem => {
    if (status.length > 0) {
      dispatch(FilterActions.updateStatusFilter(statusItem));
    }
  };

  const handleClearPayTokenFilter = paytokenItem => {
    if (paytokenItem) {
      dispatch(FilterActions.updatePayTokenFilter(paytokenItem));
    } else {
      dispatch(FilterActions.resetPayTokenFilter());
    }
  };

  const handleClearCommunityFilter = communityItem => {
    if (communityItem) {
      dispatch(FilterActions.updateCommunityFilter(communityItem));
    } else {
      dispatch(FilterActions.resetCommunityFilter());
    }
  };

  const handleClearAllFilters = () => {
    handleClearCategoryFilter(null);
    handleClearStatusFilter(null);
    handleClearLicenseFilter(null);
    handleClearPayTokenFilter(null);
    handleClearCommunityFilter(null);
  };

  const formatStatus = status => FilterStatusFullNameMap.get(status);

  return (
    <div className="filterHeaderContainer">
      <div className="filterHeaderRoot">
        <div className="filterHeaderLeft">
          {searchKey ? (
            <FilterItem
              item={searchKey}
              clearAction={() => handleClearSearchPhrase()}
            />
          ) : null}
          {status.length
            ? status.map((item, idx) => (
                <FilterItem
                  key={idx}
                  item={formatStatus(item)}
                  clearAction={() => handleClearStatusFilter(item)}
                />
              ))
            : null}
          {license ? (
            <FilterItem
              item={getContractTypeById(license).label}
              clearAction={() => handleClearLicenseFilter(null)}
            />
          ) : null}
          {category.length > 0
            ? category.map((item, idx) => (
                <FilterItem
                  key={idx}
                  item={item?.type}
                  clearAction={() => handleClearCategoryFilter(item)}
                />
              ))
            : null}
          {paytoken.length > 0
            ? paytoken.map((item, idx) => (
                <FilterItem
                  key={idx}
                  item={item?.name}
                  clearAction={() => handleClearPayTokenFilter(item)}
                />
              ))
            : null}
          {community.length > 0
            ? community.map((item, idx) => (
                <FilterItem
                  key={idx}
                  item={item?.community?.name}
                  clearAction={() => handleClearCommunityFilter(item)}
                />
              ))
            : null}
          {status.length > 0 ||
          category.length > 0 ||
          paytoken.length > 0 ||
          license ? (
            <div
              className="filterClearAllFiltersLabel"
              onClick={() => handleClearAllFilters()}
            >
              Clear All Filters
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ExploreFilterHeader;
