import React, { useState } from 'react';
import Modal from '../Modal';
import styles from './styles.module.scss';
import { useAppKitAccount } from '@reown/appkit/react';

import cx from 'classnames';
import { StyledButton } from 'components/StyledComponents';
import { MoonPayBuyWidget } from '@moonpay/moonpay-react';
import { useSelector } from 'react-redux';

const MoonPayModal = ({ args, visible, onClose }) => {
  const { address: account } = useAppKitAccount();
  const [openMoonPay, setOpenMoonPay] = useState(false);
  const { mode } = useSelector(state => state.HeaderOptions);
  const handleDisconnect = () => {
    onClose();
  };

  return (
    <>
      <Modal
        visible={visible}
        onClose={handleDisconnect}
        submitDisabled={true}
        showTitle={false}
      >
        <div
          className={styles.title}
        >{`You don't have enough ${args.symbol.toUpperCase()} in your wallet to complete the transaction`}</div>
        <div className={styles.subtitleContainer}>
          <div className={styles.subtitle}>
            Would you like to add funds to your wallet now?
          </div>
        </div>
        <div className={styles.footer}>
          <StyledButton
            target="_blank"
            rel="noreferrer"
            className={cx(styles.button, styles.cancel)}
            onClick={() => onClose()}
            cancel="true"
          >
            Cancel
          </StyledButton>
          <StyledButton
            className={cx(styles.button, styles.save)}
            onClick={async () => {
              setOpenMoonPay(true);
            }}
          >
            Buy
          </StyledButton>
        </div>
      </Modal>
      <MoonPayBuyWidget
        variant="overlay"
        theme={mode}
        baseCurrencyCode="usd"
        quoteCurrencyAmount={parseFloat(args.price).toFixed(4)}
        defaultCurrencyCode={args.symbol}
        onLogin={async () => console.log('Customer logged in!')}
        visible={openMoonPay}
        onCloseOverlay={() => {
          setOpenMoonPay(false);
          onClose();
        }}
        walletAddress={account}
      />
    </>
  );
};

export default React.memo(MoonPayModal, (prevProps, nextProps) => {
  return (
    prevProps.args.price === nextProps.args.price &&
    prevProps.args.symbol === nextProps.args.symbol &&
    prevProps.visible === nextProps.visible
  );
});
