import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import StatusFilter from 'components/StatusFilter';
import CategoriesFilter from 'components/CategoriesFilter';
import LicenseFilter from 'components/LicenseFilter';

import ExploreFilterHeader from './Body/FilterHeader';
import MasonaryNFTs from 'components/MasonaryNFTs';
import SortByButton from 'components/SortByButton';

import { useApi } from '../../api';
import Header from 'components/header';
import FooterFS from 'components/Footer';
import HeaderActions from 'actions/header.actions';

import styles from './styles.module.scss';
import FiltersButton from 'components/FiltersButton';
import TokenFilter from 'components/TokenFilter';
import CommunityFilter from 'components/CommunityFilter';

const ExploreAllPage = () => {
  const { key } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const { getCategories, getContractTypes, fetchPayTokens } = useApi();

  const [categories, setCategories] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [payTokens, setPayTokens] = useState([]);

  const [nftTab, setNftTab] = useState('createdAt');
  const [loading, setLoading] = useState(true);

  const { upFetching, downFetching } = useSelector(state => state.Tokens);
  const { category, status, license, paytoken, community } = useSelector(
    state => state.Filter
  );
  const { authToken } = useSelector(state => state.Auth);

  useEffect(() => {
    window.scrollTo(0, 0);

    // init lookups
    (async () => {
      const { data: categories } = await getCategories();
      const { data: contractTypes } = await getContractTypes();
      const { data: payTokens } = await fetchPayTokens();

      setCategories(categories);
      setContractTypes(contractTypes);
      setPayTokens(
        payTokens.map(a => ({
          payTokenId: a.payTokenId,
          icon: a.icon,
          name: a.name,
          symbol: a.symbol,
          address: a.address,
          community: a.community,
        }))
      );
    })();
  }, []);

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
  }, []);

  return (
    <div>
      <Header isSticky="sticky" />
      <div
        className={cx(
          styles.container,
          (upFetching || downFetching) && styles.containerLoading
        )}
      >
        <div className={cx(styles.sidebar)}>
          <div className={styles.sidebarHeader}>
            <div className={styles.sidebarTitle}>Filters</div>
          </div>
          <div className={styles.filterContainer}>
            <div className={styles.filterList}>
              <StatusFilter />
              <CategoriesFilter categories={categories} />
              <TokenFilter payTokens={payTokens} />
              <CommunityFilter payTokens={payTokens} />
              <LicenseFilter contractTypes={contractTypes} />
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.filterHeader}>
            <ExploreFilterHeader
              searchKey={key}
              clearSearchKey={() => {
                history.push('/explore/');
              }}
            />

            <div className={styles.buttons}>
              <FiltersButton
                className={styles.filtersButton}
                categories={categories}
                contractTypes={contractTypes}
                authToken={authToken}
                payTokens={payTokens}
              />

              <SortByButton
                className={styles.sortByButton}
                sortBy={nftTab}
                setSortBy={tab => setNftTab(tab)}
              />
            </div>
          </div>
          <div className={styles.exploreAll}>
            <MasonaryNFTs
              sortBy={nftTab}
              setSortBy={tab => setNftTab(tab)}
              setContainerLoading={loading ? setLoading : null}
              category={category}
              status={status}
              searchKey={key}
              license={license}
              paytoken={paytoken}
              community={community}
            />
          </div>
        </div>
      </div>
      <FooterFS hideFooter={authToken} />
    </div>
  );
};

export default ExploreAllPage;
