import Input from 'components/Input';
import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { ClipLoader } from 'react-spinners';
import { StyledButton } from 'components/StyledComponents';
import { useApi } from 'api';
import showToast from 'utils/toast';
import PasswordChecklist from 'react-password-checklist';
import PasswordInput from 'components/Input/PasswordInput';

function index({ authToken }) {
  const { checkPassword, postSetNewPassword } = useApi();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState(null);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(null);
  const [reseting, setReseting] = useState(false);
  const [message, setMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const confirmButton = useRef(null);

  const handleSetPassword = async () => {
    setReseting(true);
    try {
      const checkPassResponse = await checkPassword(oldPassword, authToken);
      if (checkPassResponse.errors && checkPassResponse.errors.length > 0) {
        setOldPasswordError('Old password is invalid');
        setReseting(false);

        return;
      }

      if (newPassword !== confirmNewPassword) {
        setConfirmNewPasswordError("Password doesn't match");
        setReseting(false);

        return;
      }

      const newPasswordResponse = await postSetNewPassword(
        newPassword,
        authToken
      );
      if (
        newPasswordResponse?.errors &&
        newPasswordResponse?.errors.length > 0
      ) {
        showToast('error', newPasswordResponse?.errors[0].message);
        return;
      } else {
        setMessage('New password is set');
      }
    } catch (error) {
      showToast('error', 'There is an issue. Please try again');
    }

    setReseting(false);
  };

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        confirmButton.current.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return (
    <div className={styles.inputGroup}>
      <div className={styles.inputWrapper}>
        <Input
          label="Old password*"
          hasError={oldPasswordError}
          placeholder=""
          value={oldPassword}
          type="password"
          onChange={e => {
            setOldPassword(e.target.value);
          }}
        />
        {oldPasswordError && (
          <div className={styles.error}>{oldPasswordError}</div>
        )}
      </div>
      <div className={styles.inputWrapper}>
        <PasswordInput
          label="New password*"
          placeholder=""
          value={newPassword}
          onChange={e => {
            setNewPassword(e.target.value);
          }}
        />
      </div>
      <div className={styles.inputWrapper}>
        <PasswordInput
          label="Confirm password*"
          hasError={confirmNewPasswordError}
          placeholder=""
          value={confirmNewPassword}
          onChange={e => setConfirmNewPassword(e.target.value)}
        />
        {confirmNewPasswordError && (
          <div className={styles.error}>{confirmNewPasswordError}</div>
        )}
      </div>
      <div className={styles.successful}>{message}</div>
      <PasswordChecklist
        rules={['minLength', 'number', 'capitalAndLowercase', 'match']}
        minLength={6}
        value={newPassword}
        valueAgain={confirmNewPassword}
        onChange={_isValid => {
          setIsValid(_isValid);
        }}
        className={styles.passwordCheckList}
      />
      <div className={styles.btnGroup}>
        <StyledButton
          onClick={oldPassword.length > 0 && isValid ? handleSetPassword : null}
          disabled={!isValid || oldPassword.length <= 0}
          ref={confirmButton}
        >
          {reseting ? (
            <ClipLoader color="#FFF" size={16} />
          ) : (
            'Reset your password'
          )}
        </StyledButton>
      </div>
    </div>
  );
}

export default index;
