export const ASSETTYPES = {
  FRONT_COVER: 1,
  PHOTOS: 2,
  THUMBNAIL: 3,
  CROPPED_THUMBNAIL: 4,
  ADDITIONAL_DOCUMENTS: 5,
  ACTOR_MODEL_RELEASES: 6,
  WATERMARKED_THUMBNAIL_IMAGE: 7,
  THUMBNAIL_VIDEO: 8,
  WATERMARKED_THUMBNAIL_VIDEO: 9,
  TRIMMED_AUDIO: 10,
  DOWNSIZED_AUDIO: 11,
  WATERMARKED_SOURCE_FILE: 12,
  ICON: 13,
};
