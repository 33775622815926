import React from 'react';

const IconEth = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <polyline
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="9.5,16 16,5.17 22.5,16 16,26.83"
      />
      <line
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="16"
        y1="26.83"
        x2="9.5"
        y2="16"
      />
      <line
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="17.99"
        y1="13.74"
        x2="22.5"
        y2="16"
      />
      <line
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="9.5"
        y1="16"
        x2="14.01"
        y2="13.74"
      />
      <polygon
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="16,26.83 9.5,16 16,19.25 22.5,16"
      />
      <line
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="16"
        y1="18.87"
        x2="16"
        y2="9"
      />
      <polygon
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="16,5.17 9.5,16 16,26.83 22.5,16"
      />
      <circle
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        cx="16"
        cy="16"
        r="14.5"
      />
    </g>
  </svg>
);
export default IconEth;
