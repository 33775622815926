import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { signal } from '@preact/signals-react';

export const totalCommentsCount = signal(0);

export const getReplyText = replies =>
  replies === 1 ? '1 Reply' : `${replies} Replies`;

export const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  bgcolor: 'white',
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  backgroundColor: 'white',
  flexDirection: 'row-reverse',
  padding: 0,
  '&.MuiAccordionSummary-root': {
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content': {
    cursor: 'default',
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  '&.MuiAccordionDetails-root': {
    padding: 0,
  },
}));
