import HeaderConstants from '../constants/header.constants';
/*
    store headerbar related options, like toggle search bar status, 
*/

export function HeaderOptions(
  state = { isShown: false, mode: 'dark' },
  action
) {
  switch (action.type) {
    case HeaderConstants.TOGGLESEARCHBAR: {
      return {
        ...state,
        isShown: action.toggle,
      };
    }
    case HeaderConstants.TOGGLE_MODE: {
      return {
        ...state,
        mode: action.toggle,
      };
    }
    default: {
      return state;
    }
  }
}
