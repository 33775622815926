import React, { useState } from 'react';
import IconReport from 'assets/icons/nft/iconReport';
import { Box, Drawer, IconButton, Menu, MenuItem, styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const SubMenu = styled('div')({
  position: 'relative',
  width: '100%',
});

const SubMenuItems = styled('div')({
  display: 'block',
  position: 'absolute',
  right: '100%',
  top: 0,
  transform: 'translateX(-5px)',
  backgroundColor: 'var(--background-color)',
  borderRadius: 'var(--border-radius)',
  border: '1px solid var(--border-color)',
  overflow: 'hidden',
  zIndex: 1,
  width: 250,
  '& .MuiMenuItem-root': {
    padding: '10px 5px 10px 15px',
    '&:hover': {
      backgroundColor: 'var(--secondary-background-color)',
    },
  },
});

const violationItems = ['Poor image quality', 'Inappropriate image'];

const ActionMenu = ({ isMobile, onNftFlagged }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuEl, setSubmenuEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubmenuEl(null);
  };

  const handleNftFlag = async vioaltion => {
    onNftFlagged && (await onNftFlagged(vioaltion));
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          mt: 1.2,
          color: 'var(--primary-text-color)',
        }}
      >
        <MoreVertIcon />
      </IconButton>
      {!isMobile ? (
        <Menu
          anchorEl={anchorEl}
          id="action-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              width: 200,
              minWidth: 200,
              overflow: 'visible',
              filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.32))',
              mt: 1,
              '& .MuiAvatar-root': {
                width: 36,
                height: 36,
                ml: -0.5,
                mr: 1,
              },
              '& .MuiMenuItem-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <SubMenu
            sx={{
              '& .MuiMenu-paper': {
                width: 100,
              },
            }}
          >
            <MenuItem
              onClick={e => setSubmenuEl(prev => (!prev ? e.target : null))}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <IconReport /> Report
              </Box>
              <ArrowRightIcon
                sx={{
                  color: 'lightgray',
                }}
              />
            </MenuItem>
            {submenuEl && (
              <SubMenuItems>
                {violationItems.map((item, idx) => (
                  <MenuItem key={idx} onClick={() => handleNftFlag(item)}>
                    {item}
                  </MenuItem>
                ))}
              </SubMenuItems>
            )}
          </SubMenu>
        </Menu>
      ) : (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiDrawer-paper': {
              borderRadius: '10px 10px 0 0',
              overflow: 'hidden',
              pt: 0.5,
            },
            '& .MuiAvatar-root': {
              width: 36,
              height: 36,
              ml: -0.5,
              mr: 1,
            },
            '& .MuiMenuItem-root': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            },
          }}
        >
          <SubMenu
            sx={{
              '& .MuiMenu-paper': {
                width: 100,
              },
            }}
          >
            {!submenuEl ? (
              <MenuItem
                onClick={e => setSubmenuEl(prev => (!prev ? e.target : null))}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <IconReport /> Report
                </Box>
                <ArrowRightIcon
                  sx={{
                    color: 'lightgray',
                  }}
                />
              </MenuItem>
            ) : (
              <>
                <IconButton
                  onClick={e => setSubmenuEl(prev => (!prev ? e.target : null))}
                >
                  <ArrowLeftIcon
                    sx={{
                      color: 'lightgray',
                      scale: '1.3',
                    }}
                  />
                </IconButton>
                {violationItems.map((item, idx) => (
                  <MenuItem key={idx} onClick={() => handleNftFlag(item)}>
                    {item}
                  </MenuItem>
                ))}
              </>
            )}
          </SubMenu>
        </Drawer>
      )}
    </>
  );
};

export default ActionMenu;
