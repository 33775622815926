import React from 'react';
import styles from '../styles.module.scss';
import { Grid } from '@mui/material';
import BackgroundIcon from 'assets/imgs/bg.png';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { StyledButton } from 'components/StyledComponents';
const LicenseGrid = ({ children, lastUpdated, title, description, url }) => {
  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${title}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  return (
    <>
      <Grid container className={styles.inner} direction="column">
        <div className={styles.header}>
          <div className={styles.background}>
            <div className={styles.bg1}>
              <img src={BackgroundIcon} alt="backgroud" />
            </div>
            <div className={styles.bg2}>
              <img src={BackgroundIcon} alt="background" />
            </div>
          </div>
          <div className={styles.headerContent}>
            <div className={styles.time}>{`Current as of ${lastUpdated}`}</div>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
        {url && (
          <div className={styles.download}>
            <StyledButton secondary="true" onClick={downloadFile}>
              Download
              <FileDownloadOutlinedIcon />
            </StyledButton>
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </Grid>
    </>
  );
};

export default LicenseGrid;
