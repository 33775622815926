import React from 'react';

const IconPaper = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.2912 5.78665C13.2838 5.76531 13.2778 5.74465 13.2692 5.72398C13.2376 5.65162 13.1933 5.58548 13.1385 5.52865L9.1385 1.52865C9.08167 1.47383 9.01553 1.42959 8.94317 1.39798C8.92317 1.38865 8.90184 1.38331 8.8805 1.37598C8.82472 1.357 8.76665 1.34556 8.70784 1.34198C8.69317 1.34065 8.6805 1.33331 8.6665 1.33331H3.99984C3.2645 1.33331 2.6665 1.93131 2.6665 2.66665V13.3333C2.6665 14.0686 3.2645 14.6666 3.99984 14.6666H11.9998C12.7352 14.6666 13.3332 14.0686 13.3332 13.3333V5.99998C13.3332 5.98598 13.3258 5.97331 13.3245 5.95865C13.3211 5.90009 13.3099 5.84224 13.2912 5.78665ZM11.0572 5.33331H9.33317V3.60931L11.0572 5.33331ZM3.99984 13.3333V2.66665H7.99984V5.99998C7.99984 6.17679 8.07008 6.34636 8.1951 6.47138C8.32012 6.59641 8.48969 6.66665 8.6665 6.66665H11.9998L12.0012 13.3333H3.99984Z" />
  </svg>
);

export default IconPaper;
