import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

const UserBio = ({ bio }) => {
  const [showFullBio, setShowFullBio] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(true);

  useEffect(() => {
    const wordCount = bio?.split(' ').length;
    setIsOverflowing(
      (wordCount > 3 && bio?.length > 45) || bio?.includes('\n')
    );
  }, [bio]);

  const toggleBio = () => {
    setShowFullBio(!showFullBio);
  };
  return bio?.trim() ? (
    <div className={cx(styles.root, showFullBio && styles.full)}>
      <div
        className={cx(styles.bioText, showFullBio ? styles.full : styles.short)}
      >
        {showFullBio || !isOverflowing ? bio : `${bio.split('\n')[0]}...`}
      </div>
      <div>
        {isOverflowing && (
          <button onClick={toggleBio} className={styles.seeMore}>
            {showFullBio ? 'Show less...' : 'Show more...'}
          </button>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default UserBio;
