import React from 'react';
import { Grid, Box } from '@mui/material';
import styles from './styles.module.scss';

const Main = () => {
  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Grid item xs={12} md={12} lg={12}>
            <Box className={styles.title}>
              <h1>The marketplace</h1>
              <h1>for meme communities</h1>
            </Box>

            <Box className={styles.subtitle1}>
              <h6>
                Add your community and memecoin. Trade content and have fun
              </h6>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Main;
