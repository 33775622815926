import { TextField, Box, Chip } from '@mui/material';
import React, { useRef } from 'react';
import styles from './styles.module.scss';
export default function InputTags({ tags, setTags, className, text }) {
  const tagRef = useRef();

  const handleDelete = value => () => {
    const newtags = tags.filter(val => val !== value);
    setTags(newtags);
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    if (!tags.includes(tagRef.current.value)) {
      setTags([...tags, tagRef.current.value]);
      tagRef.current.value = '';
    }
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <div className={styles.tagContainer}>
        {tags.map((data, index) => {
          return (
            <Chip
              label={data}
              key={index}
              onDelete={handleDelete(data)}
              className={styles.tag}
            />
          );
        })}
      </div>
      <form onSubmit={handleOnSubmit}>
        <TextField
          inputRef={tagRef}
          fullWidth
          variant="outlined"
          size="small"
          margin="none"
          placeholder={text}
          className={className}
          sx={{
            ':has(.Mui-focused)': {
              borderColor: 'var(--primary-color)',
            },
          }}
        />
      </form>
    </Box>
  );
}
