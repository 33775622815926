import { combineReducers } from 'redux';

import { Auth } from './auth.reducers';
import { ConnectWallet } from './connectwallet.reducers';
import { HeaderOptions } from './header.reducers';
import { Modal } from './modal.reducers';
import { Filter } from './filter.reducers';
import { Collections } from './collections.reducers';
import { Tokens } from './tokens.reducers';
import { Price } from './price.reducers';
import { Maps } from './maps.reducers';
import { Notifications } from './noti.reducers';
import { Player } from './player.reducer';

const createReducer = asyncReducers => {
  return combineReducers({
    ...asyncReducers,
    Auth,
    ConnectWallet,
    HeaderOptions,
    Modal,
    Filter,
    Collections,
    Tokens,
    Price,
    Maps,
    Notifications,
    Player,
  });
};

export default createReducer;
