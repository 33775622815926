import { Contracts } from 'constants/networks';
import { ethers } from 'ethers';
import useContract from 'hooks/useContract';

import { REWARD_TOKEN_ABI } from './abi';

export const useRewardContract = () => {
  const { getContract } = useContract();

  const getRewardContract = async address =>
    await getContract(address, REWARD_TOKEN_ABI);

  const transferFromSigned = async (from, amount, nonce, signature) => {
    const contract = await getRewardContract(Contracts.token);

    return await contract.transferFromSigned(from, amount, nonce, signature);
  };

  const getDecimals = async () => {
    const contract = await getRewardContract(Contracts.token);
    return await contract.decimals();
  };

  const getSymbol = async () => {
    const contract = await getRewardContract(Contracts.token);
    return await contract.symbol();
  };

  const getRewardBalance = async address => {
    const contract = await getRewardContract(Contracts.token);
    const decimals = await getDecimals();
    const currentBalance = await contract.balanceOf(address);

    return parseFloat(ethers.utils.formatUnits(currentBalance, decimals));
  };

  return {
    getRewardContract,
    transferFromSigned,
    getRewardBalance,
    getDecimals,
    getSymbol,
  };
};
