import React, { useState } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { Stack } from '@mui/material';
import { StyledButton } from 'components/StyledComponents';
import IconSmiley from 'assets/icons/nft/iconSmiley';

const Reactions = ({ onEmojiClick }) => {
  const [showButtons, setShowButtons] = useState(false);

  return (
    <Stack
      flexDirection="row"
      alignItems="flex-start"
      gap="5px"
      mt="2px"
      sx={{
        bgcolor: 'transparent',
        minWidth: '40px',
        '& > aside': {
          height: '40px',
        },
      }}
    >
      <StyledButton
        sx={{
          width: 30,
          px: 0,
          py: '10px',
          minWidth: 0,
          borderColor: 'transparent',
          '&:hover': {
            bgcolor: 'var(--primary-text-color) !important',
            borderColor: 'var(--primary-text-color) !important',
            '& svg': {
              fill: 'var(--background-color)',
            },
          },
          '& svg': {
            width: 20,
            fill: 'var(--primary-text-color)',
          },
        }}
        cancel="true"
        onClick={() => setShowButtons(prev => !prev)}
      >
        <IconSmiley />
      </StyledButton>

      {showButtons && (
        <EmojiPicker
          style={{ '--epr-emoji-size': '20px' }}
          emojiStyle="native"
          skinTonesDisabled
          reactionsDefaultOpen={true}
          onEmojiClick={e => {
            onEmojiClick(e);
          }}
          onReactionClick={e => {
            onEmojiClick(e);
          }}
        />
      )}
    </Stack>
  );
};

export default Reactions;
