import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import Skeleton from 'react-loading-skeleton';

import { formatNumber } from 'utils';
import PriceInput from 'components/PriceInput';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import InputError from '../InputError';

const BidModal = ({
  visible,
  onClose,
  onPlaceBid,
  minBidAmount,
  confirming,
  token,
  firstBid,
}) => {
  const [currentBid, setCurrentBid] = useState(0);
  const [price, setPrice] = useState('');
  const [focused, setFocused] = useState(false);
  const [inputError, setInputError] = useState(null);

  useEffect(() => {
    setPrice(minBidAmount);
    setCurrentBid(parseFloat(minBidAmount));
  }, [visible]);

  const validateInput = () => {
    return (
      price.length > 0 &&
      parseFloat(price) > 0 &&
      (firstBid
        ? parseFloat(price) >= currentBid
        : parseFloat(price) > currentBid)
    );
  };

  return (
    <Modal
      visible={visible}
      title="Place Bid"
      onClose={onClose}
      showCloseIcon={false}
      submitDisabled={confirming || !validateInput() || inputError}
      submitLabel={
        confirming ? <ClipLoader color="#FFF" size={16} /> : 'Place Bid'
      }
      onSubmit={() =>
        !confirming && validateInput() ? onPlaceBid(price) : null
      }
    >
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>Price</div>
        <div className={cx(styles.formInputCont, focused && styles.focused)}>
          <div className={styles.select}>
            <div className={styles.selectedToken}>
              <img src={token?.icon} className={styles.tokenIcon} />
              <div className={styles.tokenSymbol}>{token?.symbol}</div>
            </div>
          </div>
          <PriceInput
            className={styles.formInput}
            placeholder="0.00"
            decimals={token?.decimals || 0}
            value={'' + price}
            onChange={setPrice}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={confirming}
            onInputError={err => setInputError(err)}
          />
          <div className={styles.usdPrice}>
            {!isNaN(token?.payToken?.price) &&
            token?.payToken?.price !== null ? (
              `$${formatNumber(
                ((parseFloat(price) || 0) * token?.payToken?.price).toFixed(2)
              )}`
            ) : (
              <Skeleton width={100} height={24} />
            )}
          </div>
        </div>
        <InputError text={inputError} />
      </div>
    </Modal>
  );
};

export default BidModal;
