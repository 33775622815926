import React, { forwardRef, memo, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { fixUrl, formatDate, formatNumber } from 'utils';

import cx from 'classnames';
import VideocamIcon from '@mui/icons-material/Videocam';
import ShareIcon from '@mui/icons-material/ShareOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

import Identicon from 'components/Identicon';
import styles from './styles.module.scss';
import { Box, Chip, Typography, useMediaQuery } from '@mui/material';
import { StyledButton } from 'components/StyledComponents';
import Skeleton from '@mui/material/Skeleton';
import ShareModal from 'components/ShareModal';
import { NFT_STATUSES_CODES } from 'constants/NFTStatuses.constants';
import ReactPlayer from 'react-player';
import IconLoading from 'assets/icons/iconLoading';
import AudioPlayerSmall from './components/AudioPlayerSmall';
import showToast from 'utils/toast';

const NFTItem = forwardRef(
  (
    {
      nft,
      isLoggedIn,
      handleToggleFavorite,
      handleToggleLike,
      showStatus,
      hideSubItems,
      hideAvatarAndName,
      likeOnTop,
      favoriteOnTop,
      hideHoverButtons,
      toggleUserFollow,
      preview = false,
      user,
      openInNewTab,
    },
    ref
  ) => {
    const history = useHistory();
    const playerRef = useRef(null);
    const [shareModal, setShareModal] = useState(false);
    const [videoHasError, setVideoHasError] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const isMobile = useMediaQuery('(max-width: 900px)');

    const { user: { userId, isFollowing } = {} } = nft ?? {};

    const renderFavoriteButton = ({ remove } = {}) => (
      <div
        className={styles.favoriteSection}
        onClick={e => {
          if (isLoggedIn) {
            e.preventDefault();
            if (handleToggleFavorite) {
              handleToggleFavorite(remove);
            }
          } else {
            history.push('/auth/sign-in');
          }
        }}
      >
        {nft.isFavorite ? (
          <StarIcon className={cx(styles.favoriteIcon, styles.isFavorite)} />
        ) : (
          <StarBorderIcon className={styles.favoriteIcon} />
        )}
      </div>
    );

    const renderLikeButton = ({ hideCount, remove } = {}) => (
      <div
        className={styles.likeSection}
        onClick={e => {
          if (isLoggedIn) {
            e.preventDefault();
            if (handleToggleLike) {
              handleToggleLike(remove);
            }
          } else {
            history.push('/auth/sign-in');
          }
        }}
      >
        {nft.isLiked ? (
          <FavoriteIcon color="error" className={styles.likeIcon} />
        ) : (
          <FavoriteBorderIcon className={styles.likeIcon} />
        )}
        {!hideCount ? (
          <span className={styles.likes}>{nft.likes ?? 0}</span>
        ) : null}
      </div>
    );

    const handleRedirectToProfile = () => {
      if (nft.userName) {
        history.push(`/account/${nft.userName.replaceAll(' ', '+')}`);
      }
    };

    const handleRedirectToNft = () => {
      if (nft.userName) {
        history.push(url);
      }
    };

    const url = useMemo(() => {
      if (nft?.nftId) {
        if (nft.nftStatusId === NFT_STATUSES_CODES.PREMINT) {
          return `/draft/${nft.nftId}`;
        } else if (nft.nftStatusId !== NFT_STATUSES_CODES.MINTED) {
          return `/pending/${nft.nftId}`;
        } else {
          return `/explore/${nft.name.toLowerCase().replaceAll(' ', '+')}`;
        }
      }
      return '';
    }, [nft.nftId, nft.nftStatusId, nft.name]);

    const handleMouseEnter = () => {
      setIsPlaying(true);
    };

    const handleMouseLeave = () => {
      setIsPlaying(false);
      if (playerRef.current) {
        playerRef.current.seekTo(0);
      }
    };
    return (
      <div
        ref={ref}
        className={cx(
          styles.masonryContainer,
          videoHasError && styles.videoHasError
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onContextMenu={e => e.preventDefault()}
      >
        <div className={styles.mainSection}>
          {showStatus && (
            <Chip
              label={
                nft.nftStatus.name.toLowerCase() === 'premint'
                  ? 'Draft'
                  : nft.nftStatus.name
              }
              className={styles.chip}
              size="small"
            />
          )}
          <div className={styles.topRight}>
            {likeOnTop && renderLikeButton({ hideCount: true, remove: true })}
            {favoriteOnTop && renderFavoriteButton({ remove: true })}
          </div>
          <div
            className={cx(
              styles.imageContainer,
              nft.trimmedAudio &&
                !nft.multipleSourceFiles &&
                styles.audioContainer,
              nft.thumbnailVideo &&
                !nft.multipleSourceFiles &&
                styles.videoContainer,
              showStatus && styles.larger
            )}
          >
            {nft.thumbnailImage || nft.thumbnailVideo || nft.trimmedAudio ? (
              <>
                {nft.thumbnailVideo && !nft.multipleSourceFiles ? (
                  <>
                    <ReactPlayer
                      ref={playerRef}
                      className={cx(styles.media)}
                      url={nft.thumbnailVideo}
                      playing={isMobile ? false : isPlaying}
                      playsinline={isMobile}
                      controls={false}
                      muted
                      loop
                      width="100%"
                      height="100%"
                      onReady={() => setVideoHasError(false)}
                      onError={() => setVideoHasError(true)}
                    />
                    <Box
                      className={cx(
                        styles.videoIcon,
                        nft.processing === 'failed' && styles.failed,
                        nft.processing === 'pending' && styles.pending
                      )}
                    >
                      <VideocamIcon />
                      {nft.processing === 'pending' && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontSize="11px"
                            m="2px"
                            mt="0"
                          >
                            processing
                          </Typography>
                          <IconLoading />
                        </Box>
                      )}
                      {nft.processing === 'failed' && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontSize="11px"
                            m="2px"
                            mt="0"
                          >
                            failed
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </>
                ) : nft.trimmedAudio && !nft.multipleSourceFiles ? (
                  <div className={styles.audioSource}>
                    <div className={styles.posterOverlay}></div>
                    <div
                      className={styles.poster}
                      onPointerMove={e => e.preventDefault()}
                      style={{
                        backgroundImage: `url(${fixUrl(nft.thumbnailImage)})`,
                      }}
                    ></div>
                    <AudioPlayerSmall
                      audioUrl={nft.trimmedAudio}
                      showStatus={showStatus}
                    />
                  </div>
                ) : (
                  <div className={styles.imageSource}>
                    <img
                      src={nft.thumbnailImage}
                      className={cx(styles.image)}
                    />
                  </div>
                )}
              </>
            ) : (
              <Skeleton
                variant="rounded"
                height={200}
                width={200}
                className={styles.image}
              />
            )}
          </div>
          {!preview && (
            <div className={styles.stampContainer}>
              <Link to={url} target={openInNewTab ? '_blank' : '_self'} />

              <div className={styles.stamp}>
                <div className={styles.stampMain} onClick={handleRedirectToNft}>
                  <div className={styles.stampTitle}>{nft.name}</div>
                </div>
                <div className={styles.middleDisplay}>
                  <div className={styles.stampDescription}>
                    {nft.description}
                  </div>
                  <div className={styles.bottomDisplay}>
                    {(hideSubItems &&
                      nft.nftStatus &&
                      nft.nftStatus.name.toLowerCase() !== 'minted') ||
                    !nft.nftStatus ? (
                      <div style={{ height: '20px' }}>&nbsp;</div>
                    ) : (
                      <>
                        {!favoriteOnTop && renderFavoriteButton()}
                        <div className={styles.shareSection}>
                          <ShareIcon
                            className={styles.shareIcon}
                            onClick={() => setShareModal(true)}
                          />
                        </div>
                        {!likeOnTop && renderLikeButton()}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.descriptionSection}>
          {/* first child */}
          <div>
            {!hideAvatarAndName && (
              <>
                <div className={styles.avatar}>
                  {nft.userAvatar ? (
                    <img
                      src={
                        nft.userAvatar.includes('mypinata')
                          ? `${nft.userAvatar}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                          : nft.userAvatar
                      }
                      className={styles.avatar}
                    />
                  ) : (
                    <Identicon
                      size={25}
                      account={nft.userId}
                      className={styles.icon}
                    />
                  )}
                </div>

                <div className={styles.nameSection}>
                  <div onClick={handleRedirectToProfile}>{nft.userName}</div>
                  <div className={styles.stampTime}>
                    {formatDate(nft.createdAt)}
                  </div>
                </div>
              </>
            )}
            {userId
              ? !hideHoverButtons && (
                  <div className={styles.followButton}>
                    <StyledButton
                      onClick={() =>
                        isLoggedIn
                          ? toggleUserFollow(userId)
                          : history.push('/auth/sign-in')
                      }
                    >
                      {isFollowing ? 'Unfollow' : 'Follow'}
                    </StyledButton>
                  </div>
                )
              : null}
          </div>

          {/* second child */}
          <div>
            {nft.price > 0 && (
              <>
                <div className={styles.price}>
                  {/* <div>Price</div> */}
                  <div className={styles.stampPrice}>
                    {nft?.icon ? (
                      <img src={`${nft?.icon}`} className={styles.icon} />
                    ) : (
                      <Identicon
                        account={user.userId}
                        size={50}
                        className={styles.icon}
                      />
                    )}
                    {formatNumber(nft.price)}
                  </div>
                </div>

                {!hideHoverButtons && (
                  <div className={styles.buyNow}>
                    <StyledButton
                      onClick={() =>
                        isLoggedIn
                          ? user?.isAdmin
                            ? showToast(
                                'error',
                                'This is an admin account. This action is not allowed.'
                              )
                            : history.push(`${url}/buy`)
                          : history.push('/auth/sign-in')
                      }
                    >
                      Buy Now
                    </StyledButton>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <ShareModal
          visible={shareModal}
          onClose={() => setShareModal(false)}
          nft={nft}
        />
      </div>
    );
  }
);

NFTItem.displayName = 'NFTItem';

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.nft.user?.isFollowing === nextProps.nft.user?.isFollowing &&
    prevProps.nft.nftId === nextProps.nft.nftId &&
    prevProps.nft.name === nextProps.nft.name &&
    prevProps.nft.isFavorite === nextProps.nft.isFavorite &&
    prevProps.nft.isLiked === nextProps.nft.isLiked &&
    prevProps.nft.price === nextProps.nft.price &&
    prevProps.nft.thumbnailImage === nextProps.nft.thumbnailImage &&
    prevProps.nft.thumbnailVideo === nextProps.nft.thumbnailVideo &&
    prevProps.nft.frontCover === nextProps.nft.frontCover &&
    prevProps.likeOnTop == nextProps.likeOnTop &&
    prevProps.favoriteOnTop == nextProps.favoriteOnTop
  );
};

export default memo(NFTItem, areEqual);
