import React, { useState, useRef } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { useApi } from 'api';
import UploadIcon from '@mui/icons-material/Upload';
import { LinearProgress } from '@mui/material';

import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import toast from 'utils/toast';

const InputPdfFile = ({
  assetType = 'attachments',
  changeFunction,
  multiple = true,
  disabled = false,
}) => {
  const [progressUpload, setProgressUpload] = useState({});
  const authToken = useSelector(state => state.Auth.authToken);
  const fileRef = useRef();

  const { uploadFile, setAssetUploaded } = useApi();

  const changeHandler = async files => {
    if (files.length < 1) return;
    setProgressUpload(prev => {
      const uploadingBars = files.reduce((obj, file) => {
        obj[file.name] = 0;
        return obj;
      }, {});
      return { ...prev, ...uploadingBars };
    });
    const progressBarCb = fileName => newValue => {
      setProgressUpload(prev => {
        return { ...prev, [fileName]: newValue };
      });
    };
    const completeCb = fileName => async response => {
      setProgressUpload(prev => {
        const afterUpload = Object.keys(prev)
          .filter(k => k !== fileName)
          .reduce((obj, key) => {
            obj[key] = prev[key];
            return obj;
          }, {});
        return afterUpload;
      });

      const asset = response;

      const { errors } = await setAssetUploaded(authToken, asset.assetId);

      if (!errors) {
        asset.uploaded = true;
        asset.type = assetType;

        changeFunction(prevState => {
          const result = multiple ? [...prevState, asset] : [asset];
          return result;
        });
        toast('success', 'File saved');
      }
    };
    files.map(file => {
      return uploadFile(
        authToken,
        assetType,
        file,
        progressBarCb(file.name),
        completeCb(file.name)
      );
    });
  };

  const bars = Object.entries(progressUpload).map(el => {
    return (
      !!el[1] && (
        <LinearProgress variant="determinate" key={el[0]} value={el[1]} />
      )
    );
  });

  const waitingUpload = !multiple && Object.keys(progressUpload).length !== 0;

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    multiple,
    onDrop: changeHandler,
    maxSize: 15728640,
    noClick: true,
    accept: '.pdf',
  });

  return (
    <>
      <div
        className={cx(styles.board, waitingUpload && styles.waitingUpload)}
        onClick={() => fileRef.current?.click()}
      >
        <div
          {...getRootProps({
            className: cx(
              styles.uploadCont,
              isDragAccept && styles.isDragAccept
            ),
          })}
        >
          <input {...getInputProps()} ref={fileRef} disabled={disabled} />
          <div className={styles.uploadFile}>
            <div className={styles.iconContainer}>
              <UploadIcon />
            </div>
            <div className={styles.iconText}>Upload file</div>
          </div>
        </div>
      </div>
      {bars}
    </>
  );
};

export default InputPdfFile;
