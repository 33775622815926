const playerActions = {
  getActivePlayer,
  setActivePlayer,
};

function getActivePlayer() {
  return dispatch => {
    dispatch(_getActivePlayer());
  };
}

const _getActivePlayer = () => {
  return {
    type: 'get_active_player',
  };
};

function setActivePlayer(data) {
  return dispatch => {
    dispatch(_setActivePlayer(data));
  };
}

const _setActivePlayer = data => {
  return {
    type: 'set_active_player',
    ...data,
  };
};

export default playerActions;
