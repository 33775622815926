import React from 'react';

const IconSocialMedia = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.25 1.25C6.52665 1.25182 4.87441 1.93722 3.65582 3.15582C2.43722 4.37441 1.75182 6.02665 1.75 7.75V13.0206C1.75033 13.3466 1.87996 13.6591 2.11044 13.8896C2.34092 14.12 2.65343 14.2497 2.97937 14.25H8.25C9.97391 14.25 11.6272 13.5652 12.8462 12.3462C14.0652 11.1272 14.75 9.47391 14.75 7.75C14.75 6.02609 14.0652 4.37279 12.8462 3.15381C11.6272 1.93482 9.97391 1.25 8.25 1.25ZM8.25 12.75H3.25V7.75C3.25 6.76109 3.54324 5.79439 4.09265 4.97215C4.64206 4.1499 5.42295 3.50904 6.33658 3.1306C7.25021 2.75216 8.25555 2.65315 9.22545 2.84607C10.1954 3.039 11.0863 3.5152 11.7855 4.21447C12.4848 4.91373 12.961 5.80464 13.1539 6.77455C13.3469 7.74445 13.2478 8.74979 12.8694 9.66342C12.491 10.577 11.8501 11.3579 11.0279 11.9073C10.2056 12.4568 9.23891 12.75 8.25 12.75ZM7.75 8C7.75 8.19778 7.69135 8.39112 7.58147 8.55557C7.47159 8.72002 7.31541 8.84819 7.13268 8.92388C6.94996 8.99957 6.74889 9.01937 6.55491 8.98079C6.36093 8.9422 6.18275 8.84696 6.04289 8.70711C5.90304 8.56725 5.8078 8.38907 5.76921 8.19509C5.73063 8.00111 5.75043 7.80004 5.82612 7.61732C5.90181 7.43459 6.02998 7.27841 6.19443 7.16853C6.35888 7.05865 6.55222 7 6.75 7C7.01522 7 7.26957 7.10536 7.45711 7.29289C7.64464 7.48043 7.75 7.73478 7.75 8ZM10.75 8C10.75 8.19778 10.6914 8.39112 10.5815 8.55557C10.4716 8.72002 10.3154 8.84819 10.1327 8.92388C9.94996 8.99957 9.74889 9.01937 9.55491 8.98079C9.36093 8.9422 9.18275 8.84696 9.04289 8.70711C8.90304 8.56725 8.8078 8.38907 8.76921 8.19509C8.73063 8.00111 8.75043 7.80004 8.82612 7.61732C8.90181 7.43459 9.02998 7.27841 9.19443 7.16853C9.35888 7.05865 9.55222 7 9.75 7C10.0152 7 10.2696 7.10536 10.4571 7.29289C10.6446 7.48043 10.75 7.73478 10.75 8Z" />
  </svg>
);

export default IconSocialMedia;
