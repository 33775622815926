import React, { useEffect, useState } from 'react';
import { AccordionDetails, AccordionSummary, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledAccordion } from 'components/StyledComponents';
import styles from '../styles.module.scss';
import Input from 'components/Input';
import { useDebounce } from 'use-debounce';
import { useApi } from 'api';
import { useSelector } from 'react-redux';

const CommunityInfo = ({
  tokenDetails,
  updateTokenDetails,
  validateError,
  setValidateError,
}) => {
  const { communityExists } = useApi();
  const [expanded, setExpanded] = useState(true);
  const { authToken } = useSelector(state => state.Auth);
  const [enteredName] = useDebounce(tokenDetails['community']['name'], 500);

  useEffect(() => {
    const checkCommunityExists = async () => {
      const { data: exists } = await communityExists(authToken, enteredName);

      if (exists) {
        setValidateError(prev => ({
          ...prev,
          community: {
            ...prev.community,
            name: 'Community name exists',
          },
        }));
      }
    };

    if (enteredName) {
      checkCommunityExists();
    }
  }, [authToken, enteredName]);

  const validateField = (key, value) => {
    let errorMessage = '';

    if (key === 'name' && value.trim() === '') {
      errorMessage = 'Community name is required';
    }

    if (key === 'description' && value.trim() === '') {
      errorMessage = 'Description is required';
    }

    setValidateError(prev => ({
      ...prev,
      community: {
        ...prev.community,
        [key]: errorMessage,
      },
    }));
  };

  return (
    <div className={styles.panelInputs}>
      <StyledAccordion
        border="1px var(--border-color) solid !important"
        titlesize="24px !important"
        sx={{ overflow: 'hidden' }}
        expanded={expanded}
        onChange={() => setExpanded(prev => !prev)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          className={styles.inputGroup}
          id="panel2-header"
          style={{
            border: '1px #eaecf0 solid !important',
            boxShadow: 'none',
          }}
        >
          Community
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.formGroup}>
            <Input
              hasError={validateError.community.name}
              placeholder="Community Name"
              value={tokenDetails['community']['name']}
              onChange={e => {
                const { value } = e.target;
                updateTokenDetails(['community', 'name'], value);
                validateField('name', value);
              }}
              label="Community Name*"
            />
            {validateError.community.name && (
              <Box className={styles.error}>{validateError.community.name}</Box>
            )}
            <Input
              hasError={validateError.community.description}
              placeholder="Description"
              value={tokenDetails['community']['description']}
              onChange={e => {
                const { value } = e.target;
                updateTokenDetails(['community', 'description'], value);
                validateField('description', value);
              }}
              label="Description*"
            />
            {validateError.community.description && (
              <Box className={styles.error}>
                {validateError.community.description}
              </Box>
            )}
            <Input
              hasError={validateError.community.website}
              placeholder="https://example.com"
              value={tokenDetails['community']['website']}
              onChange={e =>
                updateTokenDetails(['community', 'website'], e.target.value)
              }
              label="Website"
            />
            <Input
              hasError={validateError.community.twitter}
              placeholder=""
              value={tokenDetails['community']['twitter']}
              onChange={e =>
                updateTokenDetails(['community', 'twitter'], e.target.value)
              }
              label="X"
            />
            <Input
              hasError={validateError.community.discord}
              placeholder=""
              value={tokenDetails['community']['discord']}
              onChange={e =>
                updateTokenDetails(['community', 'discord'], e.target.value)
              }
              label="Discord"
            />
            <Input
              hasError={validateError.community.instagram}
              placeholder=""
              value={tokenDetails['community']['instagram']}
              onChange={e =>
                updateTokenDetails(['community', 'instagram'], e.target.value)
              }
              label="Instagram"
            />
            <Input
              hasError={validateError.community.telegram}
              placeholder=""
              value={tokenDetails['community']['telegram']}
              onChange={e =>
                updateTokenDetails(['community', 'telegram'], e.target.value)
              }
              label="Telegram"
            />
            <Input
              hasError={validateError.community.tiktok}
              placeholder=""
              value={tokenDetails['community']['tiktok']}
              onChange={e =>
                updateTokenDetails(['community', 'tiktok'], e.target.value)
              }
              label="TikTok"
            />
          </div>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
};

export default CommunityInfo;
