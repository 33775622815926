import React from 'react';

const IconNotification = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.99984 11.3333V12C5.99984 12.5304 6.21055 13.0391 6.58562 13.4142C6.9607 13.7893 7.4694 14 7.99984 14C8.53027 14 9.03898 13.7893 9.41405 13.4142C9.78912 13.0391 9.99984 12.5304 9.99984 12V11.3333M6.6665 3.33333C6.6665 2.97971 6.80698 2.64057 7.05703 2.39052C7.30708 2.14048 7.64622 2 7.99984 2C8.35346 2 8.6926 2.14048 8.94265 2.39052C9.19269 2.64057 9.33317 2.97971 9.33317 3.33333C10.0988 3.69535 10.7514 4.25888 11.2212 4.96353C11.691 5.66818 11.9601 6.48738 11.9998 7.33333V9.33333C12.05 9.7478 12.1968 10.1447 12.4284 10.4921C12.66 10.8395 12.9699 11.1276 13.3332 11.3333H2.6665C3.0298 11.1276 3.33971 10.8395 3.5713 10.4921C3.80288 10.1447 3.94967 9.7478 3.99984 9.33333V7.33333C4.03954 6.48738 4.3087 5.66818 4.77847 4.96353C5.24824 4.25888 5.9009 3.69535 6.6665 3.33333Z"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconNotification;
