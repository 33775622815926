import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import Identicon from 'components/Identicon';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles.module.scss';
import { StyledButton } from 'components/StyledComponents';

const Follower = ({ user, children, onClick, userId }) => {
  return user?.userId ? (
    <Link
      to={
        user.userId === userId
          ? '/profile'
          : `/account/${user.name.replaceAll(' ', '+')}`
      }
      onClick={onClick}
      className={styles.holder}
    >
      {children}
    </Link>
  ) : (
    <div className={styles.holder}>{children}</div>
  );
};

const FollowersModal = ({
  visible,
  onClose,
  title,
  users,
  toggleFollowUser,
  userId,
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={visible}
      sx={{
        bottom: '20%',
        '& .MuiPaper-root': {
          width: '500px',
          backgroundColor: 'var(--background-color)',
          color: 'var(--primary-text-color)',
        },
      }}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'var(--primary-text-color)',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          maxHeight: '400px',
          overflowY: 'auto',
        }}
      >
        {users.map((user, idx) => (
          <Follower key={idx} user={user} onClick={onClose} userId={userId}>
            <div className={styles.holderInfo}>
              <div className={styles.avatarWrapper}>
                {!user ? (
                  <Skeleton width={40} height={40} />
                ) : user.avatar ? (
                  <img src={`${user.avatar}`} className={styles.avatarImg} />
                ) : (
                  <Identicon
                    account={user.userId}
                    size={50}
                    className={styles.avatarImg}
                  />
                )}
              </div>
              <div className={styles.username}>{user?.name}</div>
              {/* <div className={styles.info}>
                <div className={styles.address}>
                  {user ? (
                    shortenAddress(user?.address)
                  ) : (
                    <Skeleton width={100} height={20} />
                  )}
                </div>
              </div> */}
            </div>
            <div className={styles.followers}>
              {user && user.userId !== userId && (
                <StyledButton
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (user.userId) {
                      toggleFollowUser(user.userId, user.name);
                    }
                  }}
                  secondary={user.isFollowing}
                >
                  {!user?.isFollowing ? 'Follow' : 'Unfollow'}
                </StyledButton>
              )}
            </div>
          </Follower>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default FollowersModal;
