import React, { useState, useEffect, useRef } from 'react';
import Modal from 'components/Modal';
import styles from './styles.module.scss';
import { StyledButton } from 'components/StyledComponents';
import { useApi } from 'api';
import toast from 'utils/toast';
import CodeInput from 'components/CodeInput';
function FAModel({ visible, onClose, qrcode, authToken, action }) {
  const [twoFACode, setTwoFACode] = useState('');
  const { check2faCode } = useApi();
  const confirmButton = useRef(null);
  const [isFACodeValid, setIsFACodeValid] = useState(true);
  const handleSendQRVerification = async qrValue => {
    if (qrValue.length !== 6 || isNaN(qrValue)) {
      setIsFACodeValid(false);
      return;
    }
    try {
      const { errors, data } = await check2faCode(authToken, qrValue);

      if (Object.keys(data).length === 0) {
        setIsFACodeValid(false);
        setTwoFACode('');
        toast('error', errors[0].message);
      } else {
        action(data);
        onClose();
      }
    } catch (error) {
      toast('error', error.message);
    }
  };
  useEffect(() => {
    if (visible) {
      const listener = event => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          event.preventDefault();
          if (confirmButton) {
            confirmButton.current.click();
          }
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    }
  }, [visible]);
  return (
    <Modal visible={visible} onClose={onClose} showCloseIcon={false}>
      <div className={styles.title}>Set up two-factor authentication</div>
      <div className={styles.description}>
        To authorise transactions, please scan this QR code with your
        Authenticator App and enter the verification code below.
      </div>
      <div className={styles.qrCodeContainer}>
        <img src={qrcode} className={styles.qrcodeImg} />
      </div>
      <div className={styles.verificationTitle}>Verification code</div>
      {visible && (
        <CodeInput
          twoFACode={twoFACode}
          setTwoFACode={setTwoFACode}
          setIsFACodeValid={setIsFACodeValid}
          isFACodeValid={isFACodeValid}
          handleCheck2fa={handleSendQRVerification}
        />
      )}
      <div className={styles.btnGroup}>
        <StyledButton cancel="true" onClick={() => onClose()}>
          Cancel
        </StyledButton>
        <StyledButton
          onClick={() => handleSendQRVerification(twoFACode)}
          ref={confirmButton}
        >
          Confirm
        </StyledButton>
      </div>
    </Modal>
  );
}

export default FAModel;
