import React from 'react';
import { Box } from '@mui/material';
import logo from 'assets/imgs/mintifi.png';

const IconFS = () => {
  return (
    <Box
      sx={{
        border: '1px solid var(--primary-color)',
        borderRadius: '50%',
        width: '1.5rem',
        height: '1.5rem',
        fontSize: 'initial !important',
        lineHeight: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 12,
        }}
        component="img"
        src={logo}
      />
    </Box>
  );
};

export default IconFS;
