import React, { memo, useEffect, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import Identicon from 'components/Identicon';
import { Box, Stack } from '@mui/material';
import { StyledButton } from 'components/StyledComponents';
import IconThumbup from 'assets/icons/nft/iconThumbup';
import { formatDate } from 'utils';
import showToast from 'utils/toast';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  getReplyText,
} from '../../utils';
import { useApi } from 'api';
import ReplyItem from '../ReplyItem';
import AddComment from '../AddComment';
import ActionButtons from '../ActionButtons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { totalCommentsCount } from '../../utils';

import styles from './styles.module.scss';

const CommentItem = ({
  comment,
  user: me,
  nft,
  authToken,
  onCommentDeleted,
  onCommentEdited,
  isMobile,
}) => {
  const history = useHistory();
  const [comments, setComments] = useState([]);
  const [repliesCount, setRepliesCount] = useState(comment.replies);
  const [upvotes, setUpvotes] = useState(comment.upvotes);
  const [upvoted, setUpvoted] = useState(comment.upvoted);
  const [loading, setLoading] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [expandReplies, setExpandReplies] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [isEdited, setIsEdited] = useState(comment.edited);
  const [commentSelected, setCommentSelected] = useState(false);
  const [fromIndex, setFromIndex] = useState(0);
  const [isCommentsFetched, setIsCommentsFetched] = useState(false);

  const {
    getNftComments,
    addCommentVote,
    addCommentFlag,
    deleteComment,
  } = useApi();

  const handleRedirectToProfile = name => {
    if (name) {
      history.push(`/account/${name.replaceAll(' ', '+')}`);
    }
  };

  const fetchComments = async () => {
    if (fromIndex < 0) {
      return;
    }

    setLoading(true);
    const { data } = await getNftComments(
      {
        nftId: nft.nftId,
        parentId: comment.commentId,
        from: fromIndex,
        count: 5,
      },
      authToken
    );

    setComments(prev => {
      // Filter out duplicate comments
      const uniqueNewComments = data.comments.filter(
        newComment =>
          !prev.some(comment => comment.commentId === newComment.commentId)
      );
      return [...prev, ...uniqueNewComments];
    });
    setIsCommentsFetched(true);
    setLoading(false);

    if (data.total > fromIndex + 5) {
      setFromIndex(prev => prev + 5);
    } else {
      setFromIndex(-1);
    }
  };

  useEffect(() => {
    setCreatedAt(formatDate(comment.createdAt));
  }, [comment.createdAt]);

  const handleExpandReplies = async () => {
    if (!expandReplies) {
      await fetchComments();
    } else {
      setFromIndex(0);
      setTimeout(() => {
        // keep animation going
        setComments([]);
      }, 250);
    }
    setExpandReplies(prev => !prev);
  };

  const handleExpandReply = () => {
    setIsReplying(prev => !prev);
  };

  const onNewCommentAdded = comment => {
    if (!authToken) return;

    setComments(prev => [comment, ...prev]);
    setExpandReplies(true);
    setIsReplying(false);

    // increse total comments count
    totalCommentsCount.value = totalCommentsCount.value + 1;

    // increase reply count
    setRepliesCount(prev => prev + 1);
  };

  const handleUpvoteComment = async () => {
    if (!authToken || comment.userId === me.userId) return;

    const model = {
      commentId: comment.commentId,
      vote: 'upvote',
    };

    const { data } = await addCommentVote(model, authToken);

    if (data?.commentVoteId) {
      setUpvoted(true);
      setUpvotes(prev => prev + 1);
    } else {
      setUpvoted(false);
      setUpvotes(prev => prev - 1);
    }
  };

  const handleReportComment = async violation => {
    if (!authToken) return;

    const model = {
      commentId: comment.commentId,
      violation,
    };

    await addCommentFlag(model, authToken);

    showToast('success', 'Thank you for your feedback!');
  };

  const handleDeleteComment = async () => {
    if (!authToken) return;

    const { data } = await deleteComment(comment.commentId, authToken);

    if (data?.message === 'OK') {
      setIsDeleted(true);
      // to add animation
      setTimeout(() => {
        onCommentDeleted && onCommentDeleted(comment.commentId); // notify parent
        // update total count
        totalCommentsCount.value =
          totalCommentsCount.value - (repliesCount + 1);
      }, 1000);
    } else {
      showToast('error', 'Something went wrong!');
    }
  };

  const handleEditComment = comment => {
    onCommentEdited(comment);
    setIsEdited(true);
  };

  const onReplyDeleted = commentId => {
    setComments(prev => prev.filter(a => a.commentId !== commentId));

    // decrease reply count
    setRepliesCount(prev => prev - 1);
  };

  const isNewComment = createdAt === 'just now';

  return (
    <Stack
      className={cx(
        styles.root,
        isNewComment && styles.flash,
        isDeleted && styles.deleted,
        commentSelected && styles.selected
      )}
    >
      <Stack className={styles.firstRow}>
        <Stack
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          flexShrink={1}
          gap={1}
        >
          <Box
            display="flex"
            alignItems="center"
            onClick={() => handleRedirectToProfile(comment.user.name)}
            sx={{
              cursor: 'pointer',
            }}
          >
            {comment.user?.avatar ? (
              <img
                src={`${
                  comment.user?.avatar.includes('mypinata')
                    ? `${comment.user?.avatar}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                    : comment.user?.avatar
                }`}
                width="30"
                height="30"
                className={cx(styles.avatarBig)}
              />
            ) : (
              <Identicon
                account={comment.user?.userId}
                size={36}
                className={cx(styles.avatarBig)}
              />
            )}
          </Box>

          <Box
            className={styles.address}
            onClick={() => handleRedirectToProfile(comment.user.name)}
          >
            {comment.user.name}
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {/* x mins ago */}
            <Box className={styles.when}>{createdAt}</Box>

            {isEdited && <Box className={styles.when}>Edited</Box>}
          </Box>
        </Stack>

        <Box
          sx={{
            position: 'absolute',
            right: 0,
          }}
        >
          {authToken && (
            <ActionButtons
              comment={comment}
              authToken={authToken}
              isOwner={me.userId === comment.userId}
              onCommentSelected={setCommentSelected}
              onReport={handleReportComment}
              onDelete={handleDeleteComment}
              onEdit={handleEditComment}
            />
          )}
        </Box>
      </Stack>

      <Stack className={styles.secondRow}>{comment.comment}</Stack>

      <Accordion
        expanded={authToken && isReplying}
        sx={{
          backgroundColor: 'var(--background-color)',
        }}
      >
        <AccordionSummary
          expandIcon={<></>}
          sx={{
            backgroundColor: 'var(--background-color)',
          }}
        >
          <Stack
            display="flex"
            flexDirection="column"
            alignItems="start"
            width="100%"
          >
            <Stack display="flex" flexDirection="row" alignItems="center">
              <StyledButton
                sx={{
                  width: 50,
                  px: 0,
                  py: '4px',
                  minWidth: 0,
                  borderColor: 'var(--border-color)',
                  color: 'var(--primary-text-color)',

                  backgroundColor: upvoted
                    ? 'var(--secondary-background-color) !important'
                    : 'var(--background-color) !important',
                  '&:hover': {
                    bgcolor: 'var(--secondary-background-color) !important',
                    borderColor: 'var(--border-color) !important',
                  },
                  '& svg': {
                    width: 20,
                    marginRight: '5px',
                  },
                }}
                onClick={handleUpvoteComment}
                cancel={!upvoted ? 'true' : ''}
              >
                <IconThumbup />
                <Box>{upvotes}</Box>
              </StyledButton>

              {authToken && (
                <StyledButton
                  sx={{
                    ml: 2,
                    py: '1px',
                    minWidth: 0,
                    borderColor: 'var(--border-color)',
                    height: '100%',

                    '&:hover': {
                      bgcolor: 'var(--secondary-background-color) !important',
                      borderColor: 'var(--border-color) !important',
                    },
                  }}
                  cancel="true"
                  onClick={handleExpandReply}
                >
                  Reply
                </StyledButton>
              )}
            </Stack>
            {repliesCount > 0 && (
              <StyledButton
                sx={{
                  padding: '10px',
                  margin: '10px 0',
                  minWidth: 0,
                  border: 'none',
                  color: 'var(--primary-text-color)',
                  height: '100%',
                  '&:hover': {
                    border: 'none !important',
                    bgcolor: 'var(--secondary-background-color) !important',
                  },
                }}
                cancel="true"
                disabled={loading}
                onClick={() => {
                  handleExpandReplies();
                }}
              >
                {expandReplies ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
                {getReplyText(repliesCount)}
              </StyledButton>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: 'var(--background-color)',
          }}
        >
          <Stack className={styles.addReplies}>
            {authToken && (
              <AddComment
                nft={nft}
                user={me}
                parentId={comment.commentId}
                authToken={authToken}
                onCommentSaved={onNewCommentAdded}
                onCanceled={() => setIsReplying(false)}
                isMobile={isMobile}
                isReplying={isReplying}
              />
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandReplies}
        sx={{
          backgroundColor: 'var(--background-color)',
        }}
      >
        <AccordionSummary
          expandIcon={<></>}
          sx={{
            backgroundColor: 'var(--background-color)',
          }}
        />
        <AccordionDetails
          sx={{
            backgroundColor: 'var(--background-color)',
          }}
        >
          <Stack className={styles.replies}>
            {comments.map(comment => (
              <ReplyItem
                key={comment.commentId}
                nft={nft}
                parentId={comment.parentId}
                comment={comment}
                user={me}
                authToken={authToken}
                setRepliesCount={setRepliesCount}
                setComments={setComments}
                onCommentDeleted={onReplyDeleted}
                isMobile={isMobile}
              />
            ))}

            {expandReplies &&
              isCommentsFetched &&
              repliesCount > 1 &&
              fromIndex > -1 && (
                <Box className={styles.loadMore} onClick={fetchComments}>
                  Show more replies
                </Box>
              )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.nft.nftId === nextProps.nft.nftId &&
    prevProps.me?.userId === nextProps.me?.userId &&
    prevProps.comment.comment === nextProps.comment.comment &&
    prevProps.authToken === nextProps.authToken
  );
};

export default memo(CommentItem, areEqual);
