import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// Styled Tooltip with custom styles
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    padding: '8px 16px',
    fontSize: '14px',
    color: '#cccccc',
    boxShadow: '13px 12px 22px -3px rgba(0,0,0,0.1)',
    borderRadius: '10px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

function BootstrapTooltip(props) {
  return <CustomTooltip arrow {...props} interactive="true" />;
}

export default BootstrapTooltip;
