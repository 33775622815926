const initialState = {
  audioUrl: '',
};

export function Player(state = initialState, action) {
  switch (action.type) {
    case 'set_active_player': {
      return {
        ...state,
        audioUrl: action.audioUrl,
      };
    }
    case 'get_active_player': {
      return state.audioUrl;
    }
    default: {
      return state;
    }
  }
}
