import React from 'react';
import { styled, usePagination } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForward';
import { StyledButton } from 'components/StyledComponents';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

const index = ({ pages, setPage: currentPage } = {}) => {
  const [page, setPage] = React.useState(1);
  const { items } = usePagination({
    count: pages,
    page,
  });

  const handleClick = (event, value) => {
    setPage(value);
    currentPage(value);
  };

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '3px',
        mt: 2,
        mb: 2,

        '& li.prev': {
          marginRight: 'auto',
          marginLeft: '20px',
        },

        '& li.next': {
          marginLeft: 'auto',
          marginRight: '20px',
        },

        '& button': {
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          bgcolor: 'white',
          borderRadius: '8px',
          color: '#363636',
          minWidth: '35px',
          height: '35px',
          lineHeight: '35px',
          p: '0',

          '&:hover': {
            bgcolor: '#e9e9e9',
          },

          '&.selected': {
            backgroundColor: '#f9f5ff',
            color: 'var(--primary-color)',
            fontWeight: 600,

            '&:hover': {
              bgcolor: '#ede3fd',
            },
          },
        },

        '& .prev-btn, & .next-btn': {
          boxShadow: 'none',
          display: 'flex',
          alignItems: 'center',
          border: '1px solid lightgray',
          gap: '5px',
          p: 1,
          fontWeight: 500,
          bgcolor: 'white',
          borderRadius: '8px',
          color: '#363636',
          width: 'fit-content',
        },
      }}
    >
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null;

        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = <div>...</div>;
        } else if (type === 'previous') {
          children = (
            <StyledButton
              type="button"
              {...item}
              className="prev-btn"
              onClick={event => handleClick(event, page)}
            >
              <ArrowBackIosNewIcon fontSize="small" />
              Previous
            </StyledButton>
          );
        } else if (type === 'next') {
          children = (
            <StyledButton
              type="button"
              {...item}
              className="next-btn"
              onClick={event => handleClick(event, page)}
            >
              Next
              <ArrowForwardIosIcon fontSize="small" />
            </StyledButton>
          );
        } else if (type === 'page') {
          children = (
            <StyledButton
              type="button"
              className={selected ? 'selected' : ''}
              {...item}
              onClick={event => handleClick(event, page)}
            >
              {page}
            </StyledButton>
          );
        } else {
          children = (
            <button type="button" {...item}>
              {type}
            </button>
          );
        }

        return (
          <li
            key={index}
            className={
              type === 'previous' ? 'prev' : type === 'next' ? 'next' : ''
            }
          >
            {children}
          </li>
        );
      })}
    </List>
  );
};

export default index;
