import MasonaryNFTs from 'components/MasonaryNFTs';
import React from 'react';

function SingItems({ updateInteractions, isMe, userId, setCount, followUser }) {
  return (
    <MasonaryNFTs
      onInteractionsUpdated={updateInteractions}
      showNftStatus={Boolean(isMe)}
      hideSubItems={Boolean(isMe)}
      userId={userId}
      avoidNullUserId
      setCount={setCount}
      toggleUserFollow={followUser}
      isOtherProfile={!isMe}
      hide
    />
  );
}

export default SingItems;
