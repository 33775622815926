import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ServerGrid from 'components/CustomDataGrid/ServerGrid';
import moment from 'moment';
import { useApi } from 'api';
import { useSelector } from 'react-redux';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  Tooltip,
} from '@mui/material';
import _ from 'lodash';
import Identicon from 'components/Identicon';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CloseIcon from '@mui/icons-material/Close';
import { CommunityInfo } from './components/communityInfo';
import { shortenAddress } from 'utils';

import styles from './styles.module.scss';

const CustomBox = styled(Box)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
});

const PAGE_SIZE = 10;

const index = () => {
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1); // starts from 1
  const [sortColumns, setSortColumns] = useState([]);
  const [selectedToken, setSelectedToken] = useState();
  const { authToken } = useSelector(state => state.Auth);

  const { getUserPayTokens, explorerUrl } = useApi();

  useEffect(() => {
    if (authToken) {
      (async () => {
        const variables = {
          from: (page - 1) * PAGE_SIZE,
          count: PAGE_SIZE,
        };

        const { data } = await getUserPayTokens(authToken, variables);

        setRows(data.payTokens);
        setTotal(data.total);
      })();
    }
  }, [authToken, page]);

  const getComparator = useCallback(() => {
    return (a, b) => (a > b ? 1 : a === b ? 0 : -1);
  }, []);

  const sortedRows = useMemo(() => {
    if (rows.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns, getComparator]);

  const columns = [
    {
      key: 'name',
      name: 'Name',
      width: 190,
      minWidth: 190,
      frozen: true,
      renderCell: ({ row }) => {
        return (
          <Box className={styles.nameContainer}>
            <div className={styles.avatarContainer}>
              {row.icon ? (
                <img src={row.icon} className={styles.avatar} />
              ) : (
                <Identicon
                  className={styles.identicon}
                  account={row.address}
                  size={30}
                  style={{
                    width: '30px',
                    height: '30px',
                  }}
                />
              )}
            </div>
            <div>{row.name}</div>
          </Box>
        );
      },
    },
    {
      key: 'symbol',
      name: 'Symbol',
      minWidth: 80,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomBox width="100%">
          <Tooltip
            title={row.symbol}
            arrow
            placement="top"
            className={styles.tooltip}
          >
            <CustomBox
              sx={{
                cursor: 'pointer',
                color: 'var(--primary-color) !important',
                display: 'flex',
                width: 'fit-content',
              }}
            >
              {row.symbol}
            </CustomBox>
          </Tooltip>
        </CustomBox>
      ),
    },
    {
      key: 'community',
      name: 'Community',
      minWidth: 80,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomBox width="100%">
          <Tooltip
            title={row.community?.name}
            arrow
            placement="top"
            className={styles.tooltip}
          >
            <CustomBox
              sx={{
                cursor: 'pointer',
                color: 'var(--primary-color) !important',
                display: 'flex',
                width: 'fit-content',
              }}
            >
              {row.community?.name}
            </CustomBox>
          </Tooltip>
        </CustomBox>
      ),
    },
    {
      key: 'address',
      name: 'Address',
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip
          title={row.address}
          arrow
          placement="top"
          className={styles.tooltip}
        >
          <a
            href={`${explorerUrl}token/${row.address}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {shortenAddress(row.address)}
          </a>
        </Tooltip>
      ),
    },
    {
      key: 'status',
      name: 'Status',
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomBox
          sx={{
            color: 'var(--primary-color) !important',
            display: 'flex',
            width: 'fit-content',
          }}
        >
          {_.upperFirst(row.status)}
        </CustomBox>
      ),
    },
    {
      key: 'createdAt',
      name: 'Register Date',
      minWidth: 130,
      width: 130,
      sortable: false,
      renderCell: ({ row }) => (
        <div>{moment(row.createdAt).format('DD MMMM YYYY')}</div>
      ),
    },
    {
      key: 'action',
      name: '',
      minWidth: 40,
      width: 40,
      sortable: false,
      renderCell: ({ row: token }) => (
        <CustomBox sx={{ position: 'absolute', right: 5, top: 0 }}>
          <Tooltip
            title="View Details"
            arrow
            placement="left"
            componentsProps={{
              popper: {
                sx: {
                  zIndex: 99999,
                },
              },
            }}
          >
            <IconButton
              onClick={() => {
                setSelectedToken(token);
              }}
              sx={{
                border: '1px solid var(--background-color)',
                p: 0.8,
                svg: {
                  color: 'var(--primary-text-color)',
                },
              }}
            >
              <FolderOpenIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </CustomBox>
      ),
    },
  ];

  const handleCancel = () => {
    setSelectedToken();
  };

  return (
    <div className={styles.container}>
      {Boolean(selectedToken) && (
        <Dialog
          open={Boolean(selectedToken)}
          onClose={handleCancel}
          sx={{
            bottom: '20%',
            '& .MuiPaper-root': {
              width: '500px',
            },
          }}
        >
          <DialogTitle>
            <Box>Token Details</Box>
            <IconButton
              aria-label="close"
              onClick={handleCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              maxHeight: '400px',
              overflowY: 'auto',
            }}
          >
            <Box className={styles.modal}>
              <Stack gap={1}>
                <Box
                  className={styles.content}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    '& > div': {
                      borderBottom: '1px solid #EBEBEB',
                      pb: 1,
                    },
                  }}
                >
                  <Box className={styles.nameContainer}>
                    <div className={styles.avatarContainer}>
                      {selectedToken.icon ? (
                        <img
                          src={selectedToken.icon}
                          className={styles.avatar}
                        />
                      ) : (
                        <Identicon
                          className={styles.identicon}
                          account={selectedToken.address}
                          size={30}
                          style={{
                            width: '30px',
                            height: '30px',
                          }}
                        />
                      )}
                    </div>
                    <div>
                      {selectedToken.name} ({selectedToken.symbol})
                    </div>
                  </Box>
                  <Box className={styles.titleContainer}>
                    Address:
                    <Tooltip
                      title={selectedToken.address}
                      arrow
                      placement="top"
                      className={styles.tooltip}
                    >
                      <a
                        href={`${explorerUrl}token/${selectedToken.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        {shortenAddress(selectedToken.address)}
                      </a>
                    </Tooltip>
                  </Box>

                  <CommunityInfo selectedToken={selectedToken} />
                </Box>
              </Stack>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      <div className={styles.title}>
        Meme Tokens
        <div className={styles.subTitle}>
          Take a look at all your meme tokens
        </div>
      </div>

      <div className={styles.gridContainer}>
        <ServerGrid
          mainHeader="Meme Tokens"
          subHeader={total === 1 ? '1 token' : `${total} Tokens`}
          columns={columns}
          setRows={setRows}
          setSortColumns={setSortColumns}
          sortColumns={sortColumns}
          sortedRows={sortedRows}
          rowHeight={40}
          pageSize={PAGE_SIZE}
          setPage={setPage}
          totalRows={total}
          onRowClick={() => {
            // console.log(idx, row)
          }}
        />
      </div>
    </div>
  );
};

export default index;
