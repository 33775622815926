import React, { useMemo } from 'react';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';
import DataGrid from 'react-data-grid';

import CustomPagination from 'components/CustomPagination';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import 'react-data-grid/lib/styles.css';

const index = ({
  className,
  mainHeader,
  subHeader,
  columns,
  sortedRows,
  setRows,
  totalRows,
  setPage,
  sortColumns,
  setSortColumns,
  onRowClick,
  pageSize = 8,
  rowHeight = 40,
  rowClass = null,
}) => {
  const { mode } = useSelector(state => state.HeaderOptions);
  const pages = Math.ceil(totalRows / pageSize);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedRows = useMemo(() => sortedRows, [sortedRows]);

  return (
    <Box
      className={cx(styles.datagridContainer, className)}
      sx={{
        '& .rdg-light': {
          height: '500px',
        },
      }}
    >
      <div className={styles.header}>
        <div>{mainHeader}</div>
        <div className={styles.userCount}>{subHeader}</div>
      </div>

      <DataGrid
        columns={memoizedColumns}
        rows={memoizedRows}
        rowKeyGetter={row => row.id || uuidv4()}
        onRowsChange={setRows}
        onRowClick={onRowClick}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        defaultColumnOptions={{
          sortable: false,
        }}
        headerRowHeight={50}
        rowHeight={rowHeight}
        className={mode === 'light' ? 'rdg-light' : 'rdg-dark'} // light mode
        rowClass={rowClass}
      />

      <div className={styles.pagination}>
        {pages > 1 ? (
          <CustomPagination pages={pages} setPage={page => setPage(page)} />
        ) : null}
      </div>
    </Box>
  );
};

export default index;
