import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { useApi } from 'api';
import { Search as SearchIcon } from '@mui/icons-material';
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Paper,
  InputAdornment,
} from '@mui/material';
import FilterActions from 'actions/filter.actions';
import Identicon from 'components/Identicon';

import styles from './styles.module.scss';
import { isVideoFormat } from 'utils';

function SearchBox({ id, className, isSticky }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);

  const { search: searchText } = useSelector(state => state.Filter);
  const { user: me } = useSelector(state => state.Auth);

  const setSearchText = search => {
    dispatch(FilterActions.udpateSearchPhrase(search));
  };

  const [loading, setLoading] = useState(false);
  const { searchNames } = useApi();

  const [search] = useDebounce(searchText, 500);

  const debouncedSearch = async text => {
    if ((text?.length || 0) < 2) {
      setOptions([]);
      return;
    }

    setLoading(true);
    const { data } = await searchNames(text);
    const nfts = data?.nfts?.map(nft => ({
      id: nft.nftId,
      name: (nft.name || '').toLowerCase(),
      tokenId: nft.nftContractId,
      address: nft.contractAddress,
      avatarUrl: nft.nftAssets?.at(0)?.url || '',
      extras: `${nft.category?.type}${nft.tags
        ?.map(tag => tag.name)
        .join('')}`.trim(),
      type: 'nft',
      group: 'Assets',
    })) || [{ group: 'Assets' }];

    const users = data?.users?.map(user => ({
      id: user.userId,
      name: (user.name || '').toLowerCase(),
      email: user.email,
      address: user.address,
      avatarUrl: user.avatar || '',
      extras: '',
      type: 'user',
      group: 'Accounts',
    })) || [{ group: 'Accounts' }];

    setOptions([...users, ...nfts]);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    debouncedSearch(search);
  }, [search]);
  useEffect(() => {
    // close options
    setOpenSearch(false);
  }, [isSticky]);

  const handleSearchNameChanged = async search => {
    const { id, name, type, address } = search || {};

    if (id) {
      setValue(null);
      setSearchText('');
      setOptions([]);
      switch (type) {
        case 'nft':
          history.push(`/explore/${name.toLowerCase().replaceAll(' ', '+')}`);
          break;
        case 'user':
          if (address === me?.address) {
            history.push('/profile/');
          } else {
            history.push(`/account/${name.toLowerCase().replaceAll(' ', '+')}`);
          }
          break;
      }
    }
  };

  const filterOptions = options => {
    // const inputLowerCase = inputValue.toLowerCase();

    // const query = options.filter(option =>
    //   [option.address, option.email, option.extras, option.name].some(value =>
    //     String(value)
    //       .toLowerCase()
    //       .includes(inputLowerCase.toLowerCase())
    //   )
    // );

    return options;
  };

  const handleEnter = event => {
    if (event.key === 'Enter') {
      if (options.length === 1) {
        const { type, name, address } = options[0];
        switch (type) {
          case 'nft':
            history.push(`/explore/${name.toLowerCase().replaceAll(' ', '+')}`);
            break;
          case 'user':
            if (address === me?.address) {
              history.push('/profile/');
            } else {
              history.push(
                `/account/${name.toLowerCase().replaceAll(' ', '+')}`
              );
            }
            break;
        }
      } else {
        history.push(`/search/${event.target.value}`);
      }
    }
  };

  function CustomPaper(props) {
    return searchText ? (
      <Paper {...props}>
        {props.children}
        <Box
          sx={{
            p: 1,
            textAlign: 'center',
            fontStyle: 'italic',
          }}
        >
          <Typography variant="body2">
            Press enter to search all items
          </Typography>
        </Box>
      </Paper>
    ) : (
      <></>
    );
  }

  return (
    <div id={id || Date.now().toString()} className={cx(styles.searchBox)}>
      <Autocomplete
        className={className}
        value={value}
        inputValue={searchText}
        freeSolo
        sx={{
          width: '100%',
          '& *': {
            boxShadow: 'none !important',
          },
          '& .MuiInputBase-root': {
            borderRadius: '12px !important',
            padding: '0 !important',
            height: 52,
            overflow: 'hidden',
            color: 'var(--primary-text-color)',
            backgroundColor: 'var(--place-holder-color)',
            border: '1px solid var(--light-border-color)',
            opacity: 0.7,
            '@media (max-width: 768px)': {
              height: 44,
            },
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              borderColor: 'var(--border-color)',
              borderWidth: 1,
            },
          },
          '& .Mui-focused': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              borderColor: 'var(--border-color)',
              borderWidth: 1,
            },
          },
          '& .MuiSvgIcon-root': {
            color: 'var(--primary-text-color)',
          },
        }}
        groupBy={options => options.group}
        filterOptions={filterOptions}
        open={openSearch}
        onChange={(e, selectedValue) => {
          e.preventDefault();
          e.stopPropagation();
          handleSearchNameChanged(selectedValue);
        }}
        onOpen={() => {
          setOpenSearch(true);
        }}
        onClose={() => {
          setOpenSearch(false);
        }}
        onInputChange={(_, newInputValue) => {
          setSearchText(newInputValue);
        }}
        loading={openSearch && loading}
        getOptionLabel={option => option.name || ''}
        options={options}
        getOptionKey={option => option.id || Date.now().toString()}
        PaperComponent={CustomPaper}
        renderOption={(props, option, state) => (
          <Box
            key={state.index}
            component="li"
            {...props}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: `var(--secondary-background-color)`,
              '&.MuiAutocomplete-option': {
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                pt: 1,
                pb: 1,
                gap: 1,
                '&.Mui-focused': {
                  backgroundColor: 'var(--third-background-color) !important',
                },
                '& .avatar': {
                  display: 'flex',
                  width: 40,
                  justifyContent: 'center',
                },
                '&:hover': {
                  backgroundColor: 'var(--third-background-color) !important',
                },
              },
              '& .avatar-user': {
                display: 'flex',
                maxWidth: 35,
                maxHeight: 35,
                borderRadius: '50%',
                overflow: 'hidden',
                '& img': {
                  maxWidth: 35,
                  maxHeight: 35,
                },
              },
              '& .avatar-nft .avatar-icon': {
                display: 'flex',
                width: 40,
                height: 40,
                borderRadius: '5px',
                overflow: 'hidden',
              },
            }}
          >
            <div className="avatar">
              <div
                className={cx(
                  option.type === 'nft' ? 'avatar-nft' : 'avatar-user'
                )}
              >
                {option.avatarUrl ? (
                  isVideoFormat(option.avatarUrl) ? (
                    <video src={option.avatarUrl} className="avatar-icon" />
                  ) : (
                    <img
                      src={
                        option.avatarUrl.includes('mypinata')
                          ? `${option.avatarUrl}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                          : option.avatarUrl
                      }
                      className="avatar-icon"
                    />
                  )
                ) : (
                  <Identicon
                    size={35}
                    account={option.id}
                    className={styles.icon}
                  />
                )}
              </div>
            </div>
            <Typography fontWeight={500}>{option.name}</Typography>
          </Box>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Try searching for items, tags and accounts....."
            onKeyDown={handleEnter}
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    m: 0,
                    height: '100%',
                    '& svg': {
                      color: '#ddd',
                    },
                  }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiInputBase-input': {
                pl: '0 !important',
              },
            }}
          />
        )}
      />
    </div>
  );
}

export default SearchBox;
