import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';
import DataGrid from 'react-data-grid';
import CustomPagination from 'components/CustomPagination';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import 'react-data-grid/lib/styles.css';

const index = ({
  className,
  mainHeader,
  subHeader,
  columns,
  sortedRows: rows,
  setRows,
  sortColumns,
  setSortColumns,
  pageSize = 8,
  rowHeight = 40,
}) => {
  const { mode } = useSelector(state => state.HeaderOptions);
  const [page, setPage] = useState(1);

  const pages = Math.ceil(rows.length / pageSize);

  const start = (page - 1) * pageSize;

  const sortedRows = useMemo(() => rows.slice(start, start + pageSize), [
    rows,
    start,
  ]);
  return (
    <Box className={cx(styles.datagridContainer, className)}>
      <div className={styles.header}>
        <div>{mainHeader}</div>
        <div className={styles.userCount}>{subHeader}</div>
      </div>

      <DataGrid
        columns={columns}
        rows={sortedRows}
        rowKeyGetter={row => row.id || uuidv4()}
        onRowsChange={setRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        defaultColumnOptions={{
          sortable: false,
        }}
        headerRowHeight={50}
        rowHeight={rowHeight}
        className={mode === 'light' ? 'rdg-light' : 'rdg-dark'} // light mode
      />

      <div className={styles.pagination}>
        {pages > 1 ? (
          <CustomPagination pages={pages} setPage={page => setPage(page)} />
        ) : null}
      </div>
    </Box>
  );
};

export default index;
