import React, { useEffect, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
  styled,
} from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './styles.module.scss';
import { useApi } from 'api';
import { useSelector } from 'react-redux';
import { StyledAccordion, StyledButton } from 'components/StyledComponents';
import toast from 'utils/toast';

const CustomCheckbox = styled(Checkbox)(() => ({
  color: 'lightgray',
  '&.Mui-checked': {
    color: 'var(--primary-color)',
  },
  '&.MuiCheckbox-indeterminate': {
    color: 'var(--primary-color)',
  },
}));

const selfSettings = [
  {
    value: 'sNftBuy',
    title: 'You purchased a new Asset.',
    description: 'You purchased a new asset.',
  },
  {
    value: 'sNftSell',
    title: 'You sold an Asset.',
    description: 'Your asset is sold.',
  },

  {
    value: 'sNftOffer',
    title: 'You got an Offer to your Asset.',
    description: 'You got an offer to your asset.',
  },
  {
    value: 'sNftAuction',
    title: 'You started a New Auction',
    description: 'You started a New Auction',
  },
  {
    value: 'sNftBidToAuctionCancel',
    title: 'You canceled a bid on an Auction.',
    description: 'You canceled a bid on an Auction.',
  },
  {
    value: 'sNftBidToAuction',
    title: 'You got a bid to your Auction.',
    description: 'You got a bid to your Auction.',
  },
  {
    value: 'sNftAuctionPrice',
    title: 'The Bid to your Auction is Updated.',
    description: 'The Bid to your Auction is Updated.',
  },
  {
    value: 'sNftPrice',
    title: 'Your Asset price is updated.',
    description: 'Your asset price is updated.',
  },
  {
    value: 'sAuctionWin',
    title: 'You purchased an Asset in Auction.',
    description: 'You purchased an asset in Auction.',
  },
];

const followerSettings = [
  {
    value: 'fNftList',
    title: 'Your Following Lists an Asset.',
    description: 'Lists an asset for sale.',
  },
  {
    value: 'fNftSell',
    title: 'Your Following Sells an Asset.',
    description: 'Your Following Sells an Asset.',
  },
  {
    value: 'fNftPrice',
    title: 'Your Following Updates an Asset Price for Sale.',
    description: 'Updates asset price on sale.',
  },
  {
    value: 'fNftAuction',
    title: 'Your Following Starts a New Auction.',
    description: 'Starts a new auction.',
  },
  {
    value: 'fNftAuctionPrice',
    title: 'Your Following Updates an Asset Auction Price.',
    description: 'Updats an auction price.',
  },
];

const index = () => {
  const {
    getUserNotificationSettings,
    userUpdateNotificationSetting,
  } = useApi();
  const [loading, setLoading] = useState(false);
  const { authToken } = useSelector(state => state.Auth);
  const [selfAllChecked, setSelfAllChecked] = useState(false);
  const [followerAllChecked, setFollowerAllChecked] = useState(false);

  const [selfState, setSelfState] = useState(
    selfSettings.reduce((acc, item) => {
      acc[item.value] = false; // init, set false
      return acc;
    }, {})
  );

  const [followerState, setFollowerState] = useState(
    followerSettings.reduce((acc, item) => {
      acc[item.value] = false; // init, set false
      return acc;
    }, {})
  );

  const handleSelfItemsChange = value => {
    setSelfState(prevState => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  const handleSelfChange = () => {
    setSelfState(prevState => {
      const newState = {};
      Object.keys(prevState).forEach(key => {
        newState[key] = !selfAllChecked;
      });
      return newState;
    });
  };

  const handleFollowerItemsChange = value => {
    setFollowerState(prevState => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  const handleFollowerChange = () => {
    setFollowerState(prevState => {
      const newState = {};
      Object.keys(prevState).forEach(key => {
        newState[key] = !followerAllChecked;
      });
      return newState;
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await getUserNotificationSettings(authToken);
        const tempSelectedNotifs = data.map(a => a.notificationType.name);

        setSelfState(prevState => {
          const newState = {};
          Object.keys(prevState).forEach(key => {
            newState[key] = tempSelectedNotifs.includes(key)
              ? true
              : prevState[key];
          });
          return newState;
        });

        setFollowerState(prevState => {
          const newState = {};
          Object.keys(prevState).forEach(key => {
            newState[key] = tempSelectedNotifs.includes(key)
              ? true
              : prevState[key];
          });
          return newState;
        });
      } catch {
        //
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setSelfAllChecked(Object.values(selfState).every(Boolean));
  }, [selfState]);

  useEffect(() => {
    setFollowerAllChecked(Object.values(followerState).every(Boolean));
  }, [followerState]);

  const handleUpdateNotifications = async () => {
    const updatesSelf = Object.keys(selfState).filter(
      key => selfState[key] === true
    );

    const updatesFollower = Object.keys(followerState).filter(
      key => followerState[key] === true
    );

    const updateKeys = [...updatesSelf, ...updatesFollower];

    const { data } = await userUpdateNotificationSetting(authToken, updateKeys);

    if (data) {
      toast('success', data.message);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Notifications
        <div className={styles.subTitle}>Update your notification settings</div>
      </div>

      <StyledAccordion
        border="1px var(--border-color) solid !important"
        titlesize="24px !important"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="activity-notifications"
          sx={{
            '.Mui-expanded': {
              margin: '12px 0',
            },
          }}
        >
          <div className={styles.mainForm}>
            <FormControlLabel
              onClick={e => e.stopPropagation()}
              label={
                <Typography className={styles.mainFormTitle}>
                  Activity Notifications
                </Typography>
              }
              control={
                <CustomCheckbox
                  checked={selfAllChecked}
                  indeterminate={
                    !selfAllChecked && Object.values(selfState).some(Boolean)
                  }
                  onChange={handleSelfChange}
                />
              }
            />
            <div className={styles.mainFormSubtitle}>
              Check the box to receive an email notification for each platform
              action relating to your Assets.
            </div>
          </div>
        </AccordionSummary>

        <Divider className={styles.divider} />

        <AccordionDetails>
          <div className={styles.content}>
            {selfSettings.map((setting, idx) =>
              !loading ? (
                <div className={styles.item} key={idx}>
                  <FormControlLabel
                    label={<Typography>{setting.title}</Typography>}
                    control={
                      <CustomCheckbox
                        checked={selfState[setting.value]}
                        onChange={() => handleSelfItemsChange(setting.value)}
                      />
                    }
                  />
                  <div className={styles.subTitle}>{setting.description}</div>
                </div>
              ) : (
                <Skeleton key={idx} height={80} />
              )
            )}
          </div>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion border="1px var(--border-color) solid !important">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="following-activity-notifications"
          sx={{
            '.Mui-expanded': {
              margin: '12px 0',
            },
          }}
        >
          <div className={styles.mainForm}>
            <FormControlLabel
              onClick={e => e.stopPropagation()}
              label={
                <Typography className={styles.mainFormTitle}>
                  Following Activity Notifications
                </Typography>
              }
              control={
                <CustomCheckbox
                  checked={followerAllChecked}
                  indeterminate={
                    !followerAllChecked &&
                    Object.values(followerState).some(Boolean)
                  }
                  onChange={handleFollowerChange}
                />
              }
            />
            <div className={styles.mainFormSubtitle}>
              Check the box to receive an email notification for each of your
              following&apos;s platform actions.
            </div>
          </div>
        </AccordionSummary>

        <Divider className={styles.divider} />
        <AccordionDetails>
          <div className={styles.content}>
            {followerSettings.map((setting, idx) =>
              !loading ? (
                <div className={styles.item} key={idx}>
                  <FormControlLabel
                    label={<Typography>{setting.title}</Typography>}
                    control={
                      <CustomCheckbox
                        checked={followerState[setting.value]}
                        onChange={() =>
                          handleFollowerItemsChange(setting.value)
                        }
                      />
                    }
                  />
                  <div className={styles.subTitle}>{setting.description}</div>
                </div>
              ) : (
                <Skeleton key={idx} height={80} />
              )
            )}
          </div>
        </AccordionDetails>
      </StyledAccordion>

      <div>
        <StyledButton onClick={handleUpdateNotifications} sx={{ pl: 2, pr: 2 }}>
          Save Settings
        </StyledButton>
        <div className={styles.mainFormSubtitle}>
          * Mark in the boxes and save to subscribe back to email notifications.
        </div>
      </div>
    </div>
  );
};

export default index;
