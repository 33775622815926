import HeaderConstants from '../constants/header.constants';

const HeaderActions = {
  toggleSearchbar,
  toggleMode,
};

function toggleSearchbar(toggle) {
  return dispatch => {
    dispatch(_toggleSearchbar(toggle));
  };
}

const _toggleSearchbar = toggle => {
  return {
    type: HeaderConstants.TOGGLESEARCHBAR,
    toggle: toggle,
  };
};

function toggleMode(toggle) {
  return dispatch => {
    dispatch(_toggleMode(toggle));
  };
}

const _toggleMode = toggle => {
  return {
    type: HeaderConstants.TOGGLE_MODE,
    toggle: toggle,
  };
};

export default HeaderActions;
