import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ServerGrid from 'components/CustomDataGrid/ServerGrid';
import moment from 'moment';
import { useApi } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, styled, Tooltip } from '@mui/material';
import NotiActions from 'actions/noti.actions';

import styles from './styles.module.scss';

const CustomBox = styled(Box)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
});

const PAGE_SIZE = 10;

const index = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1); // starts from 1
  const [sortColumns, setSortColumns] = useState([]);
  const { authToken } = useSelector(state => state.Auth);
  const { notifications } = useSelector(state => state.Notifications);

  const { findAllUserNotifications, notificationViewed } = useApi();

  const onNotificationRead = async notification => {
    if (!authToken || notification.status !== 'new') return;

    const { data } = await notificationViewed(authToken, {
      notificationId: notification.notificationId,
    });

    if (data?.message === 'OK') {
      // set viewed
      setRows(prevRows =>
        prevRows.map(row =>
          row.notificationId === notification.notificationId
            ? {
                ...row,
                status: 'viewed',
              }
            : row
        )
      );
      dispatch(NotiActions.notificationViewed(notification));
    }
  };

  useEffect(() => {
    setRows(prevRows => {
      const existingRowMap = new Map(
        prevRows.map(row => [row.notificationId, row])
      );

      const updatedRows = prevRows.map(row => {
        const matchingNotification = notifications.find(
          n => n.notificationId === row.notificationId
        );
        if (matchingNotification) {
          return { ...row, status: matchingNotification.status };
        }
        return row;
      });

      notifications.forEach(notification => {
        if (!existingRowMap.has(notification.notificationId)) {
          updatedRows.unshift(notification);
        }
      });

      return updatedRows.slice(0, PAGE_SIZE);
    });
  }, [notifications]);

  useEffect(() => {
    if (authToken) {
      (async () => {
        const variables = {
          from: (page - 1) * PAGE_SIZE,
          count: PAGE_SIZE,
          includeViewed: true,
        };

        const { data } = await findAllUserNotifications(authToken, variables);

        setRows(data.notifications);
        setTotal(data.total);
      })();
    }
  }, [authToken, page]);

  const getComparator = useCallback(() => {
    return (a, b) => (a > b ? 1 : a === b ? 0 : -1);
  }, []);

  const handleActionClick = notification => {
    if (notification.link) window.location.href = notification.link;
    onNotificationRead(notification);
  };

  const sortedRows = useMemo(() => {
    if (rows.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns, getComparator]);

  const columns = [
    {
      key: 'title',
      name: 'Title',
      minWidth: 250,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomBox width="100%">
          <Tooltip
            title={row.title}
            arrow
            placement="top"
            className={styles.tooltip}
          >
            <CustomBox
              sx={{
                cursor: 'pointer',
                color: 'var(--primary-color) !important',
                fontWeight: row.status === 'new' ? 600 : 500,
                display: 'flex',
                width: 'fit-content',
              }}
              onClick={() => handleActionClick(row)}
            >
              {row.title}
            </CustomBox>
          </Tooltip>
        </CustomBox>
      ),
    },
    {
      key: 'message',
      name: 'Message',
      minWidth: 250,
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip
          title={row.message}
          arrow
          placement="top"
          className={styles.tooltip}
        >
          <CustomBox>{row.message}</CustomBox>
        </Tooltip>
      ),
    },
    {
      key: 'status',
      name: 'Status',
      minWidth: 100,
      width: 100,
      sortable: false,
      renderCell: ({ row }) => <div>{row.status}</div>,
    },
    {
      key: 'createdAt',
      name: 'Date Received',
      minWidth: 110,
      width: 110,
      sortable: false,
      renderCell: ({ row }) => (
        <div>{moment(row.createdAt).format('DD MMMM YYYY')}</div>
      ),
    },
    {
      key: 'action',
      name: '',
      minWidth: 40,
      width: 40,
      sortable: false,
      renderCell: ({ row }) =>
        row.status === 'new' && (
          <CustomBox sx={{ position: 'absolute', right: 5, top: 0 }}>
            <Tooltip
              title="Mark as read"
              arrow
              placement="left"
              componentsProps={{
                popper: {
                  sx: {
                    zIndex: 99999,
                  },
                },
              }}
            >
              <IconButton
                onClick={() => {
                  onNotificationRead(row);
                }}
                sx={{
                  border: '1px solid var(--background-color)',
                  p: 1.4,
                }}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: 'var(--primary-color)',
                    position: 'absolute',
                  }}
                />
              </IconButton>
            </Tooltip>
          </CustomBox>
        ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Notifications
        <div className={styles.subTitle}>
          Take a look at all your notifications
        </div>
      </div>

      <div className={styles.gridContainer}>
        <ServerGrid
          mainHeader=""
          subHeader={total === 1 ? '1 Notification' : `${total} Notifications`}
          columns={columns}
          setRows={setRows}
          setSortColumns={setSortColumns}
          sortColumns={sortColumns}
          sortedRows={sortedRows}
          rowHeight={40}
          pageSize={PAGE_SIZE}
          setPage={setPage}
          totalRows={total}
          onRowClick={() => {
            // console.log(idx, row)
          }}
          rowClass={row => {
            return row.status === 'new' ? styles.new : undefined;
          }}
        />
      </div>
    </div>
  );
};

export default index;
