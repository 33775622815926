import React, { useState, useEffect } from 'react';
import styles from './../styles.module.scss';
import cx from 'classnames';
import { useHistory } from 'react-router';
import letterOk from 'assets/svgs/v2/letter_ok.svg';
import AuthGrid from '../AuthGrid';
import { StyledButton } from 'components/StyledComponents';

const UnsubscribeEmail = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);

  const handleConfirmAccount = async () => {
    window.scrollTo(0, 0);
    history.push('/');
  };

  return (
    <AuthGrid>
      <form className={styles.authForm}>
        <div className={styles.title}>Unsubscribe successful</div>
        <div className={styles.confirmIcon}>
          <img src={letterOk} />
        </div>
        <div className={styles.buttonsWrapper}>
          <StyledButton
            className={cx(styles.submitButton)}
            onClick={handleConfirmAccount}
          >
            Ok
          </StyledButton>
        </div>
      </form>
    </AuthGrid>
  );
};
export default UnsubscribeEmail;
