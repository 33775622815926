import React, { useState } from 'react';
import { AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { StyledAccordion } from 'components/StyledComponents';
import styles from '../styles.module.scss';
import Input from 'components/Input';
import BootstrapTooltip from 'components/BootstrapTooltip';

const Summary = ({ asset, tokenDetails }) => {
  const [expandedToken, setExpandedToken] = useState(true);
  const [expandedCommunity, setExpandedCommunity] = useState(true);

  return (
    <div className={styles.panelInputs}>
      <StyledAccordion
        border="1px var(--border-color) solid !important"
        titlesize="24px !important"
        sx={{ overflow: 'hidden' }}
        expanded={expandedToken}
        onChange={() => setExpandedToken(prev => !prev)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          className={styles.inputGroup}
          id="panel1-header"
          style={{
            boxShadow: 'none',
          }}
        >
          Token Details
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.formGroup}>
            <Input
              className={styles.readOnlyNoBorder}
              placeholder="0x1234..."
              value={tokenDetails['address']}
              label="Token Contract Address*"
              readOnly
            />
            <Input
              className={styles.readOnlyNoBorder}
              placeholder=""
              value={tokenDetails['name']}
              label="Token Name*"
              readOnly
            />
            <Input
              className={styles.readOnlyNoBorder}
              placeholder=""
              value={tokenDetails['symbol']}
              label="Token Symbol*"
              readOnly
            />
            <Input
              className={styles.readOnlyNoBorder}
              placeholder=""
              value={tokenDetails['decimals']}
              label="Token Decimals*"
              readOnly
            />
            <div className={styles.uploadContainer}>
              <div className={styles.uploadTitle}>
                Token Logo&nbsp;
                <BootstrapTooltip
                  title="Drag or choose your logo to upload"
                  placement="top"
                >
                  <HelpOutlineIcon />
                </BootstrapTooltip>
              </div>
              <Stack sx={{ m: 3 }}>
                {tokenDetails['icon'] || asset?.fullUrl || asset?.url ? (
                  <img
                    src={tokenDetails['icon'] || asset.fullUrl || asset.url}
                    width="80"
                    height="80"
                    className={styles.avatarBig}
                  />
                ) : (
                  <>No logo</>
                )}
              </Stack>
            </div>
          </div>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        border="1px var(--border-color) solid !important"
        titlesize="24px !important"
        sx={{ overflow: 'hidden' }}
        expanded={expandedCommunity}
        onChange={() => setExpandedCommunity(prev => !prev)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          className={styles.inputGroup}
          id="panel2-header"
          style={{
            boxShadow: 'none',
          }}
        >
          Community
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.formGroup}>
            <Input
              placeholder="Community Name"
              className={styles.readOnlyNoBorder}
              value={tokenDetails['community']['name']}
              label="Community Name*"
              readOnly
            />
            <Input
              className={styles.readOnlyNoBorder}
              placeholder="Description"
              value={tokenDetails['community']['description']}
              label="Description*"
              readOnly
            />

            {tokenDetails['community']['website'] && (
              <Input
                className={styles.readOnlyNoBorder}
                placeholder="https://example.com"
                value={tokenDetails['community']['website']}
                label="Website"
                readOnly
              />
            )}

            {tokenDetails['community']['twitter'] && (
              <Input
                className={styles.readOnlyNoBorder}
                placeholder=""
                value={tokenDetails['community']['twitter']}
                readOnly
                label="X"
              />
            )}

            {tokenDetails['community']['discord'] && (
              <Input
                className={styles.readOnlyNoBorder}
                placeholder=""
                value={tokenDetails['community']['discord']}
                readOnly
                label="Discord"
              />
            )}

            {tokenDetails['community']['instagram'] && (
              <Input
                className={styles.readOnlyNoBorder}
                placeholder=""
                value={tokenDetails['community']['instagram']}
                readOnly
                label="Instagram"
              />
            )}

            {tokenDetails['community']['telegram'] && (
              <Input
                className={styles.readOnlyNoBorder}
                placeholder=""
                value={tokenDetails['community']['telegram']}
                readOnly
                label="Telegram"
              />
            )}

            {tokenDetails['community']['tiktok'] && (
              <Input
                className={styles.readOnlyNoBorder}
                placeholder=""
                value={tokenDetails['community']['tiktok']}
                readOnly
                label="TikTok"
              />
            )}
          </div>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
};

export default Summary;
