import React, { useState, useRef, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { styled, useMediaQuery } from '@mui/material';
import cx from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import 'react-alice-carousel/lib/alice-carousel.css';
import AliceCarousel from 'react-alice-carousel';
import styles from './styles.module.scss';
import ModalActions from '../../actions/modal.actions';
import LazyLoader from 'components/LazyLoader';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { getFileType } from 'utils/getFileType';
import './carousel.scss';
const AlphaBackdrop = styled(Backdrop)({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
});

const CarouselModal = ({ items, startIndex }) => {
  const dispatch = useDispatch();
  const [sliderIndex, setSliderIndex] = useState(0);
  const [indexes, setIndexes] = useState({});
  const modalCarouselRef = useRef(null);
  const vidRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 1000px)');

  const { photoCarouselModalVisible } = useSelector(state => state.Modal);
  const [carouselImages, setCarouselImages] = useState([]);
  const handleCloseCarouselModal = () => {
    dispatch(ModalActions.hidePhotoCarouselModal());
  };

  useEffect(() => {
    if (items) {
      const onlyPhotos = [];
      const allIndexes = {};
      for (let i = 0; i < items.length; i++) {
        const type = getFileType(items[i]);
        if (type === 'image') {
          onlyPhotos.push(items[i]);
          allIndexes[i] = onlyPhotos.length - 1;
        }
      }
      setIndexes(allIndexes);
      const photos = (Object.values(onlyPhotos) || []).map(el => {
        return (
          <LazyLoader
            key={el}
            url={el}
            rootElement={modalCarouselRef}
            vidRef={vidRef}
            imgStyle={styles}
          />
        );
      });
      setCarouselImages(photos);
    }
  }, [items]);

  return (
    <Modal
      className={styles.modalPopup}
      aria-labelledby="photo-modal-carousel"
      open={photoCarouselModalVisible}
      onClose={handleCloseCarouselModal}
      BackdropComponent={AlphaBackdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={photoCarouselModalVisible}>
        <div className={styles.modalSliderContainer} id="photo-modal-carousel">
          <div className={styles.closeLabel} onClick={handleCloseCarouselModal}>
            <CloseIcon />
          </div>
          <div className={styles.subModalContainer}>
            <div
              className={cx(
                styles.sliderButtonWrapper,
                styles.leftButton,
                sliderIndex === 0 && styles.hideButton
              )}
              onClick={() => {
                modalCarouselRef.current.slidePrev();
                if (vidRef.current) {
                  vidRef.current.pause();
                }
              }}
            >
              <ArrowBackIosNew />
            </div>
            <div
              className={cx(styles.carouselContainer, 'carouselModal')}
              onClick={() => {
                if (!isMobile) handleCloseCarouselModal();
              }}
            >
              <AliceCarousel
                ref={modalCarouselRef}
                activeIndex={indexes[startIndex]}
                items={carouselImages}
                autoWidth={true}
                animationType="fadeout"
                animationDuration={800}
                disableButtonsControls
                disableDotsControls={false}
                onSlideChanged={ev => setSliderIndex(ev.item)}
                onInitialized={ev => setSliderIndex(ev.item)}
              />
            </div>
            <div
              className={cx(
                styles.sliderButtonWrapper,
                styles.rightButton,
                sliderIndex === carouselImages.length - 1 && styles.hideButton
              )}
              onClick={() => {
                modalCarouselRef.current.slideNext();
                if (vidRef.current) {
                  vidRef.current.pause();
                }
              }}
            >
              <ArrowForwardIos />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default CarouselModal;
