import { NotiConstants } from '../constants/noti.constants';

const initialState = {
  notifications: [],
  total: 0,
  from: 0,
  count: 5,

  // asset notification
  checkedAssets: [],
};

export function Notifications(state = initialState, action) {
  switch (action.type) {
    case NotiConstants.FETCH_NOTIFICATIONS: {
      return {
        ...state,
        notifications: Array.from(
          new Map(
            [...state.notifications, ...(action.notifications || [])].map(n => [
              n.notificationId,
              n,
            ])
          ).values()
        ).sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ),
        total: action.total,
        from:
          state.from + (action.notifications?.length || 0) /* fetched count */,
      };
    }
    case NotiConstants.ADD_NEW_NOTIFICATION: {
      const { notification } = action;
      return {
        ...state,
        notifications: [notification, ...state.notifications].sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ),
        total: state.total + 1,
        from: state.from + 1,
      };
    }
    case NotiConstants.NOTIFICATION_VIEWED: {
      return {
        ...state,
        notifications: state.notifications.map(notif =>
          notif.notificationId === action.notificationId
            ? { ...notif, status: 'viewed' }
            : notif
        ),
        total: state.total >= 0 ? state.total - 1 : 0,
        from: state.from >= 0 ? state.from - 1 : 0,
      };
    }
    case NotiConstants.FROM_COUNT: {
      return {
        ...state,
        from: state.from,
        count: state.count,
      };
    }
    case NotiConstants.RESET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [],
        total: 0,
        from: 0,
        count: 5,
      };
    }

    // ASSETS
    case NotiConstants.ADD_CHECKED_ASSET: {
      const newAsset = action.payload;

      const checkedAssetsMap = new Map(
        state.checkedAssets.map(asset => [asset.assetId, asset])
      );

      checkedAssetsMap.set(newAsset.assetId, {
        ...checkedAssetsMap.get(newAsset.assetId),
        ...newAsset,
      });

      return {
        ...state,
        checkedAssets: Array.from(checkedAssetsMap.values()),
      };
    }
    case NotiConstants.REMOVE_CHECKED_ASSET: {
      const assetIdToRemove = action.payload;

      const checkedAssetsMap = new Map(
        state.checkedAssets.map(asset => [asset.assetId, asset])
      );

      checkedAssetsMap.delete(assetIdToRemove);

      return {
        ...state,
        checkedAssets: Array.from(checkedAssetsMap.values()),
      };
    }
    case NotiConstants.GET_CHECKED_ASSET: {
      return state;
    }
    case NotiConstants.RESET_CHECKED_ASSET: {
      return {
        ...state,
        checkedAssets: [],
      };
    }

    default: {
      return state;
    }
  }
}
