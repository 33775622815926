import React from 'react';

const IconSentOffers = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_459_12808)">
      <path
        d="M18.7552 6.07029L0.590183 10.8693C0.407006 10.9173 0.24124 11.0164 0.112204 11.155C-0.0168319 11.2935 -0.103823 11.4659 -0.138631 11.6521C-0.173439 11.8382 -0.154629 12.0304 -0.0843951 12.2062C-0.0141609 12.3821 0.104605 12.5344 0.258062 12.6453L6.73017 17.3419L10.2316 24.5312C10.3139 24.702 10.4431 24.8457 10.6041 24.9457C10.7651 25.0456 10.9513 25.0977 11.1408 25.0957C11.3303 25.0938 11.5154 25.0379 11.6743 24.9346C11.8332 24.8313 11.9595 24.685 12.0382 24.5126L19.9187 7.45683C19.9984 7.28453 20.0274 7.09312 20.0023 6.90495C19.9773 6.71678 19.8991 6.53964 19.7771 6.39422C19.6551 6.2488 19.4942 6.14112 19.3133 6.08375C19.1323 6.02639 18.9388 6.02172 18.7552 6.07029ZM11.1057 21.76L9.59061 18.6491L12.1162 12.8225L5.93958 14.2979L3.13903 12.2656L17.2112 8.54729L11.1057 21.76Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_459_12808">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconSentOffers;
