import React from 'react';
import ReactCodeInput from 'react-verification-input';
import styles from './styles.module.scss';
function CodeInput({
  isFACodeValid,
  twoFACode,
  setTwoFACode,
  setIsFACodeValid,
  handleCheck2fa,
}) {
  return (
    <ReactCodeInput
      classNames={{
        container: styles.codeInput,
        character: isFACodeValid ? styles.character : styles.characterInvalid,
        characterInactive: styles.characterInactive,
        characterSelected: styles.characterSelected,
        characterFilled: styles.characterFilled,
      }}
      validChars="0-9"
      inputProps={{ inputMode: 'numeric' }}
      length={6}
      value={twoFACode}
      placeholder=""
      autoFocus={true}
      onChange={e => {
        setTwoFACode(e);
        setIsFACodeValid(true);
      }}
      onComplete={e => handleCheck2fa(e)}
      inputStyleInvalid={{
        color: 'red',
        border: '1px solid red',
      }}
      isValid={isFACodeValid}
    />
  );
}

export default CodeInput;
