export const Contracts = {
  artion: process.env.REACT_APP_ARTION,
  auction: process.env.REACT_APP_AUCTION,
  sales: process.env.REACT_APP_MARKETPLACE,
  token: process.env.REACT_APP_REWARD_TOKEN,
  bundleSales: process.env.REACT_APP_FANTOM_BUNDLE_MARKETPLACE,
  factory: process.env.REACT_APP_FANTOM_NFT_FACTORY, //FantomNFTFactory
  artFactory: process.env.REACT_APP_FANTOM_ART_FACTORY, //FantomArtFactory
  //privateFactory: process.env., //FantomNFTFactoryPrivate
  // privateArtFactory: process.env., //FantomArtFactoryPrivate
};
