import React from 'react';

const IconReceivedOffers = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 12.5H16L14 15.5H10L8 12.5H2M22 12.5V18.5C22 19.0304 21.7893 19.5391 21.4142 19.9142C21.0391 20.2893 20.5304 20.5 20 20.5H4C3.46957 20.5 2.96086 20.2893 2.58579 19.9142C2.21071 19.5391 2 19.0304 2 18.5V12.5M22 12.5L18.55 5.61C18.3844 5.27679 18.1292 4.99637 17.813 4.80028C17.4967 4.60419 17.1321 4.5002 16.76 4.5H7.24C6.86792 4.5002 6.50326 4.60419 6.18704 4.80028C5.87083 4.99637 5.61558 5.27679 5.45 5.61L2 12.5"
      stroke="#101828"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconReceivedOffers;
