export const AuthConstants = {
  PROFILE_GET_START: 'PROFILE_GET_START',
  PROFILE_GET_SUCCESS: 'PROFILE_GET_SUCCESS',
  PROFILE_GET_FAILED: 'PROFILE_GET_FAILED',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  UPDATE_BANNER: 'UPDATE_BANNER',
  SET_2FA: 'SET_2FA',
};

export const API_URL = 'https://api.sumsub.com';

export const FLOW_NAME = 'basic-kyc';

export const DAY_IN_MS = 86400000;

export const GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US';

export const APP_STORE =
  'https://apps.apple.com/us/app/google-authenticator/id388497605';
