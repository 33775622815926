import React, { useState } from 'react';
import Input from 'components/Input';
import styles from 'components/Input/styles.module.scss';

import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
function PasswordInput({ ...props }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
          >
            {showPassword ? (
              <VisibilityOff className={styles.icons} />
            ) : (
              <Visibility className={styles.icons} />
            )}
          </IconButton>
        </InputAdornment>
      }
      {...props}
    />
  );
}

export default PasswordInput;
