import React, { useEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import AddComment from '../AddComment';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import CommentItem from '../CommentItem';
import { useApi } from 'api';

import { totalCommentsCount } from '../../utils';
import { computed } from '@preact/signals-react';

const reactions = computed(() =>
  totalCommentsCount.value > 0 ? (
    <span className={styles.reactions}>
      {totalCommentsCount.value === 1 ? (
        <span className={styles.count}>1 reaction</span>
      ) : (
        <span className={styles.count}>
          {totalCommentsCount.value} reactions
        </span>
      )}
    </span>
  ) : (
    <span className={styles.reactions}>(no reactions yet)</span>
  )
);

const Comments = ({
  user: me,
  nft,
  isMobile,
  firstCommentsLoad,
  setFirstCommentsLoad,
}) => {
  const { authToken } = useSelector(state => state.Auth);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const commentsSectionRef = useRef(null);
  const observerRef = useRef(null);
  const fromIndex = useRef(0);

  const { getNftComments } = useApi();

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !loading) {
          setPage(prev => prev + 1);
        }
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [loading]);

  const scrollToCommentsWithMargin = (element, margin) => {
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - margin;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const fetchComments = async () => {
    if (fromIndex.current < 0) {
      return;
    }

    setLoading(true);
    const { data } = await getNftComments(
      {
        nftId: nft.nftId,
        parentId: null,
        from: fromIndex.current,
      },
      authToken
    );
    setComments(prev => {
      // Filter out duplicate comments
      const uniqueNewComments = data.comments.filter(
        newComment =>
          !prev.some(comment => comment.commentId === newComment.commentId)
      );
      return [...prev, ...uniqueNewComments];
    });
    setLoading(false);

    if (!firstCommentsLoad && fromIndex.current === 0) {
      if (isMobile) {
        scrollToCommentsWithMargin(commentsSectionRef.current, 130);
      } else {
        if (commentsSectionRef.current) {
          commentsSectionRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    }

    if (data.total > fromIndex.current + 10) {
      fromIndex.current += 10;
    } else {
      fromIndex.current = -1;
    }

    // set comments loaded once
    setFirstCommentsLoad(false);
  };

  useEffect(() => {
    fetchComments();
  }, [page]);

  const onNewCommentAdded = comment => {
    setComments(prev => [comment, ...prev]);
    // increase reply count
    totalCommentsCount.value = totalCommentsCount.value + 1;
  };

  const onCommentDeleted = commentId => {
    setComments(prev => prev.filter(a => a.commentId !== commentId));
  };

  const onCommentEdited = editedComment => {
    setComments(prev =>
      prev.map(comment =>
        comment.commentId === editedComment.commentId
          ? { ...comment, comment: editedComment.comment }
          : comment
      )
    );
  };

  return (
    <Stack className={styles.root}>
      <Box className={styles.title} ref={commentsSectionRef}>
        Comments {reactions}
      </Box>

      <Box className={styles.body}>
        {authToken && (
          <AddComment
            nft={nft}
            user={me}
            authToken={authToken}
            className={styles.mainComment}
            onCommentSaved={onNewCommentAdded}
            isMobile={isMobile}
          />
        )}

        {comments.map(comment => (
          <CommentItem
            key={comment.commentId}
            authToken={authToken}
            comment={comment}
            user={me}
            nft={nft}
            onCommentDeleted={onCommentDeleted}
            onCommentEdited={onCommentEdited}
            isMobile={isMobile}
          />
        ))}
      </Box>

      <div ref={observerRef}></div>
    </Stack>
  );
};

export default Comments;
