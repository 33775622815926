import React, { useState, useEffect } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNFTContract } from 'contracts';
import { useApi } from 'api';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { StyledAccordion } from 'components/StyledComponents';
import styles from '../styles.module.scss';
import Input from 'components/Input';
import BootstrapTooltip from 'components/BootstrapTooltip';
import InputMedia from 'components/InputMedia';
import { ASSET_TYPES } from 'constants/asset.constants';
import { isEthereumAddress } from 'utils';

const TokenInfo = ({
  asset,
  setAsset,
  tokenDetails,
  updateTokenDetails,
  validateError,
  setValidateError,
  errors,
}) => {
  const { getERC20Contract } = useNFTContract();
  const { deleteFile, payTokenExists, setAssetUploaded } = useApi();
  // pending or failed assets
  const { checkedAssets } = useSelector(state => state.Notifications);
  const { authToken } = useSelector(state => state.Auth);
  const [expanded, setExpanded] = useState(true);
  const [loading, setLoading] = useState(false);
  const [assetsToCheck, setAssetsToCheck] = useState([]);
  const [photoProgress, setPhotoProgress] = useState({});
  const [enteredAddress] = useDebounce(tokenDetails['address'], 500);

  const updateMultipleTokenDetails = details => {
    Object.entries(details).forEach(([key, value]) => {
      updateTokenDetails(key, value);
    });
  };

  useEffect(() => {
    if (!asset?.status) {
      setAssetsToCheck([{ ...asset, uploaded: true, status: 'pending' }]);
    } else {
      setAssetsToCheck([{ ...asset }]);
    }
  }, [asset?.assetId, asset?.status]);

  useEffect(() => {
    const checkTokenExists = async () => {
      try {
        const { data: exists } = await payTokenExists(
          authToken,
          enteredAddress
        );

        if (exists) {
          setValidateError(prev => ({
            ...prev,
            address: 'Token address exists',
          }));
          return true;
        }
        return false; // OK
      } catch (error) {
        setValidateError(prev => ({
          ...prev,
          address: 'Invalid token address',
        }));
        return true;
      }
    };

    const fetchTokenInfo = async () => {
      if (!enteredAddress || !isEthereumAddress(enteredAddress)) {
        return;
      }

      const exists = await checkTokenExists();
      if (exists) {
        return;
      }

      const erc20 = await getERC20Contract(enteredAddress);
      if (!erc20) {
        return;
      }

      await updateTokenInfo(erc20);
    };

    const updateTokenInfo = async erc20 => {
      try {
        setLoading(true);
        const [name, symbol, decimals] = await Promise.all([
          erc20.name().catch(() => 'Unknown'),
          erc20.symbol().catch(() => 'Unknown'),
          erc20.decimals().catch(() => 'Unknown'),
        ]);

        updateMultipleTokenDetails({
          name: name,
          symbol: symbol,
          decimals: decimals,
        });

        setValidateError(prev => ({
          ...prev,
          address: undefined,
          name: name === 'Unknown',
          symbol: symbol === 'Unknown',
          decimals: decimals === 'Unknown',
        }));
      } catch (error) {
        console.log('Error in fetching token info');
      } finally {
        setLoading(false);
      }
    };

    if (isEthereumAddress(enteredAddress)) {
      fetchTokenInfo();
    } else {
      updateMultipleTokenDetails({
        name: '',
        symbol: '',
        decimals: '',
      });
      setValidateError(prev => ({
        ...prev,
        address: undefined,
        name: undefined,
        symbol: undefined,
        decimals: undefined,
      }));
    }
  }, [authToken, enteredAddress]);

  const removeAssetPhoto = async id => {
    setAsset([]);
    updateMultipleTokenDetails({
      icon: '',
    });
    await deleteFile(authToken, id);
  };

  const handleUploadedAsset = async asset => {
    await setAssetUploaded(authToken, asset.assetId);
    setAsset(asset);
    updateMultipleTokenDetails({
      icon: asset.fullUrl,
      uploaded: true,
    });
  };

  const mergeAssets = (prevAssets, newAssets) => {
    const assetsMap = new Map();

    prevAssets.forEach(asset => {
      assetsMap.set(asset.assetId, asset);
    });

    newAssets.forEach(asset => {
      assetsMap.set(asset.assetId, asset);
    });

    return [...assetsMap.values()];
  };

  const photoBars = Object.entries(photoProgress).map(el => {
    return (
      !!el[1] && (
        <LinearProgress variant="determinate" key={el[0]} value={el[1]} />
      )
    );
  });
  const waitingUploadPhotos = Object.keys(photoProgress).length !== 0;
  const data = asset?.assetId ? [asset] : [];

  return (
    <div className={styles.panelInputs}>
      <StyledAccordion
        border="1px var(--border-color) solid !important"
        titlesize="24px !important"
        sx={{ overflow: 'hidden' }}
        expanded={expanded}
        onChange={() => setExpanded(prev => !prev)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          className={styles.inputGroup}
          id="panel1-header"
          style={{
            boxShadow: 'none',
          }}
        >
          Token Details
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.formGroup}>
            <Input
              hasError={validateError && validateError.address}
              placeholder="0x1234..."
              value={tokenDetails['address']}
              onChange={e => {
                updateTokenDetails('address', e.target.value);
              }}
              label="Token Contract Address*"
              disabled={loading}
            />
            {validateError.address && (
              <Box className={styles.error}>{validateError.address}</Box>
            )}
            <Input
              hasError={validateError && validateError.name}
              className={styles.readOnly}
              placeholder=""
              value={tokenDetails['name']}
              label="Token Name*"
              readOnly
            />
            <Input
              hasError={validateError && validateError.symbol}
              className={styles.readOnly}
              placeholder=""
              value={tokenDetails['symbol']}
              label="Token Symbol*"
              readOnly
            />
            <Input
              hasError={validateError && validateError.decimals}
              className={styles.readOnly}
              placeholder=""
              value={tokenDetails['decimals']}
              label="Token Decimals*"
              readOnly
            />
            <div className={styles.uploadContainer}>
              <div className={styles.uploadTitle}>
                Token Logo&nbsp;
                <BootstrapTooltip
                  title="Drag or choose your logo to upload"
                  placement="top"
                >
                  <HelpOutlineIcon />
                </BootstrapTooltip>
              </div>
              <InputMedia
                key="icon"
                assetType={ASSET_TYPES.ICON}
                changeFunction={handleUploadedAsset}
                onlyImages
                acceptedImages={['image/x-icon', 'image/png']}
                multiple={false}
                hasError={errors?.length}
                data={data}
                assetsToCheck={mergeAssets(assetsToCheck, checkedAssets)}
                setProgressUpload={setPhotoProgress}
                waitingUpload={waitingUploadPhotos}
                removeCb={removeAssetPhoto}
                disabled={false}
              />
              <div className={styles.fullBarLoader}>{photoBars}</div>
            </div>
          </div>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
};

export default TokenInfo;
