import React from 'react';

const IconCopy = props => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.41992 15.0107H4.41992C3.88949 15.0107 3.38078 14.8 3.00571 14.425C2.63064 14.0499 2.41992 13.5412 2.41992 13.0107V4.01074C2.41992 3.48031 2.63064 2.9716 3.00571 2.59653C3.38078 2.22146 3.88949 2.01074 4.41992 2.01074H13.4199C13.9504 2.01074 14.4591 2.22146 14.8341 2.59653C15.2092 2.9716 15.4199 3.48031 15.4199 4.01074V5.01074M11.4199 9.01074H20.4199C21.5245 9.01074 22.4199 9.90617 22.4199 11.0107V20.0107C22.4199 21.1153 21.5245 22.0107 20.4199 22.0107H11.4199C10.3154 22.0107 9.41992 21.1153 9.41992 20.0107V11.0107C9.41992 9.90617 10.3154 9.01074 11.4199 9.01074Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCopy;
