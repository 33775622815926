import React from 'react';
import Modal from '../Modal';
import styles from './styles.module.scss';
import { shortenAddress } from 'utils';
import { StyledButton } from 'components/StyledComponents';
import { useDisconnect } from '@reown/appkit/react';

export const WrongWalletModal = ({ visible, onClose, userWalletAddress }) => {
  const { disconnect } = useDisconnect();

  const handleDisconnect = () => {
    disconnect();
    onClose();
  };
  return (
    <Modal
      visible={visible}
      onClose={() => {}}
      submitDisabled={true}
      defaultPadding={false}
      small
      showCloseIcon={false}
    >
      <div className={styles.container}>
        <div className={styles.subtitle}>
          Oops, it looks like you connect an unverified wallet, please connect a
          verified wallet
          {userWalletAddress ? (
            <span className={styles.walletAddress}>
              &nbsp;({shortenAddress(userWalletAddress)})
            </span>
          ) : null}
          .
        </div>
        <div className={styles.btnGroup}>
          <StyledButton onClick={handleDisconnect} cancel="true">
            Disconnect Wallet
          </StyledButton>
        </div>
      </div>
    </Modal>
  );
};
