export const contractTypeIds = {
  EXCLUSIVE: 1,
  NONEXCLUSIVE: 2,
};
export const contractTypes = [
  {
    id: 1,
    label: 'Exclusive Contract',
    url: '/files/exclusive contract.pdf',
  },
  {
    id: 2,
    label: 'Non-Exclusive Contract',
    url: '/files/non-exclusive contract.pdf',
  },
  // {
  //   id: '3',
  //   label: 'AI generated. Public domain',
  //   url: '/files/non-exclusive contract.pdf',
  // },
  // {
  //   id: '4',
  //   label: 'AI generated. Derivative, copyright',
  //   url: '/files/non-exclusive contract.pdf',
  // },
];

export const getContractTypeById = tokenID => {
  for (let i = 0; i < contractTypes.length; i++) {
    const contractType = contractTypes[i];
    if (tokenID === contractType.id) {
      return contractType;
    }
  }
};
