import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function CreateRoute({ component: Component, ...restOfProps }) {
  const { authToken, user } = useSelector(state => state.Auth);
  const location = useLocation();
  return (
    <Route
      {...restOfProps}
      render={props =>
        authToken ? (
          user.is2fa ? (
            user.userStatus.name !== 'blacklist' ? (
              <Component {...props} />
            ) : (
              <Redirect to={`/`} />
            )
          ) : (
            <Redirect to={'/profile'} />
          )
        ) : (
          <Redirect to={`/auth/sign-in?redirect_to=${location.pathname}`} />
        )
      }
    />
  );
}

export default CreateRoute;
