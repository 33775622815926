import React from 'react';

const iconProhibited = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="red"
    >
      <circle cx="12" cy="12" r="10" stroke="red" strokeWidth="2" fill="none" />
      <line x1="5" y1="5" x2="19" y2="19" stroke="red" strokeWidth="2" />
    </svg>
  );
};

export default iconProhibited;
