import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Chip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FilterActions from 'actions/filter.actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cx from 'classnames';

import './styles.module.scss';
import IconLicence from 'assets/icons/iconLicence';

const LicenseFilter = ({ contractTypes }) => {
  const dispatch = useDispatch();

  const { license } = useSelector(state => state.Filter);

  const handleSelectLicense = newLicense => {
    dispatch(
      FilterActions.updateLicenseFilter(
        license === newLicense ? null : newLicense
      )
    );
  };

  return (
    <Box
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'var(--place-holder-color)',
          color: 'var(--primary-text-color)',
          '& svg': {
            fill: 'var(--primary-text-color)',
          },
        },
        '& .MuiAccordionSummary-content': {
          display: 'flex',
          alignItems: 'center',
          gap: '2px',

          '& svg': {
            scale: '0.7',
            stroke: 'var(--primary-text-color)',
          },
          '& .MuiTypography-root': {
            fontWeight: 500,
            fontSize: 15,
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        '& .MuiAccordionDetails-root': {
          m: 0,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          '& .MuiButtonBase-root': {
            height: 25,
            fontWeight: 500,
          },
        },
      }}
    >
      <Accordion
        sx={{
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <IconLicence />
          <Typography>Licenses</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '.MuiChip-root': {
              bgcolor: 'var(--secondary-background-color)',
              color: 'var(--primary-text-color)',
            },
            '& .MuiChip-root.selected': {
              bgcolor: 'var(--primary-color)',
              '&:hover': {
                bgcolor: 'var(--darker-primary-color)',
              },
            },
          }}
        >
          {contractTypes.map(contract => (
            <Chip
              key={contract.contractTypeId}
              label={contract.name}
              onClick={() => handleSelectLicense(contract.contractTypeId)}
              className={cx({
                selected: license === contract.contractTypeId,
              })}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default LicenseFilter;
