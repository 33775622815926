import React from 'react';

const IconComment = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_405_4250)">
        <path
          d="M14.4525 5.16084C15.6343 5.16084 16.5924 4.20278 16.5924 3.02097C16.5924 1.83915 15.6343 0.881104 14.4525 0.881104C13.2707 0.881104 12.3126 1.83915 12.3126 3.02097C12.3126 4.20278 13.2707 5.16084 14.4525 5.16084Z"
          fill="#D9D9D9"
        />
        <path
          d="M5.89359 6.58735C5.50128 6.58735 5.1803 6.26637 5.1803 5.87406C5.1803 5.48175 5.50128 5.16077 5.89359 5.16077H11.6213C11.3124 4.76079 11.0938 4.29865 10.9805 3.80612C10.8672 3.31359 10.8619 2.80237 10.9651 2.30762H3.76086C3.38251 2.30762 3.01965 2.45792 2.75212 2.72545C2.48458 2.99299 2.33428 3.35584 2.33428 3.73419L2.32715 14.8472C2.32715 15.4821 3.0975 15.803 3.54687 15.3537L5.1803 13.7202H15.1663C15.951 13.7202 16.5929 13.0783 16.5929 12.2937V5.85266C15.9938 6.30917 15.2591 6.58735 14.4531 6.58735H5.89359ZM10.1733 10.8671H5.89359C5.50128 10.8671 5.1803 10.5461 5.1803 10.1538C5.1803 9.76148 5.50128 9.4405 5.89359 9.4405H10.1733C10.5656 9.4405 10.8866 9.76148 10.8866 10.1538C10.8866 10.5461 10.5656 10.8671 10.1733 10.8671ZM13.0265 8.72721H5.89359C5.50128 8.72721 5.1803 8.40623 5.1803 8.01393C5.1803 7.62162 5.50128 7.30064 5.89359 7.30064H13.0265C13.4188 7.30064 13.7398 7.62162 13.7398 8.01393C13.7398 8.40623 13.4188 8.72721 13.0265 8.72721Z"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_405_4250">
          <rect
            width="17.1189"
            height="17.1189"
            fill="white"
            transform="translate(0.899536 0.881104)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconComment;
