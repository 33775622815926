/* eslint-disable no-unused-vars */
import { useApi } from 'api';
import Modal from 'components/Modal';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import cx from 'classnames';
import toast from 'utils/toast';
import { formatError, launchWebSdk } from 'utils';
import { ClipLoader } from 'react-spinners';
import { ethers } from 'ethers';
import { StyledButton } from 'components/StyledComponents';

const KYCModal = ({ visible, onClose }) => {
  const [payingFee, setPayingFee] = useState(false);
  const [kycFee, setKycFee] = useState(false);
  const [txHash, setTxHash] = useState('');
  const {
    addKYCPaymentReceipt,
    getKYCFee,
    getKYCTxHash,
    getSumsubToken,
  } = useApi();
  const [loading, setLoading] = useState(false);
  const { authToken, user } = useSelector(state => state.Auth);
  const userWallet = user?.userWallets?.at(0);

  useEffect(() => {
    if (visible) {
      (async () => {
        setLoading(true);
        const { data: kycResponse } = await getKYCFee(authToken);
        if (JSON.stringify(kycResponse) !== '{}') {
          setKycFee(kycResponse);
        }
        const { data: kycTxHash } = await getKYCTxHash(authToken);
        if (JSON.stringify(kycTxHash) !== '{}') {
          setTxHash(kycTxHash);
        }
        setLoading(false);
      })();
    }
  }, [visible, authToken]);

  const onSumSubMessage = (type, payload) => {
    if (payload?.reviewStatus === 'completed') {
      toast('success', 'The KYC authentication request was sent');
      onClose();
    }
  };

  useEffect(() => {
    if (txHash) {
      (async () => {
        const {
          data: { message, data },
        } = await getSumsubToken(authToken, txHash);
        if (message === 'OK' && data) {
          launchWebSdk(data, onSumSubMessage);
        }
      })();
    }
  }, [visible, txHash]);

  const payKYCFee = async () => {
    try {
      setPayingFee(true);

      // const tx = await connector.provider.request({
      //   method: 'eth_sendTransaction',
      //   params: [
      //     {
      //       from: userWallet.address,
      //       to: process.env.REACT_APP_OWNER_ADDRESS,
      //       value: ethers.parseEther(kycFee).toHexString(),
      //     },
      //   ],
      // });

      // setTxHash(tx);

      // await addKYCPaymentReceipt(tx, authToken);
    } catch (error) {
      toast('error', formatError(error));
    }
    setPayingFee(false);
  };
  return (
    <Modal
      visible={visible}
      title={'KYC Process'}
      onClose={() => {
        if (payingFee) {
          return;
        }
        onClose();
      }}
      submitDisabled={false}
    >
      {loading ? (
        <ClipLoader color="#FFF" size={25} />
      ) : txHash ? (
        <div id="sumsub-websdk-container" className={styles.sumsubContainer} />
      ) : (
        <>
          <div className={styles.title}>We require KYC to continue trading</div>
          <div className={styles.title}>
            Please pay fee and start a KYC applicant
          </div>
          <div className={styles.subTitle}>
            * By clicking Accept you agree to cover the gas fee associated with
            this transaction
          </div>
          <div className={styles.footer}>
            <StyledButton
              target="_blank"
              rel="noreferrer"
              className={cx(styles.button, styles.save)}
              onClick={() => onClose()}
              cancel="true"
              disabled={payingFee}
            >
              Cancel
            </StyledButton>
            <StyledButton
              className={cx(styles.button, styles.cancel)}
              onClick={async () => {
                await payKYCFee();
              }}
              disabled={payingFee}
            >
              {payingFee ? `Paying Fee` : `Pay ${kycFee} AVAX Fee`}
            </StyledButton>
          </div>
        </>
      )}
    </Modal>
  );
};

export default KYCModal;
