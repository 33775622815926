import React from 'react';

const IconWallet = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.6667 13.3333H3.33333C2.97971 13.3333 2.64057 13.1928 2.39052 12.9428C2.14048 12.6927 2 12.3536 2 12V5.99996C2 5.64634 2.14048 5.3072 2.39052 5.05715C2.64057 4.8071 2.97971 4.66663 3.33333 4.66663H12.6667C13.0203 4.66663 13.3594 4.8071 13.6095 5.05715C13.8595 5.3072 14 5.64634 14 5.99996V12C14 12.3536 13.8595 12.6927 13.6095 12.9428C13.3594 13.1928 13.0203 13.3333 12.6667 13.3333Z" />
    <path
      d="M11.0003 9.33329C10.9119 9.33329 10.8271 9.29817 10.7646 9.23566C10.7021 9.17315 10.667 9.08836 10.667 8.99996C10.667 8.91155 10.7021 8.82677 10.7646 8.76426C10.8271 8.70174 10.9119 8.66663 11.0003 8.66663C11.0887 8.66663 11.1735 8.70174 11.236 8.76426C11.2985 8.82677 11.3337 8.91155 11.3337 8.99996C11.3337 9.08836 11.2985 9.17315 11.236 9.23566C11.1735 9.29817 11.0887 9.33329 11.0003 9.33329Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 4.66664V3.73531C11.9999 3.53097 11.9529 3.32939 11.8626 3.14612C11.7722 2.96285 11.641 2.80279 11.4789 2.67831C11.3169 2.55384 11.1284 2.46827 10.928 2.42821C10.7277 2.38816 10.5208 2.39469 10.3233 2.44731L2.99 4.40264C2.70604 4.47831 2.45503 4.64568 2.27599 4.87872C2.09696 5.11177 1.99993 5.39743 2 5.69131V5.99997" />
  </svg>
);

export default IconWallet;
