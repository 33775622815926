import { ASSET_TYPES } from 'constants/asset.constants';
import { getSourceFileName } from 'utils';

export const assetTransform = gaiaAssetList => {
  return gaiaAssetList
    ? gaiaAssetList.map(el => {
        const type = el?.assetType ? el.assetType.name : el?.type;
        return {
          assetId: el?.asset_id || el?.assetId,
          parentId: el?.parentId,
          relatedAssets: el?.relatedAssets || [],
          url:
            type === ASSET_TYPES.FRONT_COVER
              ? getSourceFileName(el?.url)
              : el?.name || el?.url,
          fullUrl: el?.fullUrl || el?.url,
          status: el?.status || '',
          failed: el?.failed,
          errors: el?.errors,
          uploaded: el?.uploaded,
          nsfw: el?.nsfw,
          likely: el?.likely,
          type,
        };
      })
    : [];
};
