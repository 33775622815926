import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './styles.css';
import { ClipLoader } from 'react-spinners';
import Skeleton from 'react-loading-skeleton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import BootstrapTooltip from 'components/BootstrapTooltip';
import PriceInput from 'components/PriceInput';
import { formatNumber } from 'utils';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import InputError from '../InputError';

const AuctionModal = ({
  visible,
  onClose,
  onStartAuction,
  auction,
  auctionStarted,
  confirming,
  approveContract,
  contractApproving,
  contractApproved,
  token,
}) => {
  const [now, setNow] = useState(new Date());
  const [reservePrice, setReservePrice] = useState('');
  const [startTime, setStartTime] = useState(
    new Date(new Date().getTime() + 2 * 60 * 1000)
  );
  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );
  const [focused, setFocused] = useState(false);
  const [selected, setSelected] = useState([]);
  const [inputError, setInputError] = useState(null);

  useEffect(() => {
    const id = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    setReservePrice(auction?.reservePrice || '');
    setStartTime(
      auction?.startTime
        ? new Date(auction.startTime * 1000)
        : new Date(new Date().getTime() + 2 * 60 * 1000)
    );
    setEndTime(
      auction?.endTime
        ? new Date(auction.endTime * 1000)
        : new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    );
  }, [visible, auction]);

  useEffect(() => {
    if (visible) {
      setSelected([auction ? auction.token : token]);
    }
  }, [visible, auction]);

  const validateInput = (() => {
    if (reservePrice.length === 0 || parseFloat(reservePrice) == 0)
      return false;
    if (!auctionStarted && startTime.getTime() < now.getTime()) return false;
    return (
      endTime.getTime() >= now.getTime() + 1000 * 60 * 5 &&
      endTime.getTime() >= startTime.getTime() + 1000 * 60 * 5
    );
  })();

  return (
    <Modal
      visible={visible}
      title={auction ? 'Update Auction' : 'Start Auction'}
      onClose={onClose}
      showCloseIcon={false}
      submitDisabled={
        contractApproving ||
        confirming ||
        (contractApproved && !validateInput) ||
        inputError
      }
      submitLabel={
        contractApproved ? (
          confirming ? (
            <ClipLoader color="#FFF" size={16} />
          ) : auction ? (
            'Update Auction'
          ) : (
            'Start Auction'
          )
        ) : contractApproving ? (
          'Approving Your Asset'
        ) : (
          'Approve Your Asset'
        )
      }
      onSubmit={() =>
        contractApproved
          ? !confirming && validateInput
            ? onStartAuction(selected[0], reservePrice, startTime, endTime)
            : null
          : approveContract()
      }
    >
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>
          Reserve Price&nbsp;
          <BootstrapTooltip
            title="Reserve price is your desired one you want to get from this auction."
            placement="top"
          >
            <HelpOutlineIcon />
          </BootstrapTooltip>
        </div>
        <div className={cx(styles.formInputCont, focused && styles.focused)}>
          <div className={styles.select}>
            <div className={styles.selectedToken}>
              <img src={selected[0]?.icon} className={styles.tokenIcon} />
              <div className={styles.tokenSymbol}>{selected[0]?.symbol}</div>
            </div>
          </div>
          <PriceInput
            className={styles.formInput}
            placeholder="0.00"
            value={'' + reservePrice}
            decimals={selected[0]?.decimals || 0}
            onChange={setReservePrice}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={contractApproving || confirming}
            onInputError={err => setInputError(err)}
          />
          <div className={styles.usdPrice}>
            {!isNaN(selected[0]?.price) && selected[0]?.price !== null ? (
              `$${formatNumber(
                ((parseFloat(reservePrice) || 0) * selected[0].price).toFixed(2)
              )}`
            ) : (
              <Skeleton width={100} height={24} />
            )}
          </div>
        </div>
        <InputError text={inputError} />
      </div>
      <div className={styles.formGroupDates}>
        <div className={styles.formGroup}>
          <div className={styles.formLabel}>Start Time</div>
          <div className={styles.formInputCont}>
            <Datetime
              value={startTime}
              className={'calendarAboveInput'}
              onChange={val => setStartTime(val.toDate())}
              inputProps={{
                className: styles.formInput,
                onKeyDown: e => e.preventDefault(),
                disabled: auctionStarted || contractApproving || confirming,
              }}
              closeOnSelect
              isValidDate={cur =>
                cur.valueOf() > now.getTime() - 1000 * 60 * 60 * 24
              }
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formLabel}>Auction Expiration</div>
          <div className={styles.formInputCont}>
            <Datetime
              value={endTime}
              className={'calendarAboveInput'}
              onChange={val => setEndTime(val.toDate())}
              inputProps={{
                className: styles.formInput,
                onKeyDown: e => e.preventDefault(),
                disabled: contractApproving || confirming,
              }}
              closeOnSelect
              isValidDate={cur =>
                cur.valueOf() > startTime.getTime() - 1000 * 60 * 60 * 23
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AuctionModal;
