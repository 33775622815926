export const getFileType = url => {
  const fileTypes = {
    jpg: 'image',
    jpeg: 'image',
    png: 'image',
    svg: 'image',
    bmp: 'image',
    tiff: 'image',
    webp: 'image',
    gif: 'image',
    mov: 'video',
    mp4: 'video',
    avi: 'video',
    mkv: 'video',
    wmv: 'video',
    flv: 'video',
    webm: 'video',
    m4v: 'video',
    mp3: 'audio',
    wav: 'audio',
    flac: 'audio',
    ogg: 'audio',
    zip: 'document',
  };

  const fileType =
    fileTypes[
      url
        .split('.')
        .at(-1)
        .toLowerCase()
        .split('?')[0]
    ];
  return fileType;
};
