import { ethers } from 'ethers';
import { useDispatch } from 'react-redux';
import ModalActions from 'actions/modal.actions';

export const useCheckGasFee = () => {
  const dispatch = useDispatch();
  const checkGasFee = async (estimation, walletAddress) => {
    const provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_ENDPOINT
    );
    const gasPrice = (await provider.getFeeData()).gasPrice;

    const ETHbalance = await provider.getBalance(walletAddress);

    const totalGasFee = gasPrice * estimation;

    if (ETHbalance < totalGasFee) {
      const totalAmount = totalGasFee - ETHbalance;
      dispatch(ModalActions.showMoonpayModal(totalAmount));
      return false;
    }

    return true;
  };

  return { checkGasFee };
};
