import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Avatar, ListItem, Stack } from '@mui/material';
import Identicon from 'components/Identicon';

import styles from './styles.module.scss';

export default function AutocompleteToken({
  tokens,
  onSelect,
  value,
  displayText = 'Select Token',
  displayField = 'name',
  hideLogo = false,
  multiple = false,
  isError = false,
}) {
  const handleChange = (_, value) => {
    if (onSelect) {
      onSelect(value);
    }
  };

  const getDisplayValue = (option, field) => {
    if (!option || option.length === 0) {
      return '';
    }

    if (field.includes('.')) {
      const display = field.split('.').reduce((obj, key) => obj?.[key], option);
      return display;
    }

    return option[field];
  };
  return (
    <Autocomplete
      multiple={multiple}
      value={value}
      options={tokens}
      getOptionLabel={option => getDisplayValue(option, displayField)}
      renderOption={(props, option) => (
        <ListItem
          {...props}
          sx={{
            '&.Mui-focused': {
              backgroundColor: 'var(--secondary-background-color) !important',
              '&:hover': {
                backgroundColor: 'var(--background-color) !important',
              },
            },
            '&.MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: 'var(--secondary-background-color) !important',
              '&:hover': {
                backgroundColor: 'var(--background-color) !important',
              },
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {!hideLogo ? (
              option.icon ? (
                <Avatar
                  src={option.icon}
                  alt={getDisplayValue(option, displayField)}
                  sx={{ width: 24, height: 24 }}
                />
              ) : (
                <Identicon
                  className={styles.identicon}
                  account={option.address}
                  size={24}
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              )
            ) : (
              <></>
            )}
            <span>{getDisplayValue(option, displayField)}</span>
          </Stack>
        </ListItem>
      )}
      isOptionEqualToValue={(option, value) =>
        option?.payTokenId === value?.payTokenId
      }
      onChange={handleChange}
      renderInput={params => {
        return (
          <TextField
            {...params}
            placeholder={(!value || value.length === 0) && displayText}
            variant="outlined"
            sx={{
              '.Mui-focused': {
                color: 'var(--primary-color) !important',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--primary-color) !important',
                },
              },
              '.MuiFormLabel-root': {
                color: 'var(--primary-text-color)',
              },
              '.MuiChip-root': {
                backgroundColor: 'var(--primary-color)',
                color: 'var(--white-color)',
              },
              '.MuiAutocomplete-inputRoot': {
                width: '100%',
                minHeight: '50px',
                boxSizing: 'border-box',
                fontSize: '16px',
                color: 'var(--primary-text-color)',
                borderRadius: '10px !important',
                outline: 'none',
                backgroundColor: 'var(--place-holder-color)',
                border: '1px var(--light-border-color) solid',
                ...(isError && {
                  boxShadow: ' 0 0 10px 1px rgba(236, 28, 28, 0.76)',
                }),
              },
              'MuiAutocomplete-popupIndicator': {
                svg: {
                  color: 'var(--primary-text-color)',
                },
              },
            }}
          />
        );
      }}
      sx={{
        width: '100%',
        '.MuiAutocomplete-endAdornment': {
          svg: {
            color: 'var(--primary-text-color)',
          },
        },
      }}
    />
  );
}
