import React from 'react';
import { Box } from '@mui/material';
import styles from '../styles.module.scss';

export const CommunityInfo = ({ selectedToken: token }) => {
  if (!token.community) return null;

  const {
    name,
    description,
    website,
    twitter,
    discord,
    instagram,
    tiktok,
    telegram,
  } = token.community;

  return (
    <Box
      className={styles.titleContainer}
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start !important',
      }}
    >
      <Box>Community:</Box>
      <Box className={styles.nameContainer}>{name ?? '-'}</Box>
      <Box className={styles.titleContainer}>{description}</Box>
      {website && (
        <Box className={styles.communityItem}>
          <Box>Website:</Box>
          <a
            href={`${website}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {website}
          </a>
        </Box>
      )}
      {twitter && (
        <Box>
          <Box>X:</Box>
          <a
            href={`${twitter}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {twitter}
          </a>
        </Box>
      )}
      {discord && (
        <Box>
          <Box>Discord:</Box>
          <a
            href={`${discord}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {discord}
          </a>
        </Box>
      )}
      {instagram && (
        <Box>
          <Box>Instagram:</Box>
          <a
            href={`${instagram}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {instagram}
          </a>
        </Box>
      )}
      {tiktok && (
        <Box>
          <Box>TikTok:</Box>
          <a
            href={`${tiktok}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {tiktok}
          </a>
        </Box>
      )}
      {telegram && (
        <Box>
          <Box>Telegram:</Box>
          <a
            href={`${telegram}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {telegram}
          </a>
        </Box>
      )}
    </Box>
  );
};
