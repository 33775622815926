import React from 'react';

const IconVerified = props => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.7852 21.6523L36.5152 16.7698L37.1102 10.3123L30.7927 8.87734L27.4852 3.27734L21.5352 5.83234L15.5852 3.27734L12.2777 8.85984L5.96016 10.2773L6.55516 16.7523L2.28516 21.6523L6.55516 26.5348L5.96016 33.0098L12.2777 34.4448L15.5852 40.0273L21.5352 37.4548L27.4852 40.0098L30.7927 34.4273L37.1102 32.9923L36.5152 26.5348L40.7852 21.6523ZM18.1927 29.9123L11.5427 23.2448L14.1327 20.6548L18.1927 24.7323L28.4302 14.4598L31.0202 17.0498L18.1927 29.9123Z"
      fill="#67ABF5"
    />
  </svg>
);

export default IconVerified;
