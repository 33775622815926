import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AccountModal from './AccountModal';
import NotFound from './NotFound';
import NFTProcessing from './NFTProcessing';

import TokenPage from '../pages/Token/Add';
import PaintBoard from '../pages/Create';
import LandingPage from '../pages/landingpage';
import ExplorePage from '../pages/explorepage';
import PendingNFT from '../pages/NFTDetails/PendingNFT';
import AccountDetails from '../pages/AccountDetails';
import NFTItem from '../pages/NFTDetails/NFT';
import SignIn from '../pages/AuthFS/SignIn';
import SignUp from '../pages/AuthFS/SignUp';
import UnsubscribeEmail from '../pages/AuthFS/UnsubscribeEmail';
import ResetPassword from '../pages/AuthFS/ResetPassword';
import NewPassword from '../pages/AuthFS/NewPassword';
import ConfirmAccount from '../pages/AuthFS/ConfirmAccount';
import NeedToBeLoggedRoute from './NeedToBeLoggedRoute';
import CreateRoute from './CreateRoute';
import ProfileRouter from './ProfileRouter';
import { useApi } from '../api';
import { WrongWalletModal } from './WrongWalletModal';
import Licenses from '../pages/ExternalDocuments/Licenses';
import PrivacyPolicy from '../pages/ExternalDocuments/PrivacyPolicy';
import ExclusiveContract from '../pages/ExternalDocuments/ExclusiveContract';
import NonexclusiveContract from '../pages/ExternalDocuments/NonexclusiveContract';
import FAQS from '../pages/ExternalDocuments/FAQs';
import TermsOfUse from '../pages/ExternalDocuments/TermsOfUse';
import ModalActions from '../actions/modal.actions';
import { getWsConnection } from 'utils';
import io from 'socket.io-client';
import NotiActions from 'actions/noti.actions';
import MoonPayModal from './MoonPayModal';
import { useMediaQuery } from '@mui/material';
import MobileMessage from 'pages/NotFound';
import { useAppKitAccount, useAppKitTheme } from '@reown/appkit/react';

const App = () => {
  const dispatch = useDispatch();
  const { authToken, user } = useSelector(state => state.Auth);
  const isMobile = useMediaQuery('(max-width: 900px)');
  const { address: account } = useAppKitAccount();

  const { setThemeMode } = useAppKitTheme();

  const { mode } = useSelector(state => state.HeaderOptions);

  const { findAllUserNotifications } = useApi();

  const [wrongWalletModalVisible, setWrongWalletModalVisible] = useState(false);

  const [userWalletAddress, setUserWalletAddress] = useState(null);
  const { moonpayModalVisible, moonpayAmount } = useSelector(
    state => state.Modal
  );

  const fetchAllUserNotifications = async authToken => {
    if (!authToken) return;

    const { data } = await findAllUserNotifications(authToken, {
      from: 0,
      count: 5,
    });

    if (data) {
      dispatch(NotiActions.fetchNotifications(data));
    }
  };

  useEffect(() => {
    const body = document.querySelector('body');
    body.setAttribute('data-theme', mode);
    setThemeMode(mode);
  }, [mode]);

  useEffect(() => {
    let socket;

    if (authToken) {
      // (async () => {
      //   const savedItem = window.localStorage.getItem('nftItem');
      //   if (!savedItem) {
      //     await removeUnusedFiles(authToken);
      //   }
      // })();

      fetchAllUserNotifications(authToken);

      // Establish the WebSocket connection
      socket = io(getWsConnection(), {
        query: { authToken: authToken ?? '' },
        transports: ['websocket'],
      });

      // Handle connection event
      socket.on('connect', () => {
        console.log('Connected to WebSocket server');
      });

      // Handle disconnection event
      socket.on('disconnect', () => {
        console.log('Disconnected from WebSocket server');
      });

      // Handle incoming notifications
      socket.on('notification', notification => {
        const { addNewNotification } = NotiActions;
        dispatch(addNewNotification({ notification }));
      });

      // Handle assets notification
      socket.on('asset-checked', obj => {
        const { addCheckedAsset } = NotiActions;
        dispatch(addCheckedAsset(obj));
      });
    }

    return () => {
      socket?.disconnect();
    };
  }, [authToken]);

  useEffect(() => {
    if (account && authToken) {
      const walletAddress = user?.address;

      if (
        walletAddress &&
        walletAddress.toLowerCase() !== account.toLowerCase()
      ) {
        setWrongWalletModalVisible(true);
        setUserWalletAddress(walletAddress);
      } else {
        setWrongWalletModalVisible(false);
        setUserWalletAddress(null);
      }
    }
  }, [account, authToken, user?.address]);

  return (
    <div data-theme={mode}>
      <Router>
        {isMobile && process.env.REACT_APP_ENV === 'staging' ? (
          <Route path="*" component={MobileMessage} />
        ) : (
          <>
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/explore" component={ExplorePage} />
              <Route path="/search/:key" component={ExplorePage} />
              <Route path="/explore/:id/:action?" component={NFTItem} />

              <Route path="/licenses" component={Licenses} />
              <Route path="/auth/sign-in" component={SignIn} />
              <Route path="/auth/sign-up" component={SignUp} />
              <Route path="/terms-of-use" component={TermsOfUse} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="/exclusive-contract" component={ExclusiveContract} />
              <Route path="/faqs" component={FAQS} />

              <Route
                path="/non-exclusive-contract"
                component={NonexclusiveContract}
              />

              <Route
                path="/auth/confirm-account/:token"
                component={ConfirmAccount}
              />
              <Route
                exact
                path="/auth/reset-password"
                component={ResetPassword}
              />
              <Route exact path="/unsubscribe" component={UnsubscribeEmail} />
              <Route
                path="/auth/reset-password/:token"
                component={NewPassword}
              />

              <Route path="/account/:name/:token?" component={AccountDetails} />

              <NeedToBeLoggedRoute path="/pending/:id" component={PendingNFT} />
              <CreateRoute path="/create" key="create" component={PaintBoard} />
              <CreateRoute
                path="/draft/:createdNftId"
                key="draft"
                component={PaintBoard}
              />
              <CreateRoute
                path="/token/add"
                key="token/add"
                component={TokenPage}
              />

              <ProfileRouter
                path="/profile/:tabId"
                component={AccountDetails}
              />
              <ProfileRouter path="/profile" component={AccountDetails} />
              <Route path="/404" component={NotFound} />
              <Route path="/asset-processing" component={NFTProcessing} />
              <Route path="*">
                <Redirect to="/404" />
              </Route>
            </Switch>
            <AccountModal />
            <MoonPayModal
              args={{ symbol: 'eth', price: moonpayAmount }}
              visible={moonpayModalVisible}
              onClose={() => {
                dispatch(ModalActions.hideMoonpayModal());
              }}
            />
            <WrongWalletModal
              visible={wrongWalletModalVisible}
              onClose={() => setWrongWalletModalVisible(false)}
              userWalletAddress={userWalletAddress}
            />
            <ToastContainer
              position="top-right"
              closeOnClick
              pauseOnFocusLoss
              pauseOnHover
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              theme={mode}
            />
          </>
        )}
      </Router>
    </div>
  );
};

export default App;
