import React, { useState, useEffect } from 'react';
import LicenseGrid from '../ExternalDocumentsGrid';
import globalStyles from './../styles.module.scss';
import Header from 'components/header';

import {
  Tabs,
  Tab,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material';
import styles from './styles.module.scss';
import { StyledAccordion } from 'components/StyledComponents';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useApi } from 'api';
import { replaceLinksWithAnchors } from 'utils';
const CustomTabs = styled(Tabs)({
  '.Mui-selected': {
    color: 'var(--primary-color) !important',
    border: '1px solid var(--border-color)',
    borderBottom: 'none',
    borderRadius: '5px 5px 0px 0px',
    fontWeight: '600',
  },
  '.MuiTabs-indicator': {
    display: 'none !important',
  },
});
function FAQs() {
  const [tab, setTab] = React.useState(1);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [topics, setTopics] = useState([]);
  const { getFAQs, getQuestionTypes } = useApi();
  const fetchData = async () => {
    try {
      const { data } = await getQuestionTypes();
      if (data.length > 0) {
        setQuestionTypes(data);
      }
      const { data: faqs } = await getFAQs();

      if (faqs.length > 0) {
        setTopics(faqs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const date = new Date();

  let currentDay = String(date.getDate()).padStart(2, '0');

  let currentMonth = date.toLocaleString('default', { month: 'long' });

  let currentYear = date.getFullYear();

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <div className={globalStyles.outerContainer}>
      <div className={globalStyles.authContainer}>
        <Header border />
        <LicenseGrid
          lastUpdated={`${currentDay} ${currentMonth} ${currentYear}`}
          title="Frequently Asked Questions"
          description=""
        >
          <div className={styles.faqsContainer}>
            <CustomTabs
              value={tab}
              onChange={handleChange}
              className={styles.tabContainer}
            >
              {questionTypes.map(type => (
                <Tab
                  label={type.type}
                  value={type.questionTypeId}
                  aria-controls={`simple-tabpanel-${type.questionTypeId}`}
                  key={type.questionTypeId}
                  className={styles.tab}
                />
              ))}
            </CustomTabs>
            {topics.map(topic => (
              <div
                key={topic.question}
                role="tabpanel"
                hidden={tab !== topic.questionTypeId}
                aria-labelledby={`simple-tab-${topic.questionTypeId}`}
                className={styles.content}
              >
                <StyledAccordion
                  titlesize="16px"
                  titleweight="600"
                  border="1px var(--border-color) solid"
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {topic.question}
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: replaceLinksWithAnchors(topic.answer),
                      }}
                      className={styles.answer}
                    ></div>
                  </AccordionDetails>
                </StyledAccordion>
              </div>
            ))}
          </div>
        </LicenseGrid>
      </div>
    </div>
  );
}

export default FAQs;
