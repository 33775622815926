import { Contracts } from 'constants/networks';
import useContract from 'hooks/useContract';
import { useNFTContract } from './token';
import { SALES_CONTRACT_ABI } from './abi';
import { useCheckGasFee } from 'hooks/useCheckGasFee';
import { useAppKitAccount } from '@reown/appkit/react';

export const useSalesContract = () => {
  const { getContract } = useContract();
  const { getERC721Contract } = useNFTContract();
  const { address: account } = useAppKitAccount();
  const { checkGasFee } = useCheckGasFee();
  const getSalesContract = async () => {
    return await getContract(Contracts.sales, SALES_CONTRACT_ABI);
  };

  const buyItemERC20 = async (
    nftAddress,
    tokenId,
    payToken,
    owner,
    referralTrackId = '0'
  ) => {
    const contract = await getSalesContract();
    const args = [nftAddress, tokenId, payToken, owner, referralTrackId];

    const contractIndex = 'buyItem(address,uint256,address,address,uint256)';

    const options = {
      // gasPrice: getHigherGWEI(),
    };
    const estimation = await contract.estimateGas[contractIndex](...args);

    const isValid = await checkGasFee(estimation, account);

    if (!isValid) {
      return;
    }
    return await contract.buyItem(
      nftAddress,
      tokenId,
      payToken,
      owner,
      referralTrackId,
      options
    );
  };

  const cancelListing = async (nftAddress, tokenId) => {
    const contractERC721 = await getERC721Contract(nftAddress);
    const contract = await getSalesContract();

    const approvalEstimation = await contractERC721.estimateGas.setApprovalForAll(
      Contracts.sales,
      false
    );

    if (!(await checkGasFee(approvalEstimation, account))) {
      return;
    }

    const tx1 = await contractERC721.setApprovalForAll(Contracts.sales, false);
    await tx1.wait();

    const options = {
      // gasPrice: getHigherGWEI(),
    };
    const args = [nftAddress, tokenId];

    const estimation = await contract.estimateGas.cancelListing(...args);

    if (!(await checkGasFee(estimation, account))) {
      return;
    }

    const tx = await contract.cancelListing(nftAddress, tokenId, options);
    await tx.wait();
  };

  const listItem = async (
    nftAddress,
    tokenId,
    quantity,
    payToken,
    pricePerItem,
    startingTime
  ) => {
    const contract = await getSalesContract();

    const options = {
      //gasPrice: getHigherGWEI(),
    };
    const args = [
      nftAddress,
      tokenId,
      quantity,
      payToken,
      pricePerItem,
      startingTime,
    ];

    const estimation = await contract.estimateGas.listItem(...args);

    if (!(await checkGasFee(estimation, account))) {
      return;
    }

    return await contract.listItem(
      nftAddress,
      tokenId,
      quantity,
      payToken,
      pricePerItem,
      startingTime,
      options
    );
  };

  const updateListing = async (nftAddress, tokenId, payToken, newPrice) => {
    const contract = await getSalesContract();

    const options = {
      // gasPrice: getHigherGWEI(),
    };

    const args = [nftAddress, tokenId, payToken, newPrice];

    const estimation = await contract.estimateGas.updateListing(...args);

    if (!(await checkGasFee(estimation, account))) {
      return;
    }
    return await contract.updateListing(
      nftAddress,
      tokenId,
      payToken,
      newPrice,
      options
    );
  };

  const createOffer = async (
    nftAddress,
    tokenId,
    payToken,
    quantity,
    pricePerItem,
    deadline
  ) => {
    const contract = await getSalesContract();

    const options = {
      // gasPrice: getHigherGWEI(),
    };

    const args = [
      nftAddress,
      tokenId,
      payToken,
      quantity,
      pricePerItem,
      deadline,
    ];
    const estimation = await contract.estimateGas.createOffer(...args);

    if (!(await checkGasFee(estimation, account))) {
      return;
    }

    return await contract.createOffer(
      nftAddress,
      tokenId,
      payToken,
      quantity,
      pricePerItem,
      deadline,
      options
    );
  };

  const cancelOffer = async (nftAddress, tokenId) => {
    const contract = await getSalesContract();
    const options = {
      // gasPrice: getHigherGWEI(),
    };

    const args = [nftAddress, tokenId];
    const estimation = await contract.estimateGas.cancelOffer(...args);
    if (!(await checkGasFee(estimation, account))) {
      return;
    }

    return await contract.cancelOffer(nftAddress, tokenId, options);
  };

  const acceptOffer = async (nftAddress, tokenId, creator) => {
    const contract = await getSalesContract();
    const NFTContract = await getERC721Contract();
    if (NFTContract) {
      const isApproved = await NFTContract.isApprovedForAll(
        account,
        contract.address
      );
      if (!isApproved) {
        const tx = await NFTContract.setApprovalForAll(contract.address, true);
        await tx.wait();
      }
    }
    const options = {
      // gasPrice: getHigherGWEI(),
    };

    const args = [nftAddress, tokenId, creator];

    const estimation = await contract.estimateGas.acceptOffer(...args);

    const isValid = await checkGasFee(estimation, account);

    if (!isValid) {
      return;
    }

    return await contract.acceptOffer(nftAddress, tokenId, creator, options);
  };

  const registerRoyalty = async (nftAddress, tokenId, royalty) => {
    const contract = await getSalesContract();
    const options = {
      // gasPrice: getHigherGWEI(),
    };

    return await contract.registerRoyalty(
      nftAddress,
      tokenId,
      royalty,
      options
    );
  };

  const getCollectionRoyalty = async nftAddress => {
    const contract = await getSalesContract();
    return await contract.collectionRoyalties(nftAddress);
  };

  return {
    getSalesContract,
    buyItemERC20,
    cancelListing,
    listItem,
    updateListing,
    createOffer,
    cancelOffer,
    acceptOffer,
    registerRoyalty,
    getCollectionRoyalty,
  };
};
