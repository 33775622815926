import { NotiConstants } from '../constants/noti.constants.js';

const NotiActions = {
  fetchNotifications,
  addNewNotification,
  notificationViewed,
  getFromAndCount,
  resetNotifications,

  resetCheckedAsset,
  addCheckedAsset,
  removeCheckedAsset,
  getCheckedAsset,
};

function getFromAndCount() {
  return dispatch => {
    dispatch(_getFromAndCount());
  };
}

const _getFromAndCount = () => {
  return {
    type: NotiConstants.FROM_COUNT,
  };
};

function fetchNotifications(data) {
  return dispatch => {
    dispatch(_fetchNotifications(data));
  };
}

const _fetchNotifications = data => {
  return {
    type: NotiConstants.FETCH_NOTIFICATIONS,
    ...data,
  };
};

function resetNotifications() {
  return dispatch => {
    dispatch(_resetNotifications());
  };
}

const _resetNotifications = () => {
  return {
    type: NotiConstants.RESET_NOTIFICATIONS,
  };
};

function addNewNotification(data) {
  return dispatch => {
    dispatch(_addNewNotification(data));
  };
}

const _addNewNotification = data => {
  return {
    type: NotiConstants.ADD_NEW_NOTIFICATION,
    ...data,
  };
};

function notificationViewed(data) {
  return dispatch => {
    dispatch(_notificationViewed(data));
  };
}

const _notificationViewed = data => {
  return {
    type: NotiConstants.NOTIFICATION_VIEWED,
    ...data,
  };
};

// asset notifications
function resetCheckedAsset() {
  return dispatch => {
    dispatch(_resetCheckedAsset());
  };
}

const _resetCheckedAsset = () => {
  return {
    type: NotiConstants.RESET_CHECKED_ASSET,
  };
};

function addCheckedAsset(data) {
  return dispatch => {
    dispatch(_addCheckedAsset(data));
  };
}

const _addCheckedAsset = data => {
  return {
    type: NotiConstants.ADD_CHECKED_ASSET,
    payload: data,
  };
};

function removeCheckedAsset(assetId) {
  return dispatch => {
    dispatch(_removeCheckedAsset(assetId));
  };
}

const _removeCheckedAsset = assetId => {
  return {
    type: NotiConstants.REMOVE_CHECKED_ASSET,
    payload: assetId,
  };
};

function getCheckedAsset() {
  return dispatch => {
    dispatch(_getCheckedAsset());
  };
}

const _getCheckedAsset = () => {
  return {
    type: NotiConstants.GET_CHECKED_ASSET,
  };
};

export default NotiActions;
