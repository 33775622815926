import React from 'react';

const IconMenu = props => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_761_2185)">
      <path
        d="M0.875 6.99967H14.875M0.875 2.33301H14.875M0.875 11.6663H14.875"
        stroke="white"
        strokeWidth="1.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_761_2185">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconMenu;
