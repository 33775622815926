import React from 'react';

const IconStatus = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 21.9998H19C19.5304 21.9998 20.0391 21.7891 20.4142 21.414C20.7893 21.039 21 20.5303 21 19.9998V10.9998C21.0008 10.8682 20.9755 10.7377 20.9258 10.6159C20.876 10.4941 20.8027 10.3833 20.71 10.2898L12.71 2.28982C12.5226 2.10357 12.2692 1.99902 12.005 1.99902C11.7408 1.99902 11.4874 2.10357 11.3 2.28982L3.3 10.2898C3.20551 10.3824 3.13034 10.4929 3.07885 10.6148C3.02735 10.7366 3.00055 10.8675 3 10.9998V19.9998C3 20.5303 3.21071 21.039 3.58579 21.414C3.96086 21.7891 4.46957 21.9998 5 21.9998ZM10 19.9998V14.9998H14V19.9998H10ZM5 11.4098L12 4.40982L19 11.4098V19.9998H16V14.9998C16 14.4694 15.7893 13.9607 15.4142 13.5856C15.0391 13.2105 14.5304 12.9998 14 12.9998H10C9.46957 12.9998 8.96086 13.2105 8.58579 13.5856C8.21071 13.9607 8 14.4694 8 14.9998V19.9998H5V11.4098Z" />
  </svg>
);

export default IconStatus;
