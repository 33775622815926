import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import BootstrapTooltip from 'components/BootstrapTooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  LinearProgress,
  Select,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  MenuItem,
  useTheme,
  useMediaQuery,
  Chip,
  Checkbox,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Modal from 'components/Modal';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import HeaderActions from 'actions/header.actions';
import Header from 'components/header';
import FooterFS from 'components/Footer';
import showToast from 'utils/toast';
import { useApi } from 'api';
import InputPdfFile from 'components/InputPdfFileFS';
import InputMedia, { MAX_FILES } from 'components/InputMedia';
import styles from './styles.module.scss';
import DocumentList from 'components/DocumentList';
import {
  extractFilename,
  formatError,
  isImageFile,
  isVideoFormat,
  nameRegex,
} from 'utils';
import Notifications from 'actions/noti.actions';
import FilterActions from 'actions/filter.actions';
import NotiActions from 'actions/noti.actions';
import TagsInput from 'components/TagsInput';
import NFTItem from 'components/NFTItem';
import { StyledButton, StyledAccordion } from 'components/StyledComponents';
import Input from 'components/Input';
import { Contracts } from 'constants/networks';
import { useFileChecking } from 'hooks/useFileChecking';
import { ASSET_TYPES } from 'constants/asset.constants';
import Asset from './components/Asset';
import { assetTransform } from './utils';
import AutocompleteToken from 'components/AutocompleteToken';
import { ASSETTYPES } from 'constants/types';

const stringField = message => yup.string().label(message);
const decimalField = message =>
  yup
    .number()
    .min(0)
    .label(message);
const assetIdField = message =>
  yup
    .array()
    .of(
      yup.object({
        assetId: yup
          .number()
          .required()
          .min(0)
          .label('Incorrect assetId'),
      })
    )
    .label(message);

const dataObject = {
  photos: assetIdField('Photos').max(MAX_FILES),
  title: stringField('Title')
    .matches(nameRegex, {
      message: 'Name contains special characters',
    })
    .required(),
  categoryId: decimalField('Category ID')
    .moreThan(0)
    .required(),
  payTokenId: decimalField('Pay Token ID')
    .moreThan(0)
    .required(),
  contractTypeId: decimalField('Contract Type')
    .moreThan(0)
    .required(),
  description: stringField('Description')
    .min(2)
    .required(),
  actorModelReleases: assetIdField('Actor or Model Releases'),
  additionalDocuments: assetIdField('Additional Documents'),
};
const assetObject = {
  frontCover: assetIdField('Front cover')
    .min(1)
    .max(30),
  thumbnailImage: assetIdField('Thumbnail Image')
    .length(2)
    .required(),
  ...dataObject,
};

const assetVideoObject = {
  frontCover: assetIdField('Front cover')
    .min(1)
    .max(30),
  ...dataObject,
};

const AttributeInput = ({
  value,
  index,
  handleInputChange,
  inputAction,
  handleInputAction,
  error,
}) => (
  <div className={styles.attributeInput}>
    <input
      name="attribute"
      className={cx(styles.formInput, error && styles.errorValidate)}
      placeholder="Enter Attribute Name"
      onChange={e => handleInputChange(e, index)}
      value={value.attribute}
    />
    <input
      name="value"
      className={cx(styles.formInput)}
      placeholder="Enter Attribute Value"
      onChange={e => handleInputChange(e, index)}
      value={value.value}
    />
    <StyledButton
      onClick={handleInputAction}
      cancel={inputAction !== 'Add' ? 'true' : undefined}
    >
      {inputAction}
    </StyledButton>
  </div>
);

const PaintBoard = () => {
  const dispatch = useDispatch();
  const {
    checkAssetsReady,
    getCategories,
    registerNft,
    deleteFile,
    fetchCreatedNft,
    getContractTypes,
    uploadFileFromUrl,
    removeNft,
    getPendingFiles,
    setAssetUploaded,
    fetchPayTokens,
  } = useApi();
  const { authToken, user } = useSelector(state => state.Auth);
  const userWallet = user?.userWallets?.at(0);
  // pending or failed assets
  const { checkedAssets } = useSelector(state => state.Notifications);

  const history = useHistory();
  const { createdNftId } = useParams();
  const [categoryId, setCategoryId] = useState(0);
  const [categories, setCategories] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [payTokens, setPayTokens] = useState([]);

  const [subcategories, setSubcategories] = useState([]);
  const [title, setTitle] = useState('');
  const [hasWrongFormat, setHasWrongFormat] = useState(false);
  const [contractTypeId, setContractTypeId] = useState(0);
  const [payToken, setPayToken] = useState();

  const [description, setDescription] = useState('');
  const [tags, setTags] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [attributes, setAttributes] = useState([{ attribute: '', value: '' }]);
  const [royalty, setRoyalty] = useState(0);
  const [actorModelReleases, setActorModelReleases] = useState([]);
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [frontCover, setFrontCover] = useState([]);
  const [imageFrontCover, setImageFrontCover] = useState({});
  const [selectedImage, setSelectedImage] = useState();
  const [photoProgress, setPhotoProgress] = useState({});
  const [thumbnailImage, setThumbnailImage] = useState([]);
  const [assetsToCheck, setAssetsToCheck] = useState([]);
  const [thumbnailImageProgress, setThumbnailImageProgress] = useState({});
  const [validateError, setValidateError] = useState({});
  const [kycRequired, setKycRequired] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openCorruptedItemsModal, setOpenCorruptedItemsModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [uploadExpanded, setUploadExpanded] = useState(false);
  const [aboutExpanded, setAboutExpanded] = useState(false);
  const [isReLoad, setIsReload] = useState(false);
  const updateMode = useMemo(() => Boolean(createdNftId), [createdNftId, user]);

  const [previewInView, setPreviewIsInView] = useState(true);
  const checkPreviewInView = () => {
    const element = document.getElementById('preview');
    if (element) {
      const rect = element.getBoundingClientRect();
      const isInView = rect.top > 60;

      setPreviewIsInView(state => (isInView === state ? state : isInView));
    }
  };

  const savingFields = JSON.stringify({
    categoryId,
    description,
    subcategories: subcategories,
    name: title,
    contractTypeId: contractTypeId,
    payToken: payToken,
    tags: tags,
    actorModelReleases,
    additionalDocuments,
    photos,
    frontCover,
    thumbnail: thumbnailImage,
    attributes: JSON.stringify(
      attributes.filter(item => item.attribute !== '' && item.value !== '')
    ),
  });

  useEffect(() => {
    if (!updateMode && isReLoad) {
      window.localStorage.setItem('nftItem', savingFields);
    }
  }, [savingFields, updateMode, isReLoad]);

  const { errors, successes } = useFileChecking(
    frontCover,
    photos,
    checkedAssets
  );

  useEffect(() => {
    errors.forEach(message => {
      showToast('error', message);
    });
    successes.forEach(message => {
      showToast('success', message);
    });
  }, [errors, successes]);

  const queryCategories = async () => {
    try {
      const response = await getCategories();
      if (response.data.length) {
        setCategories(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const queryContractTypes = async () => {
    try {
      const response = await getContractTypes();
      if (response.data.length) {
        setContractTypes(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const queryPayTokens = async () => {
    try {
      const response = await fetchPayTokens();
      if (response.data.length) {
        setPayTokens(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setRoyalty(10);
    if (authToken) {
      queryCategories();
      queryContractTypes();
      setKycRequired(user?.kycRequired || userWallet?.kycProcess === 'pending');
      queryPayTokens();
    }
  }, [authToken]);

  useEffect(() => {
    checkPreviewInView();
    dispatch(HeaderActions.toggleSearchbar(true));
    dispatch(FilterActions.updateCategoryFilter(null));
    dispatch(FilterActions.resetStatusFilter());
    dispatch(NotiActions.resetCheckedAsset());
    document.addEventListener('scroll', checkPreviewInView);

    return () => {
      document.removeEventListener('scroll', checkPreviewInView);
    };
  }, []);

  const setupAsset = async assets => {
    const additionalDocuments = [];
    const actorModelReleases = [];
    let thumbnailImages = [];
    const photos = [];
    const watermarkedFrontCover = [];
    const frontCover = [];
    const imageFrontCover = {};
    let chosenWatermarkedFrontCoverID;
    let chosenWatermarkedFrontCover;
    let selectedImage;
    for (let i = 0; i < assets.length; i++) {
      const asset = assets[i];
      const assetType = asset.assetType ? asset.assetType.name : asset.type;
      if (assetType === ASSET_TYPES.THUMBNAIL_IMAGE) {
        thumbnailImages = [asset, ...thumbnailImages];
      } else if (assetType === ASSET_TYPES.WATERMARKED_THUMBNAIL_IMAGE) {
        thumbnailImages.push(asset);
      } else if (assetType === ASSET_TYPES.FRONT_COVER) {
        if (isImageFile(asset.url)) {
          let watermarkedFrontCover;
          for (let i = 0; i < asset.relatedAssets?.length ?? 0; i++) {
            const item = asset.relatedAssets[i];
            const type = item.assetType ? item.assetType.name : item.type;
            switch (type) {
              case ASSET_TYPES.WATERMARKED_SOURCE_FILE: {
                watermarkedFrontCover = item;
                break;
              }
              case ASSET_TYPES.THUMBNAIL_IMAGE: {
                selectedImage = asset.assetId;
                break;
              }
            }
          }
          if (selectedImage === asset.assetId) {
            chosenWatermarkedFrontCoverID = watermarkedFrontCover.assetId;
          }
          if (!watermarkedFrontCover) {
            const transformedAsset = assetTransform([asset])[0];
            await uploadWatermarkedFrontCover(transformedAsset, data => {
              transformedAsset[`relatedAssets`] = [data];
              setFrontCover(prev =>
                prev.map(item =>
                  item.assetId === transformedAsset.assetId
                    ? transformedAsset
                    : item
                )
              );
              setImageFrontCover(prevState => ({
                ...prevState,
                [`${transformedAsset.assetId}`]: transformedAsset,
              }));
            });
          }
          imageFrontCover[`${asset.assetId}`] = assetTransform([asset])[0];
        }
        frontCover.push(asset);
      } else if (assetType === ASSET_TYPES.WATERMARKED_SOURCE_FILE) {
        watermarkedFrontCover.push(asset);
      } else if (assetType === ASSET_TYPES.ADDITIONAL_DOCUMENTS) {
        additionalDocuments.push(asset);
      } else if (assetType === ASSET_TYPES.PHOTOS) {
        photos.push(asset);
      } else if (assetType === ASSET_TYPES.ACTOR_MODEL_RELEASES)
        actorModelReleases.push(asset);
    }
    chosenWatermarkedFrontCover = watermarkedFrontCover.filter(
      item => item.assetId === chosenWatermarkedFrontCoverID
    )[0];
    setThumbnailImage(
      thumbnailImages.length === 1 && selectedImage
        ? assetTransform([...thumbnailImages, chosenWatermarkedFrontCover])
        : assetTransform(thumbnailImages)
    );
    setSelectedImage(selectedImage);
    setAdditionalDocuments(assetTransform(additionalDocuments));
    setActorModelReleases(assetTransform(actorModelReleases));
    setPhotos(assetTransform(photos));
    setFrontCover(assetTransform(frontCover));
    setImageFrontCover(imageFrontCover);
  };
  useEffect(() => {
    if (!updateMode && user?.isAdmin) {
      history.push('/');
    }

    const setupData = (item, isLocalStorage = false) => {
      try {
        if (isLocalStorage) {
          if (item.tags) {
            setTags(item.tags);
          }
          if (item.subcategories) {
            setSubcategories(item.subcategories);
          }
          if (item.attributes) {
            const attributes = JSON.parse(item.attributes);
            if (attributes && attributes.length > 0) {
              setAttributes(attributes);
            }
          }
        } else {
          if (item.attributes && item.attributes.length > 0) {
            setAttributes(item.attributes);
          }

          if (item.subcategories) {
            setSubcategories(item.subcategories?.map(a => a.categoryId));
          }
          if (item.tags.length > 0 && item.tags[0].name) {
            const values = item.tags.map(item => item.name);
            setTags(values);
          }

          if (item.nftAssets) {
            setupAsset(item.nftAssets);
          }
        }

        try {
          stringField('Category ID').validateSync(item.categoryId);
          setCategoryId(item.categoryId);
        } catch (error) {
          showToast('error', error.message);
        }

        try {
          stringField('Description').validateSync(item.description);
          setDescription(item.description);
        } catch (error) {
          showToast('error', error.message);
        }

        try {
          stringField('Title').validateSync(item.name);
          setTitle(item.name);
          if (!nameRegex.test(item.name)) {
            setHasWrongFormat(true);
          } else {
            setHasWrongFormat(false);
          }
        } catch (error) {
          showToast('error', error.message);
        }

        try {
          stringField('Contract type').validateSync(item.contractTypeId);
          setContractTypeId(item.contractTypeId);
        } catch (error) {
          showToast('error', error.message);
        }

        try {
          if (item.payToken) {
            setPayToken(item.payToken);
          }
        } catch (error) {
          showToast('error', error.message);
        }
      } catch (err) {
        console.log(err);
        showToast('error', 'Please fill the fields again');
      }
    };

    const fetchNft = async () => {
      const { data: nft, errors } = await fetchCreatedNft(
        authToken,
        createdNftId
      );

      if (errors) {
        showToast('error', `${errors[0].message}`);
        history.push(`/create`);
        return;
      }
      if (user?.userId === nft.user.userId) {
        setupData(nft);
      }
      if (!history.location.state || !history.location.state.isCreated) {
        showToast(
          'success',
          `${nft.name} is a draft. Please, update to create or remove it`
        );
      }
    };

    const savedItem = window.localStorage.getItem('nftItem');
    if (updateMode) {
      fetchNft();
    } else {
      if (savedItem) {
        const savedNft = JSON.parse(savedItem);
        setupData(savedNft, true);
      }
      (async authToken => {
        const response = await getPendingFiles(authToken);
        if (response && response.data && response.data.length > 0) {
          const { data } = response;
          await setupAsset(data);
        }
      })(authToken);
    }
    setIsReload(true);
  }, [authToken, updateMode]);

  const createNFT = async isDraft => {
    let tempThumbnail = [...thumbnailImage];
    let noThumbnail = false;

    try {
      if (
        tempThumbnail.length === 0 &&
        Object.keys(imageFrontCover).length > 0
      ) {
        const data = Object.values(imageFrontCover)[0];
        await handleUseSourceFileAsPreview(data);
        showToast('warning', `Uploading the first source file as thumbnail`);

        return;
      }
      if (
        frontCover &&
        frontCover.length === 1 &&
        isVideoFormat(frontCover[0].url)
      ) {
        //If there is only one source file video, no need for a thumbnail image
        noThumbnail = true;
      }

      await yup.object(noThumbnail ? assetVideoObject : assetObject).validate(
        {
          frontCover,
          ...(!noThumbnail && { thumbnailImage: tempThumbnail }),
          photos,
          description,
          subcategories,
          title,
          categoryId,
          contractTypeId,
          actorModelReleases,
          additionalDocuments,
          ...(payToken && { payTokenId: payToken?.payTokenId }),
        },
        { abortEarly: false }
      );
      setValidateError({});
    } catch (error) {
      console.log('error', error.inner);
      setIsRegistering(false);
      setIsSavingDraft(false);
      let errorList = {};
      let expandUpload = false;
      let expandAbout = false;
      error.inner.forEach(el => {
        if (el.path.includes('attributes')) {
          const splitText = el.path.split('.');
          if (!errorList['attributes']) {
            errorList['attributes'] = { [`${splitText[1]}`]: true };
          } else {
            errorList['attributes'][splitText[1]] = true;
          }
        } else {
          errorList[el.path] = true;
          if (
            el.path === ASSET_TYPES.FRONT_COVER ||
            el.path === ASSET_TYPES.THUMBNAIL_IMAGE
          ) {
            expandUpload = true;
          } else if (
            el.path === 'title' ||
            el.path === 'description' ||
            el.path === 'categoryId' ||
            el.path === 'contractTypeId'
          ) {
            expandAbout = true;
          }
        }
      });
      if (expandUpload) {
        setUploadExpanded(expandUpload);
      }
      if (expandAbout) {
        setAboutExpanded(expandAbout);
      }
      setValidateError({ ...errorList });
      const element = error.inner[0].path;
      console.log('element scroll to', element);
      console.log('error', errorList);

      document
        .getElementById(element)
        .scrollIntoView({ block: 'center', behavior: 'smooth' });
      setIsRegistering(false);
      return;
    }

    const takingAssetId = asset => {
      return asset.map(el => el.assetId);
    };

    let relatedFrontCoverAssets = [];
    for (let i = 0; i < frontCover.length; i++) {
      relatedFrontCoverAssets.push(...(frontCover[i]?.relatedAssets || []));
    }
    const dataObject = {
      nftId: createdNftId,
      name: title,
      description: description,
      categoryId: categoryId,
      contractTypeId: contractTypeId,
      subcategories,
      tags: tags,
      contractAddress: Contracts.artion,
      actorModelReleases: takingAssetId(actorModelReleases),
      additionalDocuments: takingAssetId(additionalDocuments),
      photos: [...takingAssetId(photos)],
      frontCover: takingAssetId([...frontCover]),
      relatedFrontCoverAssets: takingAssetId([...relatedFrontCoverAssets]),
      ...(!noThumbnail && { thumbnailImage: tempThumbnail[0].assetId }),
      ...(!noThumbnail && {
        watermarkedThumbnailImage: tempThumbnail[1].assetId,
      }),
      royalty: royalty,
      payTokenId: payToken.payTokenId,
    };

    const filteredAttributes = attributes.filter(
      item => item.attribute !== '' && item.value !== ''
    );

    if (filteredAttributes.length > 0) {
      dataObject.attributes = JSON.stringify(filteredAttributes);
    }

    const assetList = [
      ...dataObject.frontCover,
      ...(dataObject.thumbnailImage ? [dataObject.thumbnailImage] : []),
      ...(dataObject.watermarkedThumbnailImage
        ? [dataObject.watermarkedThumbnailImage]
        : []),
      ...dataObject.photos,
      ...dataObject.additionalDocuments,
      ...dataObject.actorModelReleases,
    ];
    const { data: assetsToCheck } = await checkAssetsReady(
      authToken,
      assetList
    );
    // pending or failed
    setAssetsToCheck(assetsToCheck);
    let processFailed = false;

    const failedAssets = assetsToCheck?.filter(a => a.failed);
    if (failedAssets?.length) {
      failedAssets.forEach(asset => {
        showToast(
          'error',
          `The media file "${extractFilename(asset.url)}" is corrupted`
        );
      });
      processFailed = true;
    }

    const pendingAssets = assetsToCheck?.filter(a => a.status === 'pending');
    if (pendingAssets?.length) {
      pendingAssets.forEach(asset => {
        showToast(
          'warning',
          `Verifying "${extractFilename(asset.url)}" is in progress`
        );
      });
      processFailed = true;
    }

    const noParentQuery = assetsToCheck?.filter(
      a =>
        !a.parentId ||
        (a.assetTypeId !== ASSETTYPES.FRONT_COVER &&
          a.assetTypeId !== ASSETTYPES.WATERMARKED_SOURCE_FILE &&
          a.assetTypeId !== ASSETTYPES.WATERMARKED_THUMBNAIL_IMAGE)
    );

    const likelyAssets = noParentQuery?.filter(a => a.likely);
    if (likelyAssets?.length) {
      likelyAssets.forEach(asset => {
        showToast(
          'warning',
          `Verifying "${extractFilename(asset.url)}" is in progress`
        );
      });
      processFailed = true;
    }

    const nsfwAssets = noParentQuery?.filter(a => a.nsfw);
    if (nsfwAssets?.length) {
      nsfwAssets.forEach(asset => {
        showToast('error', `"${extractFilename(asset.url)}" is inappropriate`);
      });
      processFailed = true;
    }

    if (processFailed) {
      return { data: {}, errors: [] };
    }

    const data = await registerNft(authToken, dataObject, isDraft);
    return data;
  };

  const showKycWarning = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setShowWarning(true);
    setTimeout(() => {
      setShowWarning(false);
    }, 3000);
  };

  useEffect(() => {
    const assetList = [
      ...frontCover,
      ...thumbnailImage,
      ...photos,
      ...additionalDocuments,
      ...actorModelReleases,
    ].filter(Boolean);

    if (assetList?.length) {
      // eager update
      setAssetsToCheck(prev => {
        const assetsMap = new Map(prev.map(asset => [asset.assetId, asset]));
        // videos, audios and ZIP files are initially pending
        assetList.forEach(a => {
          if (!assetsMap.has(a.assetId)) {
            assetsMap.set(a.assetId, {
              assetId: a.assetId,
              failed: a.failed || false,
              status: a.status || 'pending',
              errors: a.errors,
              uploaded: a.uploaded,
              nsfw: a.nsfw,
              likely: a.likely,
            });
          }
        });
        return [...assetsMap.values()];
      });
    }
  }, [
    authToken,
    frontCover,
    thumbnailImage,
    photos,
    additionalDocuments,
    actorModelReleases,
  ]);
  const registerNftHandler = async (draft = false) => {
    if (kycRequired) {
      showKycWarning();
      return;
    }

    draft ? setIsSavingDraft(true) : setIsRegistering(true);
    try {
      if (isRegistering || isSavingDraft) return;
      if (!draft) {
        if (kycRequired) {
          setIsRegistering(false);
          setIsSavingDraft(false);
          showToast('error', 'KYC is not verified');
          return;
        }
      }
      let nftId = createdNftId;
      const response = await createNFT(draft);
      if (response) {
        const { data, errors } = response;
        if (data && Object.keys(data).length > 0) {
          nftId = data;
          if (draft) {
            showToast('success', `Your draft ${title} has been saved!`);
            history.push(`/draft/${nftId}`, { isCreated: true });
          } else {
            showToast('success', `Your ${title} has been registered!`);
            history.push(`/pending/${nftId}`);
          }
        } else {
          if (errors && errors.length > 0 && errors[0].message) {
            showToast('error', errors[0].message);
          }
          // else {
          //   showToast(
          //     'error',
          //     'The form is not completed. Please check your entry and try again'
          //   );
          // }
          draft ? setIsSavingDraft(false) : setIsRegistering(false);
          return;
        }
      } else {
        draft ? setIsSavingDraft(false) : setIsRegistering(false);
        return;
      }

      window.localStorage.removeItem('nftItem');

      draft ? setIsSavingDraft(false) : setIsRegistering(false);
    } catch (error) {
      console.log(error);
      showToast('error', formatError(error?.error || error, 'Create Assets'));

      draft ? setIsSavingDraft(false) : setIsRegistering(false);
    }
  };

  const removeNftHandler = async (nftId, authToken) => {
    try {
      setIsCanceling(true);
      const { data, errors } = await removeNft(nftId, authToken);
      if (Object.keys(data).length > 0) {
        showToast('success', 'Removed Your Assets successfully');
        history.push('/create');
      } else {
        if (errors.length > 0 && errors[0].message) {
          showToast('error', errors[0].message);
        }
      }
    } catch (error) {
      console.log(error);
      showToast('error', 'Cannot remove your Assets');
    } finally {
      setIsCanceling(false);
    }
  };

  const cancelHandler = async () => {
    try {
      setIsCanceling(true);
      await removeAllFiles();
      window.localStorage.removeItem('nftItem');
      history.go(0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsCanceling(false);
    }
  };

  const waitingUploadPhotos = Object.keys(photoProgress).length !== 0;
  const waitingUploadThumbnailImage =
    Object.keys(thumbnailImageProgress).length !== 0;

  const isDocumentAttached =
    actorModelReleases.length > 0 || additionalDocuments.length > 0;

  const corruptedItemsErrors = useMemo(() => {
    const firstItem = frontCover?.at(0);
    return (!firstItem?.nsfw && !firstItem?.likely && firstItem?.errors) || [];
  }, [frontCover]);

  const removeFromCheckedAssets = id => {
    dispatch(Notifications.removeCheckedAsset(id));
  };
  const removeAllFiles = async () => {
    let relatedFrontCoverAssets = [];
    for (let i = 0; i < frontCover.length; i++) {
      relatedFrontCoverAssets.push([...(frontCover[i]?.relatedAssets || [])]);
    }
    const assets = [
      ...photos,
      ...frontCover,
      ...relatedFrontCoverAssets,
      ...thumbnailImage,
      ...additionalDocuments,
      ...actorModelReleases,
    ];
    for (let i = 0; i < assets.length; i++) {
      const asset = assets[i];
      await deleteFile(authToken, asset.assetId);
    }
    setPhotos([]);
    setFrontCover([]);
    setThumbnailImage([]);
    setAdditionalDocuments([]);
    setActorModelReleases([]);
  };
  const removeAssetPhoto = async id => {
    removeFromCheckedAssets(id);

    setPhotos(prevState => {
      const dublicate = [...prevState];
      const removingIndex = dublicate.findIndex(el => el.assetId === id);
      dublicate.splice(removingIndex, 1);
      return dublicate;
    });
    await deleteFile(authToken, id);
  };

  const removeAssetFrontCover = async id => {
    removeFromCheckedAssets(id);
    await deleteFile(authToken, id);
    if (
      thumbnailImage.length > 1 &&
      thumbnailImage[1].type !== ASSET_TYPES.WATERMARKED_THUMBNAIL_IMAGE
    ) {
      if (thumbnailImage.at(0)?.parentId === id) {
        setThumbnailImage([]);
      }
    }
    setFrontCover(prevState => {
      const dublicate = [...prevState];
      const removingIndex = dublicate.findIndex(el => el.assetId === id);
      dublicate.splice(removingIndex, 1);
      return dublicate;
    });
    setImageFrontCover(prevState => {
      // eslint-disable-next-line no-unused-vars
      const { [id]: omitted, ...rest } = prevState;
      return rest;
    });
  };
  // eslint-disable-next-line no-unused-vars
  const removeAssetThumbnailImage = async id => {
    if (thumbnailImage.length > 0) {
      await deleteFile(authToken, thumbnailImage[0].assetId);
      setFrontCover(prevState => {
        const duplicate = [...prevState];
        for (let i = 0; i < duplicate.length; i++) {
          const asset = duplicate[i];
          if (asset.relatedAssets) {
            const removingIndex = asset.relatedAssets.findIndex(
              el => el.assetId === thumbnailImage[0].assetId
            );
            if (removingIndex >= 0) {
              asset.relatedAssets.splice(removingIndex, 1);
            }
          }
        }
        return duplicate;
      });
      for (let i = 0; i < thumbnailImage.length; i++) {
        removeFromCheckedAssets(thumbnailImage[i].assetId);
      }
      setThumbnailImage([]);
    }
  };

  const thumbnailBars = Object.entries(thumbnailImageProgress).map(el => {
    return (
      !!el[1] && (
        <LinearProgress variant="determinate" key={el[0]} value={el[1]} />
      )
    );
  });

  const photoBars = Object.entries(photoProgress).map(el => {
    return (
      !!el[1] && (
        <LinearProgress variant="determinate" key={el[0]} value={el[1]} />
      )
    );
  });
  const renderPreviewGrid = (kycWarning, id = null) => {
    return (
      <>
        <div
          className={cx(
            kycWarning ? styles.kycContainer : styles.photoContainer,
            showWarning && styles.showWarning
          )}
          id={id ? id : ''}
        >
          <div
            className={cx(
              styles.imageHolder,
              thumbnailImage.length === 0 &&
                validateError.thumbnailImage &&
                styles.errorValidate
            )}
          >
            <p className={styles.previewTitle}>
              {kycWarning ? 'Unable to create your Assets' : 'Preview'}
            </p>
            {kycWarning && (
              <p className={styles.previewDescription}>
                {
                  "To create Assets you need to get KYC authentication. Please click 'verify' to continue"
                }
              </p>
            )}

            {kycWarning ? (
              <StyledButton
                cancel="true"
                width="100%"
                onClick={() => history.push('/profile/kyc')}
              >
                Verify KYC Authentication
              </StyledButton>
            ) : (
              <div className={styles.skeletonContainer}>
                <div className={styles.skeletonContent}>
                  <NFTItem
                    nft={{
                      thumbnailImage: thumbnailImage
                        ? thumbnailImage[0]?.fullUrl
                        : '',
                      thumbnailVideo:
                        frontCover.length === 1 &&
                        isVideoFormat(frontCover[0].url)
                          ? frontCover[0].fullUrl
                          : '',
                      name: title,
                      description,
                      category:
                        categoryId > 0 &&
                        categories.filter(
                          item => item.categoryId === categoryId
                        )[0],
                      userName: user.name,
                      userAvatar: user.avatar,
                    }}
                    preview
                    hideSubItems
                    user={user}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  const renderCorruptedItems = () => {
    return (
      <div className={styles.corruptedItems}>
        <div className={styles.corruptedItemtitle}>Corrupted items:</div>
        <div className={styles.panel}>
          {corruptedItemsErrors.map((item, index) => (
            <div key={index} className={styles.corruptedItem}>
              <div>{item.fileName}</div>
              <div>{item.error}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleAttributeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...attributes];
    attributes[index][name] = value;
    setAttributes(list);
  };
  const handleAttributeRemove = index => {
    const list = [...attributes];
    list.splice(index, 1);
    setAttributes(list);
  };

  const handleAttributeAdd = () => {
    setAttributes([...attributes, { attribute: '', value: '' }]);
  };

  const uploadWatermarkedFrontCover = async (frontCover, setFrontCover) => {
    const url = frontCover.fullUrl;
    const fileName = frontCover.url;
    try {
      await uploadFileFromUrl(
        authToken,
        url,
        fileName,
        () => {},
        async data => {
          await setAssetUploaded(authToken, data.assetId);
          setFrontCover(data);
        },
        ASSET_TYPES.WATERMARKED_SOURCE_FILE,
        frontCover.assetId,
        true
      );
    } catch (error) {
      console.log('message', error.message);

      showToast('error', `File ${frontCover.url} is invalid`);
      await removeAssetFrontCover(frontCover.assetId);
    }
  };

  const uploadWatermarkedThumbnail = async (thumbnail, setThumbnailImage) => {
    const url = thumbnail.fullUrl;
    const fileName = thumbnail.url;
    await uploadFileFromUrl(
      authToken,
      url,
      fileName,
      () => {},
      async data => {
        await setAssetUploaded(authToken, data.assetId);
        setThumbnailImage(data);
      },
      ASSET_TYPES.WATERMARKED_THUMBNAIL_IMAGE,
      thumbnail.assetId,
      true
    );
  };

  const handleUseSourceFileAsPreview = async frontCover => {
    await removeAssetThumbnailImage();
    const url = frontCover?.fullUrl || frontCover.url;
    const fileName = frontCover.url;

    return new Promise(resolve => {
      uploadFileFromUrl(
        authToken,
        url,
        fileName,
        progress => {
          setThumbnailImageProgress({
            fileName: progress,
          });
        },
        async data => {
          await setAssetUploaded(authToken, data.assetId);
          setThumbnailImageProgress({});
          setThumbnailImage([
            data,
            ...assetTransform([frontCover.relatedAssets[0]]),
          ]);
          resolve();
        },
        ASSET_TYPES.THUMBNAIL_IMAGE,
        frontCover.assetId
      );
    });
  };

  const availableSubcategories = useMemo(
    () =>
      categories
        .flatMap(a => a.subcategories)
        .filter(a => a.parentId === categoryId)
        .reduce((acc, current) => {
          acc[current.categoryId] = current;
          return acc;
        }, {}),
    [categories, categoryId]
  );

  const mergeAssets = (prevAssets, newAssets) => {
    const assetsMap = new Map();

    prevAssets.forEach(asset => {
      assetsMap.set(asset.assetId, asset);
    });

    newAssets.forEach(asset => {
      assetsMap.set(asset.assetId, asset);
    });

    return [...assetsMap.values()];
  };

  return (
    <div className={styles.container}>
      <Header isSticky="sticky" />
      <div className={styles.body}>
        <div className={styles.ad}>Advertisement</div>
        <Grid container className={styles.content}>
          <Grid item xs={12} md={7} className={styles.panel}>
            <div className={styles.title}>
              <p className={styles.titleContent}>Create Your Assets</p>
            </div>
            <div className={styles.panelInputs}>
              <StyledAccordion
                border="1px var(--border-color) solid !important"
                sx={{ overflow: 'hidden' }}
                expanded={uploadExpanded}
                onChange={() => setUploadExpanded(prev => !prev)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  className={styles.inputGroup}
                  id="panel1-header"
                >
                  Upload Files
                </AccordionSummary>
                <AccordionDetails>
                  <Asset
                    frontCover={frontCover}
                    setFrontCover={setFrontCover}
                    imageFrontCover={imageFrontCover}
                    setImageFrontCover={setImageFrontCover}
                    waitingUploadThumbnailImage={waitingUploadThumbnailImage}
                    uploadWatermarkedFrontCover={uploadWatermarkedFrontCover}
                    handleUseSourceFileAsPreview={handleUseSourceFileAsPreview}
                    removeAssetFrontCover={removeAssetFrontCover}
                    kycRequired={kycRequired}
                    validateError={validateError.frontCover}
                    assetsToCheck={mergeAssets(assetsToCheck, checkedAssets)}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    corruptedItemsErrors={corruptedItemsErrors}
                    setOpenCorruptedItemsModal={setOpenCorruptedItemsModal}
                  />
                  <div className={styles.uploadContainer}>
                    <div className={styles.uploadTitle}>
                      Thumbnail*&nbsp;
                      <BootstrapTooltip
                        title="Upload the image that will promote your content in the marketplace"
                        placement="top"
                      >
                        <HelpOutlineIcon />
                      </BootstrapTooltip>
                    </div>
                    <InputMedia
                      key={'ThumbnailImage'}
                      changeFunction={asset => {
                        uploadWatermarkedThumbnail(asset, data =>
                          setThumbnailImage([asset, ...assetTransform([data])])
                        );
                      }}
                      assetType={ASSET_TYPES.THUMBNAIL_IMAGE}
                      multiple={false}
                      setProgressUpload={setThumbnailImageProgress}
                      waitingUpload={waitingUploadThumbnailImage}
                      data={thumbnailImage}
                      removeCb={removeAssetThumbnailImage}
                      onlyImages={true}
                      disabled={
                        kycRequired ||
                        (frontCover.length === 1 &&
                          isVideoFormat(frontCover[0].url))
                      }
                      hasError={validateError.thumbnailImage}
                      assetsToCheck={mergeAssets(assetsToCheck, checkedAssets)}
                    />
                    <div className={styles.fullBarLoader}>{thumbnailBars}</div>
                  </div>
                  <div className={styles.uploadContainer}>
                    <div className={styles.uploadTitle}>
                      Add Promotional Media&nbsp;
                      <BootstrapTooltip
                        title="Drag or choose your file to upload (up to 30 files)"
                        placement="top"
                      >
                        <HelpOutlineIcon />
                      </BootstrapTooltip>
                    </div>
                    <InputMedia
                      key={'Photos'}
                      changeFunction={asset =>
                        setPhotos(prev => [...prev, asset])
                      }
                      multiple={true}
                      assetType={ASSET_TYPES.PHOTOS}
                      setProgressUpload={setPhotoProgress}
                      waitingUpload={waitingUploadPhotos}
                      data={photos}
                      removeCb={removeAssetPhoto}
                      disabled={kycRequired}
                      assetsToCheck={mergeAssets(assetsToCheck, checkedAssets)}
                    />
                    <div className={styles.fullBarLoader}>{photoBars}</div>
                  </div>
                  <div className={styles.uploadContainer}>
                    <div className={styles.uploadTitle}>
                      Releases&nbsp;
                      <BootstrapTooltip
                        title="Add all legal releases in PDF, that verify your right to own, sell and distribute your content"
                        placement="top"
                      >
                        <HelpOutlineIcon />
                      </BootstrapTooltip>
                    </div>
                    {actorModelReleases.length > 0 ? (
                      <div className={cx(styles.formGroup)}>
                        <div className={styles.documentsList}>
                          <DocumentList
                            documents={actorModelReleases}
                            setDocuments={setActorModelReleases}
                            updateMode
                          />
                        </div>
                      </div>
                    ) : (
                      <InputPdfFile
                        key={'actorModelRelease'}
                        changeFunction={setActorModelReleases}
                        assetType={ASSET_TYPES.ACTOR_MODEL_RELEASES}
                        disabled={kycRequired}
                      />
                    )}
                  </div>
                  <div className={styles.uploadContainer}>
                    <div className={styles.uploadTitle}>
                      Additional Documents
                    </div>
                    {additionalDocuments.length > 0 ? (
                      <div className={cx(styles.formGroup)}>
                        <div className={styles.documentsList}>
                          <DocumentList
                            documents={additionalDocuments}
                            setDocuments={setAdditionalDocuments}
                            updateMode
                          />
                        </div>
                      </div>
                    ) : (
                      <InputPdfFile
                        key={'AdditionalDocuments'}
                        changeFunction={setAdditionalDocuments}
                        assetType={ASSET_TYPES.ADDITIONAL_DOCUMENTS}
                        disabled={kycRequired}
                      />
                    )}
                  </div>
                  {isDocumentAttached && <></>}
                </AccordionDetails>
              </StyledAccordion>
              <StyledAccordion
                border="1px var(--border-color) solid !important"
                sx={{ overflow: 'hidden' }}
                expanded={aboutExpanded}
                onChange={() => setAboutExpanded(prev => !prev)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  className={styles.inputGroup}
                  id="panel1-header"
                >
                  About Your Assets
                </AccordionSummary>
                <AccordionDetails>
                  <div id="title" className={styles.formGroup}>
                    <Input
                      hasError={validateError && validateError.title}
                      placeholder=""
                      value={title}
                      onChange={e => {
                        setTitle(e.target.value);
                        if (!nameRegex.test(e.target.value)) {
                          setHasWrongFormat(true);
                        } else {
                          setHasWrongFormat(false);
                        }
                      }}
                      label="Name*"
                    />
                    {hasWrongFormat && (
                      <div className={styles.errorText}>
                        No special characters allowed. Please enter a-z, A-Z,
                        0-9, -, _.
                      </div>
                    )}
                  </div>
                  <div id="description" className={styles.formGroup}>
                    <Input
                      hasError={validateError && validateError.description}
                      placeholder=""
                      value={description}
                      onChange={e => {
                        setDescription(e.target.value);
                      }}
                      label="Description*"
                      isTextArea={true}
                    />
                    <div className={styles.lengthIndicator}></div>
                  </div>
                  {categories.length > 0 && (
                    <div id="collectionId" className={styles.formGroup}>
                      <p className={styles.formLabel}>
                        Category*&nbsp;
                        <BootstrapTooltip
                          title="Select the right category for your source file."
                          placement="top"
                        >
                          <HelpOutlineIcon />
                        </BootstrapTooltip>
                      </p>
                      <Select
                        value={categoryId}
                        onChange={e => {
                          setCategoryId(e.target.value);
                          setSubcategories([]); // reset subcategories
                        }}
                        className={cx(
                          styles.select,
                          validateError.categoryId && styles.errorValidate
                        )}
                        defaultValue={0}
                        sx={{
                          '.MuiSelect-select': {
                            padding: '0 !important',
                          },
                          '&.Mui-focused': {
                            '.MuiOutlinedInput-notchedOutline': {
                              borderColor: 'var(--primary-color) !important',
                            },
                          },
                        }}
                      >
                        <MenuItem value={0} disabled>
                          <p className={styles.menuLabel}>Choose category</p>
                        </MenuItem>
                        {categories.map(col => (
                          <MenuItem
                            key={col?.categoryId}
                            value={col?.categoryId}
                            sx={{
                              '&:hover': {
                                backgroundColor:
                                  'var(--secondary-background-color)',
                              },
                              '&.Mui-selected': {
                                backgroundColor:
                                  'var(--secondary-background-color)',
                                '&:hover': {
                                  backgroundColor:
                                    'var(--secondary-background-color)',
                                },
                              },
                            }}
                          >
                            {col.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}
                  <div id="subCategory" className={styles.formGroup}>
                    <p className={styles.formLabel}>
                      Subcategories (add subcategories)&nbsp;
                      <BootstrapTooltip
                        title="Add all subcategories related to your source file."
                        placement="top"
                      >
                        <HelpOutlineIcon />
                      </BootstrapTooltip>
                    </p>
                    <Select
                      value={subcategories}
                      onChange={e => {
                        setSubcategories(e.target.value);
                      }}
                      className={cx(styles.select)}
                      defaultValue={0}
                      multiple
                      sx={{
                        '.MuiSelect-select': {
                          height: 'auto !important',
                          paddingRight: '0 !important',
                          padding: '0 !important',
                        },
                        '&.Mui-focused': {
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--primary-color) !important',
                          },
                        },
                      }}
                      renderValue={selected => {
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                              width: '100%',
                            }}
                          >
                            {selected.map(value => (
                              <Chip
                                key={value}
                                label={
                                  availableSubcategories &&
                                  availableSubcategories[value] &&
                                  availableSubcategories[value].type
                                }
                                sx={{
                                  backgroundColor: 'var(--primary-color)',
                                  color: 'var(--white-color)',
                                }}
                              />
                            ))}
                          </Box>
                        );
                      }}
                    >
                      <MenuItem value={0} disabled>
                        <p className={styles.menuLabel}>Choose subcategory</p>
                      </MenuItem>
                      {availableSubcategories &&
                        Object.keys(availableSubcategories).map(col => {
                          const convertedID = parseFloat(col);
                          return (
                            <MenuItem
                              key={convertedID}
                              value={convertedID}
                              sx={{
                                '&:hover': {
                                  backgroundColor:
                                    'var(--secondary-background-color)',
                                },
                                '&.Mui-selected': {
                                  backgroundColor:
                                    'var(--secondary-background-color)',
                                  '&:hover': {
                                    backgroundColor:
                                      'var(--secondary-background-color)',
                                  },
                                },
                              }}
                            >
                              <Checkbox
                                checked={subcategories.includes(convertedID)}
                                sx={{
                                  color: 'var(--primary-color)',
                                  '.MuiSvgIcon-root': {
                                    color: 'var(--primary-color)',
                                  },
                                  '.MuiListItemText-root': {
                                    color: 'var(--primary-text-color)',
                                  },
                                }}
                              />
                              <ListItemText
                                primary={availableSubcategories[col].type}
                                sx={{
                                  color: 'var(--primary-text-color)',
                                }}
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </div>
                  <div id="contractTypeId" className={styles.formGroup}>
                    <p className={styles.formLabel}>Contract Type*&nbsp;</p>
                    <Select
                      value={contractTypeId}
                      onChange={e => {
                        setContractTypeId(e.target.value);
                      }}
                      className={cx(
                        styles.select,
                        contractTypeId === 0 &&
                          validateError.contractTypeId &&
                          styles.errorValidate
                      )}
                      defaultValue={0}
                      sx={{
                        '.MuiSelect-select': {
                          padding: '0 !important',
                        },
                        '&.Mui-focused': {
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--primary-color) !important',
                          },
                        },
                      }}
                    >
                      <MenuItem value={0} disabled>
                        <p className={styles.menuLabel}>Choose an option</p>
                      </MenuItem>
                      {contractTypes.map(col => (
                        <MenuItem
                          key={col?.contractTypeId}
                          value={col?.contractTypeId}
                          sx={{
                            '&.Mui-selected': {
                              backgroundColor:
                                'var(--secondary-background-color)',
                              '&:hover': {
                                backgroundColor: 'var(--background-color)',
                              },
                            },
                          }}
                        >
                          {col.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div id="payTokenId" className={styles.formGroup}>
                    <p className={styles.formLabel}>Choose Memecoin*&nbsp;</p>
                    <AutocompleteToken
                      tokens={payTokens}
                      value={payToken || null}
                      onSelect={value => setPayToken(value)}
                      multiple={false}
                      isError={!payToken && validateError.payTokenId}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <p className={styles.formLabel}>Community</p>
                    <div className={styles.formResult}>
                      {payToken?.community ? (
                        <>
                          <div className={styles.formIcon}>
                            <img src={payToken?.icon} alt="icon" />
                          </div>
                          <div className={styles.formName}>
                            {`${
                              payToken?.community
                                ? `${payToken?.community?.name}`
                                : ''
                            }`}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div id="tag" className={styles.formGroup}>
                    <p className={styles.formLabel}>
                      Tags&nbsp;
                      <BootstrapTooltip
                        title="Add tags to help others to find your content more easily."
                        placement="top"
                      >
                        <HelpOutlineIcon />
                      </BootstrapTooltip>
                    </p>
                    <TagsInput
                      tags={tags}
                      setTags={setTags}
                      className={cx(styles.formInput)}
                      text=""
                    />
                    <div className={styles.lengthIndicator}></div>
                  </div>
                </AccordionDetails>
              </StyledAccordion>
              <StyledAccordion
                border="1px var(--border-color) solid !important"
                titlesize="24px !important"
                sx={{ overflow: 'hidden' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  className={styles.inputGroup}
                  id="panel1-header"
                >
                  Attributes&nbsp;
                  <BootstrapTooltip
                    title="Enter all of the attributes that pertain to your source file content."
                    placement="top"
                  >
                    <HelpOutlineIcon />
                  </BootstrapTooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <div id="resolution" className={styles.formGroup}>
                    {attributes.map((item, index) => (
                      <AttributeInput
                        value={item}
                        key={index}
                        index={index}
                        inputAction={'Remove'}
                        handleInputAction={() => handleAttributeRemove(index)}
                        handleInputChange={handleAttributeChange}
                        error={
                          validateError.attributes &&
                          validateError.attributes[index]
                        }
                      />
                    ))}
                  </div>
                  <StyledButton
                    onClick={() =>
                      attributes.length >= 30 ? {} : handleAttributeAdd()
                    }
                    disabled={attributes.length >= 30}
                    margin="10px 0 0 0"
                  >
                    Add more
                  </StyledButton>
                </AccordionDetails>
              </StyledAccordion>
            </div>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={!isMobile ? 'flex-end' : 'center'}
              alignItems="center"
              sx={{
                '& .MuiButton-root': {
                  minHeight: '40px',
                },
              }}
            >
              <StyledButton
                cancel="true"
                onClick={() =>
                  updateMode
                    ? removeNftHandler(createdNftId, authToken)
                    : cancelHandler()
                }
                margin="0 5px"
                disabled={isRegistering || isSavingDraft || isCanceling}
              >
                {isCanceling ? (
                  <ClipLoader color="var(--primary-color)" size={16} />
                ) : (
                  `${updateMode ? 'Remove' : 'Cancel'}`
                )}
              </StyledButton>
              <Box
                sx={{
                  '& .MuiButton-root': {
                    minWidth: '100px',
                    minHeight: '40px',
                  },
                }}
              >
                <StyledButton
                  secondary="true"
                  onClick={() => registerNftHandler(true)}
                  margin="0 5px"
                  disabled={
                    hasWrongFormat ||
                    isSavingDraft ||
                    isRegistering ||
                    isCanceling
                  }
                >
                  {isSavingDraft ? (
                    <ClipLoader color="var(--primary-color)" size={16} />
                  ) : (
                    'Save as Draft'
                  )}
                </StyledButton>

                <StyledButton
                  onClick={() => registerNftHandler(false)}
                  margin="0 5px"
                  disabled={
                    hasWrongFormat ||
                    isSavingDraft ||
                    isRegistering ||
                    isCanceling
                  }
                  id="premint"
                >
                  {isRegistering ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : (
                    'Create'
                  )}
                </StyledButton>
              </Box>
            </Box>
            {Object.keys(validateError).length > 0 && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={!isMobile ? 'flex-end' : 'center'}
                alignItems="center"
              >
                <div className={styles.errorText}>
                  The form is not complete. Please check your entry above and
                  try again
                </div>
              </Box>
            )}
          </Grid>
          {!isMobile && (
            <div
              className={cx(
                styles.previewSticky,
                !previewInView && styles.showPreviewSticky
              )}
            >
              {renderPreviewGrid(kycRequired)}
            </div>
          )}
          {!isMobile ? (
            <Grid
              item
              xs={12}
              md={5}
              className={cx(
                styles.imageColumn,
                !previewInView && styles.previewHidden
              )}
            >
              {renderPreviewGrid(kycRequired, 'preview')}
            </Grid>
          ) : (
            <div className={styles.previewNFT}>
              <StyledButton
                width="100%"
                onClick={() => {
                  setOpenPreviewModal(prev => !prev);
                }}
              >
                {openPreviewModal ? 'Close preview' : 'Preview Your Assets'}
              </StyledButton>
            </div>
          )}
        </Grid>
        <div className={styles.ad}>Advertisement</div>
      </div>
      <FooterFS />

      <Modal
        visible={openPreviewModal || openCorruptedItemsModal}
        showCloseIcon={openCorruptedItemsModal || false}
        onClose={() => {
          if (openCorruptedItemsModal) {
            setOpenCorruptedItemsModal(false);
          }
          if (openPreviewModal) {
            setOpenPreviewModal(false);
          }
        }}
        showTitle={false}
        sx={{ top: '120px' }}
      >
        {openPreviewModal && renderPreviewGrid(kycRequired)}
        {openCorruptedItemsModal && renderCorruptedItems()}
      </Modal>
    </div>
  );
};

export default PaintBoard;
