import React from 'react';
import styles from './../styles.module.scss';
import { Grid, IconButton } from '@mui/material';
import FooterFS from 'components/Footer';
import logo from 'assets/imgs/mintifi.png';
import { useSelector } from 'react-redux';
import XIcon from '@mui/icons-material/X';
import Instagram from '@mui/icons-material/Instagram';
import Telegram from '@mui/icons-material/Telegram';

import IconDiscord from 'assets/icons/iconDiscord';
import { handleOpenPlatform } from 'utils';
import { Link } from 'react-router-dom';

const AuthGrid = ({ children }) => {
  const { authToken } = useSelector(state => state.Auth);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Grid
          container
          className={styles.inner}
          justifyContent="space-between"
          alignItems="center"
        >
          <Link to="/" className={styles.header}>
            <img src={logo} alt="logo" />
          </Link>
          <div className={styles.content}>{children}</div>
          <div className={styles.footer}>
            <div className={styles.left}>
              © 2024 Minti. All rights reserved.
            </div>
            <div className={styles.right}>
              <IconButton
                sx={{ ml: '40px !important' }}
                size="small"
                onClick={() => handleOpenPlatform('twitter')}
              >
                <XIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleOpenPlatform('instagram')}
              >
                <Instagram />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleOpenPlatform('discord')}
              >
                <IconDiscord />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleOpenPlatform('telegram')}
              >
                <Telegram />
              </IconButton>
            </div>
          </div>
        </Grid>
      </div>
      <FooterFS hideFooter={authToken !== null} />
    </div>
  );
};

export default AuthGrid;
