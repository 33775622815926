export const connectionMetaKey = 'recent_connection_meta';

function isRecentConnectionMeta(value) {
  // make sure all required fields are present
  const test = {
    type: value.type,
    walletName: value.walletName,
  };
  const isLegalTyep = Boolean(test.type);
  return isLegalTyep;
}

export function getRecentConnectionMeta() {
  const value = window.localStorage.getItem(connectionMetaKey);
  if (!value) return;

  try {
    const json = JSON.parse(value);
    if (isRecentConnectionMeta(json)) return json;
  } catch (e) {
    console.warn(e);
  }
  // If meta is invalid or there is an error, clear it from local storage.
  setRecentConnectionMeta(undefined);
  return;
}

export function setRecentConnectionMeta(meta) {
  if (!meta) return window.localStorage.removeItem(connectionMetaKey);
  window.localStorage.setItem(connectionMetaKey, JSON.stringify(meta));
}

export const useRecentConnection = () => {
  return { setRecentConnectionMeta, getRecentConnectionMeta };
};
