import React, { useState, useEffect, useRef, useCallback } from 'react';
import cx from 'classnames';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import AliceCarousel from 'react-alice-carousel';
import LazyLoader from 'components/LazyLoader';
import { isAudioFormat } from 'utils';
import { useDispatch } from 'react-redux';
import ModalActions from 'actions/modal.actions';

import styles from './styles.module.scss';
import './carousel.scss';

const index = ({
  items,
  posterUrl,
  thumbnailUrl,
  nftName,
  ownerName,
  isMobile,
  setCarouselIndex,
}) => {
  const vidRef = useRef(null);
  const dispatch = useDispatch();
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const [carouselImages, setCarouselImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  const handleSetPreviewItems = useCallback(() => {
    const previewItems = items.map((item, index) => (
      <LazyLoader
        key={index}
        url={item}
        imgStyle={styles}
        onClickCb={() => (setThumbIndex(index), setThumbAnimation(true))}
        photoCarouselModalVisible
        subImage
        vidRef={vidRef}
        posterUrl={posterUrl}
        thumbnailUrl={thumbnailUrl}
        nftName={nftName}
        ownerName={ownerName}
      />
    ));

    setPreviewImages(previewItems);
  }, [items]);

  useEffect(() => {
    const bigItems = items.map((item, index) => (
      <LazyLoader
        key={index}
        url={item}
        imgStyle={styles}
        isMobile={isMobile}
        vidRef={vidRef}
        onClickCb={() => {
          setCarouselIndex(index);

          dispatch(ModalActions.showPhotoCarouselModal());
        }}
        posterUrl={posterUrl}
        thumbnailUrl={thumbnailUrl}
        nftName={nftName}
        ownerName={ownerName}
      />
    ));
    setThumbIndex(0);
    setCarouselImages(bigItems);

    handleSetPreviewItems();
  }, [items, handleSetPreviewItems]);

  const slideNext = () => {
    if (!thumbAnimation && thumbIndex < previewImages.length - 1) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);
    }
  };

  const slidePrev = () => {
    if (!thumbAnimation && thumbIndex > 0) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex - 1);
    }
  };

  const syncThumbs = e => {
    setThumbIndex(e.item);
    setThumbAnimation(false);
  };

  return (
    <>
      {!isMobile ? (
        <div
          className={cx(
            styles.carouselContainer,
            isAudioFormat(items.at(thumbIndex) || '') && styles.audio
          )}
        >
          <div>{carouselImages[thumbIndex]}</div>

          {previewImages.length > 1 && (
            <>
              {thumbIndex > 0 && (
                <div className={styles.prevIcon} onClick={slidePrev}>
                  <ArrowBackIos />
                </div>
              )}
              {thumbIndex < previewImages.length - 1 && (
                <div className={styles.nextIcon} onClick={slideNext}>
                  <ArrowForwardIos />
                </div>
              )}
            </>
          )}
          {previewImages.length > 1 && (
            <div className={cx(styles.thumbs, 'thumbs')}>
              <AliceCarousel
                activeIndex={thumbIndex}
                autoWidth
                disableDotsControls
                disableButtonsControls
                items={previewImages}
                onSlideChanged={syncThumbs}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.carouselContainerMobile}>
          <AliceCarousel
            animationType="fadeout"
            animationDuration={800}
            disableButtonsControls
            items={carouselImages}
            mouseTracking
          />
        </div>
      )}
    </>
  );
};

export default index;
