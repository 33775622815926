import React, { useState } from 'react';
import cx from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Skeleton from 'react-loading-skeleton';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  List,
} from '@mui/material';

const renderTab = (
  label,
  Icon,
  materialIcon,
  idx,
  count,
  countLoading,
  goToTab,
  tab,
  hideCount
) => (
  <Box
    className={cx(idx === tab ? 'selected' : '')}
    onClick={() => goToTab(idx)}
    key={idx}
    sx={{
      cursor: 'pointer',
      display: 'flex',
      gap: '10px',
      padding: '10px 20px',
      margin: '0 10px',
      borderRadius: '8px',
      color: 'var(--primary-text-color)',
      '& svg': {
        ...(!materialIcon && {
          path: {
            stroke: 'var(--primary-text-color)',
          },
        }),
        ...(!materialIcon && {
          g: {
            stroke: 'var(--primary-text-color)',
          },
        }),

        color: 'var(--primary-text-color)',
      },
      '&:hover': {
        bgcolor: 'var(--secondary-background-color)',
      },
      '&.selected': {
        bgcolor: 'var(--secondary-background-color)',
      },
      '& .tab-count': {
        ml: 'auto',
        p: '0 3px',
        color: 'var(--primary-color)',
      },
    }}
  >
    <Icon />
    <div>{label}</div>
    {!hideCount && count !== null ? (
      <div className="tab-count">
        {countLoading ? <Skeleton width={40} height={22} /> : count}
      </div>
    ) : (
      <></>
    )}
  </Box>
);

const SideBar = ({ isMobile, isMe, tabs, goToTab, selectedTab } = {}) => {
  const [myItemsExpanded, setMyItemsExpanded] = useState(!isMobile);
  const [myAccountExpanded, setMyAccountExpanded] = useState(!isMobile);

  return (
    <Box
      sx={{
        mt: isMobile ? '10px' : '0',
        maxHeight: isMobile ? '350px' : 'auto',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '.no-hover': {
          '& .MuiButtonBase-root:hover': {
            bgcolor: 'inherit',
          },
        },
        '& .MuiButtonBase-root': {
          '&:hover': {
            bgcolor: 'var(--secondary-background-color)',
          },
        },
        '& .MuiPaper-root': {
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        },
        '& .MuiAccordion-root': {
          mt: 0,
          width: '100%',
        },
        '& .MuiAccordionSummary-root': {
          '& .MuiAccordionSummary-content': {
            '&.Mui-expanded': {
              margin: '16px 0 !important',
            },
            '& *': {
              fontSize: '18px',
              fontWeight: 500,
            },
          },
        },
        '& .MuiAccordionDetails-root': {
          p: 0,
        },
      }}
    >
      {/* My items */}
      <Accordion
        sx={{
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          '& .MuiList-root': {
            width: '100%',
          },
          backgroundColor: 'var(--background-color)',
          color: 'var(--primary-text-color)',
          svg: {
            color: 'var(--primary-text-color)',
          },
        }}
        className="no-hover"
        expanded={myItemsExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setMyItemsExpanded(prev => !prev)}
        >
          <Typography>{isMe ? 'My ' : 'User '}Items</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {tabs
              .filter(
                tab =>
                  tab.group === 1 && (isMe ? true : tab.idx === 'single-items')
              )
              .map(tab =>
                renderTab(
                  tab.label,
                  tab.icon,
                  tab?.materialIcon || false,
                  tab.idx,
                  tab.count,
                  tab.countLoading,
                  goToTab,
                  selectedTab
                )
              )}
          </List>
        </AccordionDetails>
      </Accordion>

      {/* My Account */}
      {isMe ? (
        <>
          <Accordion
            sx={{
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
              '& .MuiList-root': {
                width: '100%',
              },
              backgroundColor: 'var(--background-color)',
              color: 'var(--primary-text-color)',
              svg: {
                color: 'var(--primary-text-color)',
              },
            }}
            className="no-hover"
            expanded={myAccountExpanded}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => setMyAccountExpanded(prev => !prev)}
            >
              <Typography>My Account</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {tabs
                  .filter(tab => tab.group === 2)
                  .map(tab =>
                    renderTab(
                      tab.label,
                      tab.icon,
                      tab?.materialIcon || false,
                      tab.idx,
                      tab.count,
                      tab.countLoading,
                      goToTab,
                      selectedTab,
                      true
                    )
                  )}
              </List>
            </AccordionDetails>
          </Accordion>

          {/* <AccordionSummary>
        <Typography>Activity</Typography>
                    </AccordionSummary>*/}

          <AccordionSummary
            sx={{
              backgroundColor: 'var(--background-color)',
              color: 'var(--primary-text-color)',
            }}
            onClick={() => goToTab('referrals')}
          >
            <Typography>Referrals</Typography>
          </AccordionSummary>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default SideBar;
