import React, { useState, useEffect, useRef } from 'react';
import styles from './../styles.module.scss';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { useApi } from 'api';
import { useDispatch } from 'react-redux';
import Input from 'components/Input';
import AuthGrid from '../AuthGrid';
import AuthActions from 'actions/auth.actions';
import { useAppKitAccount } from '@reown/appkit/react';
import WalletConnectActions from 'actions/walletconnect.actions';
import toast from 'utils/toast';
import { StyledButton } from 'components/StyledComponents';
import CodeInput from 'components/CodeInput';
import { useDebounce } from 'use-debounce';
import { passwordRegex } from 'utils';
import PasswordInput from 'components/Input/PasswordInput';

const SignIn = ({ isRegister }) => {
  const { postLogin, check2faCode } = useApi();
  const dispatch = useDispatch();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [enteredPassword] = useDebounce(password, 500);
  const [loginError, setLoginError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [signingIn, setSigningIn] = useState(false);
  const [twoFACode, setTwoFACode] = useState('');
  const [isFACodeValid, setIsFACodeValid] = useState(true);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isCheck2fa, setIsCheck2fa] = useState(false);
  const authToken = useRef('');
  const userStorage = useRef('');
  const loginButton = useRef(null);
  const confirmButton = useRef(null);
  const onClose = () => {
    setIsCheck2fa(false);
    setLogin('');
    setPassword('');
  };
  const validateLogin = () => {
    if (login.length === 0) {
      setLoginError("Email field can't be blank");
      return false;
    } else {
      setLoginError(null);
      return true;
    }
  };
  const validatePassword = () => {
    if (password.length === 0) {
      setPasswordError("Password field can't be blank");
      return false;
    } else {
      setPasswordError(null);
      return true;
    }
  };
  const validateAll = () => {
    const login = loginError || validateLogin();
    const password = loginError || validatePassword();
    return login && password;
  };

  useEffect(() => {
    if (history.location.state && history.location.state.email) {
      setLogin(history.location.state.email);
    }
  }, [history.location.state]);

  useEffect(() => {
    if (enteredPassword.length >= 6 && passwordRegex.test(enteredPassword)) {
      handleSignIn(enteredPassword);
    }
  }, [enteredPassword]);

  const redirectNext = () => {
    const queryParams = new URLSearchParams(location.search);
    const redirectToURL = queryParams.get('redirect_to');
    if (redirectToURL) {
      window.scrollTo(0, 0);
      history.push(redirectToURL);
    } else {
      window.scrollTo(0, 0);
      history.push('/');
    }
  };

  const handleSignIn = async (_password = undefined) => {
    setSigningIn(true);
    if (!validateAll()) {
      setSigningIn(false);
      return;
    }

    if (signingIn) {
      return;
    }
    try {
      const {
        data: { message, token, user },
        errors,
      } = await postLogin(login, _password ? _password : password);
      if (errors && !_password) {
        setSigningIn(false);
        if (errors[0].message.toLowerCase().includes('password')) {
          setPasswordError(errors[0].message);
        }
        if (errors[0].message.toLowerCase().includes('email')) {
          setLoginError(errors[0].message);
        }
        return;
      }

      authToken.current = token;
      userStorage.current = user;
      if (!errors && message == 'OK') {
        window.scrollTo(0, 0);
        if (
          account &&
          user.address &&
          account.toLocaleLowerCase() !== user.address
        ) {
          dispatch(WalletConnectActions.wrongWalletError());
        }
      }
      if (!user.is2fa) {
        dispatch(AuthActions.signIn(user, token));
        history.push('/profile');
      } else {
        setIsCheck2fa(true);
      }
    } catch (e) {
      if (!_password) {
        setLoginError(true);
        setPasswordError(true);
        toast('error', 'Invalid account');
      }
      console.log(e);
    }
    setSigningIn(false);
  };
  const handleCheck2fa = async (value = '') => {
    if (twoFACode.length !== 6 && !value) return;

    const workToken = await check2faCode(
      authToken.current,
      value ? value : twoFACode
    );
    if (workToken.errors && workToken.errors.length > 0) {
      toast('error', workToken.errors[0].message);
      setTwoFACode('');
      setIsFACodeValid(false);

      return;
    } else {
      dispatch(AuthActions.signIn(userStorage.current, workToken.data));
    }
    redirectNext();
  };

  const handleForgotPassword = () => {
    window.scrollTo(0, 0);
    history.push('/auth/reset-password');
  };
  const handleSignUp = () => {
    window.scrollTo(0, 0);
    history.push('/auth/sign-up');
  };
  const { address: account } = useAppKitAccount();
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);
  useEffect(() => {
    if (isRegister) {
      window.scrollTo(0, 0);
      history.push('/');
    }
    setLogin('');
    setPassword('');
    setLoginError(null);
    setPasswordError(null);
  }, [isRegister]);
  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (isCheck2fa) {
          confirmButton.current.click();
        } else {
          loginButton.current.click();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [isCheck2fa]);
  return (
    <AuthGrid>
      <form className={styles.authForm}>
        {isCheck2fa ? (
          <>
            <div className={cx(styles.title, styles.titleCenter)}>
              2FA authentication
            </div>
            <p className={cx(styles.subTitle, styles.subTitleCenter)}>
              Thanks for keeping your account secure.
              <br />
              Enter you 2FA code
            </p>
            <div className={styles.codeInputContainer}>
              <CodeInput
                isFACodeValid={isFACodeValid}
                twoFACode={twoFACode}
                setTwoFACode={setTwoFACode}
                setIsFACodeValid={setIsFACodeValid}
                handleCheck2fa={handleCheck2fa}
              />
            </div>
            <div className={styles.buttonsWrapper}>
              <StyledButton
                className={cx(styles.submitButton)}
                onClick={handleCheck2fa}
                ref={confirmButton}
                disabled={twoFACode.length < 6 || !isFACodeValid}
              >
                Confirm
              </StyledButton>
              <div
                className={cx(styles.labelLink, styles.cancelLink)}
                onClick={onClose}
              >
                Cancel
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.title}>Log In</div>
            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <Input
                  hasError={loginError}
                  placeholder="Enter your email address"
                  value={login}
                  onChange={e => {
                    setLogin(e.target.value);
                    if (e.target.value) {
                      setLoginError(null);
                    }
                  }}
                  label="Email*"
                  onBlur={validateLogin}
                />
                {loginError && <div className={styles.error}>{loginError}</div>}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <PasswordInput
                  label="Password*"
                  hasError={passwordError}
                  placeholder="Enter your password"
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    if (e.target.value) {
                      setPasswordError(null);
                    }
                  }}
                  onBlur={validatePassword}
                />
                {passwordError && (
                  <div className={styles.error}>{passwordError}</div>
                )}
              </div>
            </div>
            <div
              className={styles.forgotPasswordLink}
              onClick={handleForgotPassword}
            >
              Forgot password?
            </div>
            <div className={styles.buttonsWrapper}>
              <StyledButton
                onClick={() => handleSignIn()}
                ref={loginButton}
                fullWidth
                disabled={signingIn}
                className={cx(
                  styles.submitButton,
                  signingIn && styles.disabled
                )}
              >
                {signingIn ? <ClipLoader color="#FFF" size={16} /> : 'Log In'}
              </StyledButton>
              <div className={styles.formLabelRow}>
                <div className={styles.label}>Don&apos;t have an account?</div>
                <div className={styles.labelLink} onClick={handleSignUp}>
                  Sign Up
                </div>
              </div>
            </div>
          </>
        )}
      </form>
    </AuthGrid>
  );
};
export default SignIn;
