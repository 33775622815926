import React, { useState, useEffect } from 'react';
import styles from './../styles.module.scss';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useApi } from 'api';
import showToast from 'utils/toast';
import letterOk from 'assets/svgs/v2/letter_ok.svg';
import AuthGrid from '../AuthGrid';
import Input from 'components/Input';
import { StyledButton } from 'components/StyledComponents';

const ConfirmAccount = ({ isRegister }) => {
  const { postConfirmAccount, resendVerificationEmail } = useApi();
  const token = useParams();
  const [message, setMessage] = useState('');
  const [isResposed, setIsResposed] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(null);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);

  const handleConfirmAccount = async () => {
    window.scrollTo(0, 0);
    history.push('/');
  };
  const handleResend = async () => {
    if (!email) {
      showToast('error', 'Please enter your email');
    } else {
      setMessage('Sending ...');
      try {
        const { message } = await resendVerificationEmail(email);
        if (message == 'OK') {
          setMessage('New Confirmation Email Has Been Sent');
          setIsSuccess(true);
          setIsResposed(true);
        } else {
          if (message) {
            showToast('error', message);
          }
          setIsSuccess(false);
          setIsResposed(true);
          setMessage('Something went wrong');
        }
      } catch (e) {
        if (e?.response?.data?.error) {
          showToast('error', e.response.data.error);
        }
        setMessage('Something went wrong');
        setIsResposed(true);
      }
    }
  };
  useEffect(() => {
    setMessage('Checking ...');
    try {
      postConfirmAccount(token.token).then(
        response => {
          if (response.data.message === 'OK') {
            setMessage('Your Email Has Been Verified');
            setIsSuccess(true);
            setIsResposed(true);
          } else {
            setIsSuccess(false);
            setIsResposed(true);
            setMessage('Something went wrong');
          }
        },
        () => {
          setIsSuccess(false);
          setIsResposed(true);
          setMessage('Something went wrong');
        }
      );
    } catch (e) {
      setMessage('Something went wrong');
      setIsResposed(true);
    }
  }, [isRegister]);

  return (
    <AuthGrid>
      <form className={styles.authForm}>
        <div className={styles.title}>{message}</div>
        {isResposed && (
          <>
            {!isSuccess ? (
              <div className={styles.subTitle}>
                Access expired. Please try again.
              </div>
            ) : null}
            {isSuccess && (
              <div className={styles.confirmIcon}>
                <img src={letterOk} />
              </div>
            )}
            {!isSuccess && (
              <Input
                placeholder="Enter your email address"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                label="Email*"
              />
            )}
            <div className={styles.buttonsWrapper}>
              {isSuccess ? (
                <StyledButton
                  className={cx(
                    styles.submitButton,
                    !isResposed && styles.disabled
                  )}
                  onClick={isResposed ? handleConfirmAccount : null}
                >
                  Ok
                </StyledButton>
              ) : (
                <>
                  <StyledButton
                    className={cx(
                      styles.submitButton,
                      !isResposed && styles.disabled
                    )}
                    onClick={isResposed ? handleResend : null}
                  >
                    Resend Verification Email
                  </StyledButton>
                </>
              )}
            </div>
          </>
        )}
      </form>
    </AuthGrid>
  );
};
export default ConfirmAccount;
