import axios from 'axios';

export const getClient = accessToken => {
  return {
    mutate: async ({ mutation, variables }) => {
      const { data } = await axios.post(
        process.env.REACT_APP_GRAPHQL_BASE_URL,
        {
          query: mutation?.loc?.source.body,
          variables,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
          },
        }
      );

      return { ...data, data: data?.data || {} };
    },
    query: async ({ query, variables }) => {
      const { data } = await axios.post(
        process.env.REACT_APP_GRAPHQL_BASE_URL,
        {
          query: query?.loc?.source.body,
          variables,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
          },
        }
      );

      return { ...data, data: data?.data || {} };
    },
  };
};
