export const ASSET_TYPES = {
  THUMBNAIL_IMAGE: 'thumbnailImage',
  WATERMARKED_THUMBNAIL_IMAGE: 'watermarkedThumbnailImage',
  FRONT_COVER: 'frontCover',
  WATERMARKED_SOURCE_FILE: 'watermarkedSourceFile',
  WATERMARKED_THUMBNAIL_VIDEO: 'watermarkedThumbnailVideo',
  THUMBNAIL_VIDEO: 'thumbnailVideo',
  ADDITIONAL_DOCUMENTS: 'additionalDocuments',
  PHOTOS: 'photos',
  ACTOR_MODEL_RELEASES: 'actorModelReleases',
  ICON: 'icon',
};
