import React, { useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FlagIcon from '@mui/icons-material/EmojiFlags';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Modal from 'components/Modal';
import { Box, FormControlLabel, RadioGroup, Stack } from '@mui/material';
import {
  StyledButton,
  StyledRadio,
  StyledTextField,
} from 'components/StyledComponents';
import { useApi } from 'api';

import styles from './styles.module.scss';

const ActionButtons = ({
  comment: commentObj,
  isOwner,
  onReport,
  onDelete,
  onEdit,
  authToken,
  onCommentSelected,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedViolation, setSelectedViolation] = useState('');
  const [isOtherViolation, setIsOtherViolation] = useState(false);
  const [customViolation, setCustomViolation] = useState('');
  const [loading, setLoading] = useState(false);
  const editFormRef = useRef(null);

  const { addComment } = useApi();

  const handleSelectedReportChange = event => {
    setSelectedViolation(event.target.value);
    setCustomViolation('');
    setIsOtherViolation(event.target.value === 'Other');
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReport = () => {
    if (isOtherViolation && !customViolation.trim()) {
      return;
    }

    onReport &&
      onReport(isOtherViolation ? customViolation.trim() : selectedViolation);

    handleCancel();
  };

  const handleDelete = () => {
    onDelete && onDelete();
    handleCancel();
  };

  const handleCancel = () => {
    setIsDeleting(false);
    setIsReporting(false);
    setIsEditing(false);
    setSelectedViolation('');
    onCommentSelected(false);
  };

  useEffect(() => {
    if (isEditing && editFormRef.current) {
      const formData = new FormData(editFormRef.current);
      formData.set('edit-comment', commentObj.comment);
    }
  }, [isEditing, commentObj.comment]);

  const handleEdit = async e => {
    e.preventDefault();

    const formData = new FormData(editFormRef.current);
    const comment = formData.get('edit-comment');

    if (comment === commentObj.comment) return;

    setLoading(true);
    const model = {
      commentId: commentObj.commentId,
      parentId: commentObj.parentId,
      nftId: commentObj.nftId,
      comment,
    };

    const { data } = await addComment(model, authToken);
    if (data) {
      // notify parent
      handleCancel();
      onEdit(model);
    }
    setLoading(false);
  };

  return (
    <div className={styles.root}>
      {isDeleting && (
        <Modal
          visible
          showCloseIcon={false}
          onClose={handleCancel}
          submitDisabled={true}
          defaultPadding={false}
          small
        >
          <Box className={styles.modal}>
            <Stack gap={1}>
              <Box className={styles.content}>
                Are you sure you want to delete this comment?
              </Box>

              <Box className={styles.buttons}>
                <StyledButton
                  sx={{ borderColor: 'white' }}
                  cancel="true"
                  onClick={handleCancel}
                >
                  Cancel
                </StyledButton>
                <StyledButton onClick={handleDelete}>Yes</StyledButton>
              </Box>
            </Stack>
          </Box>
        </Modal>
      )}

      {isReporting && (
        <Modal
          visible
          showCloseIcon={false}
          onClose={handleCancel}
          submitDisabled={true}
          defaultPadding={false}
          small
        >
          <Box className={styles.modal}>
            <Stack gap={1} className={styles.options}>
              <Box>Report Comment</Box>

              <RadioGroup
                aria-label="options"
                name="options"
                value={selectedViolation}
                onChange={handleSelectedReportChange}
              >
                <FormControlLabel
                  value="Not appropriate for underaged users"
                  control={<StyledRadio />}
                  label="Not appropriate for underaged users"
                />
                <FormControlLabel
                  value="Misleading title, thumbnail, or description"
                  control={<StyledRadio />}
                  label="Misleading title, thumbnail, or description"
                />
                <FormControlLabel
                  value="Content is in the wrong category"
                  control={<StyledRadio />}
                  label="Content is in the wrong category"
                />
                <FormControlLabel
                  value="Content infringes on copyrights"
                  control={<StyledRadio />}
                  label="Content infringes on copyrights"
                />
                <FormControlLabel
                  value="Offensive content"
                  control={<StyledRadio />}
                  label="Offensive content"
                />
                <FormControlLabel
                  value="Other"
                  control={<StyledRadio />}
                  label="Other"
                />
              </RadioGroup>

              {isOtherViolation && (
                <Box>
                  <StyledTextField
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        borderColor: 'var(--border-color)',
                        '&:hover fieldset': {
                          borderColor: 'var(--border-color)',
                        },
                        '&.Mui-focused fieldset': {
                          border: '1px solid var(--border-color)',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '10px',
                      },
                      '& .MuiInputBase-root': {
                        maxHeight: '35px',
                      },
                    }}
                    fullWidth
                    InputLabelProps={{
                      shrink: false,
                    }}
                    inputProps={{
                      maxLength: 250, // maximum input length
                    }}
                    autoComplete="off"
                    id="custom-violation-text"
                    placeholder="Tell us why"
                    name="customViolation-comment"
                    label=""
                    helperText={
                      <span className={styles.helperText}>
                        ({250 - customViolation.length}) characters left
                      </span>
                    }
                    FormHelperTextProps={{
                      sx: {
                        margin: 0,
                      },
                    }}
                    value={customViolation}
                    onChange={e => setCustomViolation(e.target.value)}
                  />
                </Box>
              )}

              <Box className={styles.buttons}>
                <StyledButton
                  sx={{ borderColor: 'white' }}
                  cancel="true"
                  onClick={handleCancel}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  disabled={!selectedViolation}
                  onClick={handleReport}
                >
                  Report
                </StyledButton>
              </Box>
            </Stack>
          </Box>
        </Modal>
      )}

      {isEditing && (
        <Modal
          visible
          showCloseIcon={false}
          onClose={handleCancel}
          submitDisabled={true}
          defaultPadding={false}
          small
        >
          <Box className={styles.modal}>
            <form onSubmit={handleEdit} ref={editFormRef}>
              <Box>Edit your Comment</Box>
              <StyledTextField
                sx={{
                  my: 2,
                  '& .MuiOutlinedInput-root': {
                    borderColor: 'var(--border-color)',
                    color: 'var(--primary-text-color)',
                    '&:hover fieldset': {
                      borderColor: 'var(--border-color)',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid var(--border-color)',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: '10px',
                    color: 'var(--primary-text-color)',
                  },
                  '& .MuiInputBase-root': {
                    maxHeight: '35px',
                  },
                }}
                fullWidth
                InputLabelProps={{
                  shrink: false,
                }}
                inputProps={{
                  maxLength: 1000, // maximum input length
                }}
                autoComplete="off"
                id="comment-text"
                placeholder="Edit your comment"
                name="edit-comment"
                label=""
                defaultValue={commentObj.comment}
              />

              <Box className={styles.buttons}>
                <StyledButton
                  sx={{ borderColor: 'white' }}
                  cancel="true"
                  onClick={handleCancel}
                >
                  Cancel
                </StyledButton>
                <StyledButton type="submit" disabled={loading}>
                  Edit
                </StyledButton>
              </Box>
            </form>
          </Box>
        </Modal>
      )}

      <IconButton
        aria-label="more"
        aria-controls="report-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        sx={{
          color: 'var(--primary-text-color)',
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="report-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!isOwner ? (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setIsReporting(true);
              onCommentSelected(true);
            }}
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              color: 'var(--primary-text-color)',
              svg: {
                color: 'var(--primary-text-color)',
              },
            }}
          >
            <FlagIcon />
            Report
          </MenuItem>
        ) : (
          [
            <MenuItem
              key="edit"
              onClick={() => {
                setAnchorEl(null);
                setIsEditing(true);
                onCommentSelected(true);
              }}
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                color: 'var(--primary-text-color)',
                svg: {
                  color: 'var(--primary-text-color)',
                },
              }}
            >
              <EditIcon />
              Edit
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                setAnchorEl(null);
                setIsDeleting(true);
                onCommentSelected(true);
              }}
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                color: 'var(--primary-text-color)',
                svg: {
                  color: 'var(--primary-text-color)',
                },
              }}
            >
              <DeleteIcon />
              Delete
            </MenuItem>,
          ]
        )}
      </Menu>
    </div>
  );
};

export default ActionButtons;
