import React from 'react';

const IconReport = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0691 3.08044H6.74792L2.98889 6.83948V12.1606L6.74792 15.9196H12.0691L15.8281 12.1606V6.83948L12.0691 3.08044ZM9.40849 13.2805C8.89492 13.2805 8.48121 12.8668 8.48121 12.3532C8.48121 11.8396 8.89492 11.4259 9.40849 11.4259C9.92206 11.4259 10.3358 11.8396 10.3358 12.3532C10.3358 12.8668 9.92206 13.2805 9.40849 13.2805ZM10.1218 10.2133H8.6952V5.9336H10.1218V10.2133Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};

export default IconReport;
