import MasonaryNFTs from 'components/MasonaryNFTs';
import React from 'react';

function WishList({ updateInteractions, userId }) {
  return (
    <MasonaryNFTs
      onlyFavorites={userId}
      onInteractionsUpdated={updateInteractions}
      avoidNullUserId
      userId={userId}
    />
  );
}

export default WishList;
