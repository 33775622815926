export const NotiConstants = {
  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  ADD_NEW_NOTIFICATION: 'ADD_NEW_NOTIFICATION',
  NOTIFICATION_VIEWED: 'NOTIFICATION_VIEWED',
  FROM_COUNT: 'FROM_COUNT',
  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',

  // asset notif
  RESET_CHECKED_ASSET: 'RESET_CHECKED_ASSET',
  ADD_CHECKED_ASSET: 'ADD_CHECKED_ASSET',
  REMOVE_CHECKED_ASSET: 'REMOVE_CHECKED_ASSET',
  GET_CHECKED_ASSET: 'GET_CHECKED_ASSET',
};
