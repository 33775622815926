import React, { useState, useMemo } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import CustomDataGrid from 'components/CustomDataGrid';

const columns = [
  {
    key: 'video',
    name: 'Video',
    formatter: () => <div style={{ textAlign: 'center' }}>🎥</div>,
    width: 50,
    frozen: true,
  },
  { key: 'views', name: 'Views' },
  { key: 'likes', name: 'Likes' },
  { key: 'dislikes', name: 'Dislikes' },
  { key: 'shares', name: 'Shares' },
  { key: 'earned', name: 'Earned' },
  { key: 'viralPower', name: 'Viral Power' },
  { key: 'published', name: 'Published', minWidth: 120 },
];

const fakeRows = [
  {
    id: 0,
    video: '',
    views: 1,
    likes: 0,
    dislikes: 0,
    shares: 0,
    earned: 0,
    viralPower: 0,
    published: '3 January 2024',
  },
  {
    id: 1,
    video: '',
    views: 0,
    likes: 0,
    dislikes: 0,
    shares: 0,
    earned: 0,
    viralPower: 0,
    published: '4 January 2024',
  },
  {
    id: 2,
    video: '',
    views: 0,
    likes: 0,
    dislikes: 0,
    shares: 0,
    earned: 0,
    viralPower: 0,
    published: '5 January 2024',
  },
  {
    id: 3,
    video: '',
    views: 0,
    likes: 0,
    dislikes: 0,
    shares: 0,
    earned: 0,
    viralPower: 0,
    published: '6 January 2024',
  },
  {
    id: 4,
    video: '',
    views: 0,
    likes: 0,
    dislikes: 0,
    shares: 0,
    earned: 0,
    viralPower: 0,
    published: '4 January 2024',
  },
  {
    id: 5,
    video: '',
    views: 0,
    likes: 0,
    dislikes: 0,
    shares: 0,
    earned: 0,
    viralPower: 0,
    published: '4 January 2024',
  },
  {
    id: 6,
    video: '',
    views: 0,
    likes: 0,
    dislikes: 0,
    shares: 0,
    earned: 0,
    viralPower: 0,
    published: '4 January 2024',
  },
];

const index = () => {
  const [sortColumns, setSortColumns] = useState([]);
  const [rows, setRows] = useState(fakeRows);

  const getComparator = () => {
    return (a, b) => (a > b ? 1 : a === b ? 0 : -1);
  };

  const sortedRows = useMemo(() => {
    if (rows.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        My Content
        <div className={styles.subTitle}>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta saepe
          aliquam commodi laudantium maxime sint incidunt qui exercitationem
          quam. Vel?
        </div>
      </div>

      <div className={styles.itemsContainer}>
        <div className={styles.items}>
          <div>Items</div>
          <div>2</div>
        </div>
        <div className={styles.items}>
          <div>Credits</div>
          <div>1</div>
        </div>
        <div className={styles.items}>
          <div>Views</div>
          <div>1</div>
        </div>
        <div className={styles.items}>
          <div>Shares</div>
          <div>2</div>
        </div>
        <div className={cx(styles.items, styles.earning)}>
          <div>Earnings</div>
          <div>$32</div>
        </div>
      </div>

      <CustomDataGrid
        mainHeader="Referral Members"
        subHeader="31 users"
        columns={columns}
        setRows={setRows}
        setSortColumns={setSortColumns}
        sortColumns={sortColumns}
        sortedRows={sortedRows}
      />
    </div>
  );
};

export default index;
