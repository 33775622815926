import { useCallback } from 'react';
import { ethers } from 'ethers';
import {
  useAppKitAccount,
  useAppKitNetwork,
  useAppKitProvider,
} from '@reown/appkit/react';

export default () => {
  const { chainId } = useAppKitNetwork();
  const { address: account } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider('eip155');

  const getContract = useCallback(
    async (address, abi) => {
      if (chainId && walletProvider) {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = await provider.getSigner();
        return new ethers.Contract(address, abi, signer);
      } else {
        const provider = new ethers.providers.JsonRpcProvider(
          process.env.REACT_APP_RPC_ENDPOINT
        );

        return new ethers.Contract(address, abi, provider);
      }
    },
    [chainId, walletProvider]
  );

  const getNonce = useCallback(async () => {
    if (chainId && walletProvider) {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      return await provider.getTransactionCount(account);
    }
  }, [chainId, walletProvider]);

  return { getContract, getNonce };
};
