import showToast from 'utils/toast';
import { useApi } from 'api';
import { NFT_STATUSES_CODES } from 'constants/NFTStatuses.constants';
import { ERC721_CONTRACT_ABI } from 'contracts';
import { useCheckGasFee } from './useCheckGasFee';
import useContract from './useContract';

export const useMint = walletAddress => {
  const { getContract } = useContract();
  const { saveTxHash, uploadIpfs } = useApi();
  const { checkGasFee } = useCheckGasFee();
  const mint = async ({ authToken, nftId }) => {
    if (!walletAddress) {
      showToast('error', 'Please connect your wallet');
      return;
    }
    const nftContractAddress = process.env.REACT_APP_ARTION;

    const contract = await getContract(nftContractAddress, ERC721_CONTRACT_ABI);

    //0
    const { data: jsonHash } = await uploadIpfs(authToken, nftId);
    if (!jsonHash) {
      showToast('error', 'Problem with saving metadata.');
      return;
    }
    //1
    const args = [walletAddress, jsonHash];

    const estimation = await contract.estimateGas.mint(...args);

    const isValid = await checkGasFee(estimation, walletAddress);

    if (!isValid) {
      return;
    }

    let tx = await contract.mint(...args);

    const txHash = tx?.hash;
    if (!txHash?.length) {
      showToast('error', 'Mint error.');
      return;
    }

    const resp = await saveTxHash(
      authToken,
      nftId,
      NFT_STATUSES_CODES.PENDING_MINTING,
      txHash
    );

    if (resp.errors) {
      console.log(resp.errors);
    }
    return true;
  };

  return { mint };
};
