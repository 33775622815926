import {
  Button,
  Accordion,
  TextField,
  styled,
  Radio,
  Badge,
  Switch,
} from '@mui/material';

export const StyledButton = styled(Button)(
  ({ cancel, margin, secondary, width, borderadius, border, error }) => {
    return {
      textTransform: 'none',
      backgroundColor: error
        ? '#fef3f2'
        : cancel || secondary
        ? 'var(--background-color)'
        : 'var(--primary-color)',
      color: error
        ? '#b42318'
        : cancel || secondary
        ? 'var(--primary-button-text-color)'
        : 'var(--white-color)',
      border: error
        ? '1px #fecdca solid'
        : border
        ? border
        : cancel
        ? '1px var(--border-color) solid'
        : '1px var(--primary-color) solid',
      borderRadius: borderadius ? borderadius : 'var(--border-radius)',
      margin,
      width,
      '&:hover': {
        color: error
          ? '#b42318 !important'
          : 'var(--primary-text-color) !important',
        backgroundColor: error
          ? '#fee4e2 !important'
          : `var(--darker-primary-color) !important`,
        border: error
          ? '1px solid #fee4e2 !important'
          : '1px solid var(--border-color) !important',
      },
      fontWeight: '700',
      '&:disabled': {
        backgroundColor: error ? '#fee4e2' : 'var(--lighter-primary-color)',
        borderColor: error ? '#fecdca' : 'var(--border-color)',
        color: error ? '#b42318' : 'var(--primary-text-color)',
      },
    };
  }
);

export const StyledAccordion = styled(Accordion)(
  ({
    border,
    borderbottom,
    titlesize,
    titleweight,
    titlecolor,
    iconcolor,
    expandcolor,
    paddingtitle,
  }) => {
    return {
      boxShadow: 'none', // this styles directly apply to accordion
      ...(border && { border: border }),
      ...(borderbottom && { borderBottom: borderbottom }),
      margin: '10px 0',
      borderRadius: 'var(--border-radius) !important',
      overflow: 'hidden',
      '&.Mui-expanded': {
        backgroundColor: expandcolor ? expandcolor : 'var(--background-color)',
        marginTop: '20px !important',
        '.MuiAccordionSummary-root': {
          backgroundColor: 'var(--background-color)',
          borderBottom: '1px var(--border-color) solid !important',
        },
      },
      backgroundColor: 'var(--third-background-color)',
      '.MuiAccordionDetails-root': {
        color: 'var(--primary-text-color)',
      },
      '.MuiAccordionSummary-root': {
        fontSize: '16px !important',
        fontWeight: '400',
        lineHeight: '120%',
        boxShadow: 'none',
        ...(paddingtitle && { padding: paddingtitle }),
        '.MuiAccordionSummary-content': {
          display: 'flex',
          alignItems: 'center',
          fontSize: titlesize ? titlesize : '24px !important',
          fontWeight: titleweight ? titleweight : '600',
          color: titlecolor ? titlecolor : 'var(--primary-text-color)',
          svg: {
            width: '20px',
            height: '20px',
            color: iconcolor ? iconcolor : '#eaecf0',
            marginRight: '10px',
          },
        },
        '.MuiAccordionSummary-expandIconWrapper': {
          color: 'var(--primary-text-color) !important',
        },
      }, // this applies to Summary
      ':before': {
        content: 'none',
      },
    };
  }
);

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(4),
  '& legend': { display: 'none' },
  '& .MuiInputBase-input': {
    padding: '10px 14px',
  },
  '& .MuiInputBase-multiline': {
    padding: 0,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    top: 0,
    borderColor: 'var(--border-color)',
  },
  '& .MuiFormLabel-root': {
    top: '-15px',
    left: '-12px',
    fontSize: '20px',
    color: 'var(--primary-text-color) !important',
  },
}));

export const StyledRadio = styled(Radio)({
  color: 'lightgray',
  height: '30px',
  '&.Mui-checked': {
    color: 'var(--primary-color)',
  },
});

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'var(--primary-color)',
    color: 'white',
  },
}));

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 28,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be',
        ...theme.applyStyles('dark', {
          backgroundColor: '#8796A5',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#001e3c',
    width: 20,
    height: 20,
    top: '40px',
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#003892',
    }),
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
    ...theme.applyStyles('dark', {
      backgroundColor: '#8796A5',
    }),
  },
}));
