import React, { useState, useEffect, useRef } from 'react';
import styles from './../styles.module.scss';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { useApi } from 'api';
import toast from 'utils/toast';
import { NavLink, useLocation } from 'react-router-dom';
import AuthGrid from '../AuthGrid';
import Input from 'components/Input';
import { StyledButton } from 'components/StyledComponents';
import { emailRex, nameRegex } from 'utils';
import PasswordChecklist from 'react-password-checklist';
import PasswordInput from 'components/Input/PasswordInput';

const SignUp = ({ isRegister }) => {
  const { postSignUp } = useApi();

  const [login, setLogin] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [signingIn, setSigningIn] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');
  const [isLoading, setIsLoading] = useState(true);
  const signupButton = useRef(null);

  const validateLogin = () => {
    if (login.length === 0) {
      setLoginError("Email field can't be blank");
      return false;
    } else {
      if (!emailRex.test(login)) {
        setLoginError('Email is invalid');
        return false;
      }
    }
    setLoginError(null);

    return true;
  };
  const validateName = () => {
    if (name.length === 0) {
      setNameError("Name field can't be blank");
      return false;
    } else {
      if (!nameRegex.test(name)) {
        setNameError(
          'No special characters allowed. Please enter a-z, A-Z, 0-9, -, _.'
        );
        return false;
      }
    }
    setNameError(null);

    return true;
  };
  const validatePassword = () => {
    if (password.length === 0) {
      setPasswordError("Password field can't be blank");
      return false;
    } else {
      setPasswordError(null);
      return true;
    }
  };
  const handleSignUp = async () => {
    if (signingIn) return;
    try {
      if (nameError || loginError || passwordError) {
        return;
      }
      const { errors } = await postSignUp(name, login, password, code);
      if (errors) {
        if (errors[0].message.toLowerCase().includes('password')) {
          setPasswordError(errors[0].message);
        }
        if (errors[0].message.toLowerCase().includes(login.toLowerCase())) {
          setLoginError(errors[0].message);
        }
        if (errors[0].message.toLowerCase().includes(name.toLowerCase())) {
          setNameError(errors[0].message);
        }
      } else {
        toast('success', 'The verification link has been sent to your email.');
        window.scrollTo(0, 0);
        history.push('/');
      }
    } catch (e) {
      if (e?.response) {
        toast('error', e?.response?.data?.error);
      }
    }

    setSigningIn(false);
  };
  const handleSignIn = () => {
    window.scrollTo(0, 0);
    history.push('/auth/sign-in');
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);
  useEffect(() => {
    if (isRegister) {
      window.scrollTo(0, 0);
      history.push('/');
    }
    setLogin('');
    setName('');
    setPassword('');
    setLoginError(null);
    setNameError(null);
    setPasswordError(null);
  }, [isRegister]);

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        signupButton.current.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const isValid = (() => {
    if (loginError) return false;
    if (nameError) return false;
    if (passwordError) return false;
    return true;
  })();
  return (
    <AuthGrid>
      <form className={styles.authForm}>
        <div className={styles.title}>Sign Up</div>
        <div className={styles.subTitle}>
          It&apos;s time to start earning with Minti
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.inputWrapper}>
            <Input
              hasError={nameError}
              placeholder="Enter your name"
              value={name}
              onChange={e => {
                setName(e.target.value);
                if (!nameRegex.test(e.target.value)) {
                  setNameError(
                    'No special characters allowed. Please enter a-z, A-Z, 0-9, -, _.'
                  );
                } else {
                  setNameError('');
                }
              }}
              label="Name*"
              onBlur={validateName}
            />
            {nameError && <div className={styles.error}>{nameError}</div>}
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.inputWrapper}>
            <Input
              hasError={loginError}
              placeholder="Enter your email address"
              value={login}
              onChange={e => {
                setLogin(e.target.value);
                if (!emailRex.test(e.target.value)) {
                  setLoginError('Email is invalid');
                } else {
                  setLoginError('');
                }
              }}
              label="Email*"
              onBlur={validateLogin}
            />
            {loginError && <div className={styles.error}>{loginError}</div>}
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.inputWrapper}>
            <PasswordInput
              label="Password*"
              hasError={false}
              placeholder="Create a password"
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
              onBlur={validatePassword}
            />

            <PasswordChecklist
              rules={['minLength', 'number', 'capitalAndLowercase']}
              minLength={6}
              value={password}
              onChange={isValid => {
                setPasswordError(isValid ? '' : 'Password is invalid');
              }}
              className={styles.passwordCheckList}
            />
          </div>
        </div>
        <div className={styles.formLabelRowCenterredGray}>
          In clicking Create Account, you are agreeing to the
          <NavLink to="/terms-of-use" className={styles.termsOfServiceLink}>
            <span
              className={styles.labelLink}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Terms of Service
            </span>
          </NavLink>
        </div>
        <div className={styles.buttonsWrapper}>
          <StyledButton
            className={cx(styles.submitButton, !isValid && styles.disabled)}
            onClick={isValid ? handleSignUp : null}
            disabled={signingIn || nameError || loginError || passwordError}
            ref={signupButton}
          >
            {signingIn ? (
              <ClipLoader color="#FFF" size={16} />
            ) : (
              'Create Account'
            )}
          </StyledButton>
          <div className={styles.formLabelRow}>
            <div className={styles.label}>Already have an account?</div>
            <div className={styles.labelLink} onClick={handleSignIn}>
              Log In
            </div>
          </div>
        </div>
      </form>
    </AuthGrid>
  );
};
export default SignUp;
