import React, { useRef } from 'react';
import audiofile from 'assets/imgs/audiofile.png';
import otherfile from 'assets/imgs/otherfile.png';
import VideoPlayer from 'pages/NFTDetails/NFT/components/VideoPlayer';
import AudioPlayer from 'pages/NFTDetails/NFT/components/AudioPlayer';
import cx from 'classnames';
import { getFileType } from 'utils/getFileType';

const LazyLoader = ({
  url,
  posterUrl,
  thumbnailUrl,
  nftName,
  ownerName,
  vidRef,
  onClickCb,
  imgStyle,
  photoCarouselModalVisible,
  subImage,
  isMobile,
}) => {
  const elementRef = useRef();

  const fileType = getFileType(url);

  return (
    <div
      ref={elementRef}
      className={cx(
        subImage ? imgStyle.subCarouselContainer : imgStyle.carouselContainer,
        isMobile && imgStyle.carouselContainerMobile
      )}
    >
      {fileType === 'image' ? (
        <img
          src={url}
          className={imgStyle.imgStyle}
          onClick={onClickCb}
          draggable="false"
          onContextMenu={e => e.preventDefault()}
        />
      ) : fileType === 'video' ? (
        <>
          <div
            className={imgStyle.videoStyle} // Disable right click
            onContextMenu={e => e.preventDefault()}
            onClick={subImage && onClickCb}
          >
            <VideoPlayer
              videoUrl={url}
              playerRef={vidRef}
              disabled={subImage}
              isMobile={isMobile}
              // posterUrl={posterUrl}
            />
          </div>
        </>
      ) : fileType === 'audio' ? (
        photoCarouselModalVisible ? (
          <img
            draggable="false"
            src={audiofile}
            className={imgStyle.imgStyle}
            onClick={onClickCb}
          />
        ) : (
          <div
            className={imgStyle.imgStyleAudio} // Disable right click
            onContextMenu={e => e.preventDefault()}
            onClick={subImage && onClickCb}
          >
            <AudioPlayer
              audioUrl={url}
              posterUrl={posterUrl}
              thumbnailUrl={thumbnailUrl}
              nftName={nftName}
              ownerName={ownerName}
            />
          </div>
        )
      ) : (
        <img
          src={otherfile}
          className={imgStyle.imgStyle}
          onClick={onClickCb}
        />
      )}
    </div>
  );
};

export default LazyLoader;
