import React from 'react';

const IconSuccess = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3049_4150)">
      <path
        d="M11 5.82314V6.28314C10.9994 7.36135 10.6503 8.41047 10.0047 9.27405C9.35908 10.1376 8.45164 10.7694 7.41768 11.0751C6.38372 11.3808 5.27863 11.3441 4.26724 10.9704C3.25584 10.5968 2.39233 9.90619 1.80548 9.00167C1.21863 8.09715 0.939896 7.02717 1.01084 5.95129C1.08178 4.87542 1.4986 3.8513 2.19914 3.03167C2.89968 2.21205 3.84639 1.64083 4.89809 1.40321C5.9498 1.16559 7.05013 1.2743 8.035 1.71314M11 2.28314L6 7.28814L4.5 5.78814"
        stroke="#039855"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3049_4150">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.283203)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconSuccess;
