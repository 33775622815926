import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import WaveSurfer from 'wavesurfer.js';
import { PlayArrow, Pause } from '@mui/icons-material';
import { StyledButton } from 'components/StyledComponents';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';
import playerActions from 'actions/player.action';

const AudioPlayerSmall = ({ audioUrl, showStatus }) => {
  const dispatch = useDispatch();
  const { audioUrl: currentlyPlaying } = useSelector(state => state.Player);
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const hoverRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    if (audioUrl !== currentlyPlaying && wavesurferRef.current) {
      wavesurferRef.current.stop();
      setIsPlaying(false);
    }
  }, [audioUrl, currentlyPlaying]);

  useEffect(() => {
    isMounted.current = true;

    if (waveformRef.current && !wavesurferRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#fefefe',
        progressColor: 'var(--primary-color)',
        barWidth: 2,
        barHeight: 0.75,
        barRadius: 4,
        cursorWidth: 0,
        height: 80,
        barGap: 1,
        url: audioUrl,
        interact: true,
      });

      wavesurferRef.current.on('play', () => {
        if (isMounted.current) {
          setIsPlaying(true);
          // set active player
          dispatch(
            playerActions.setActivePlayer({
              type: 'set_active_player',
              audioUrl,
            })
          );
        }
      });

      wavesurferRef.current.on('pause', () => {
        if (isMounted.current) {
          setIsPlaying(false);
        }
      });

      wavesurferRef.current.on('interaction', () => {
        if (isMounted.current) {
          wavesurferRef.current.play();
        }
      });

      wavesurferRef.current.on('ready', () => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });

      // looping functionality
      wavesurferRef.current.on('finish', () => {
        if (isMounted.current) {
          wavesurferRef.current.play();
        }
      });
    }

    return () => {
      isMounted.current = false;
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
        wavesurferRef.current = null;
      }
    };
  }, [audioUrl]);

  const handleHover = e => {
    if (hoverRef.current) {
      hoverRef.current.style.width = `${e.nativeEvent.offsetX}px`;
    }
  };

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
    }
  };

  return (
    <div className={cx(styles.root, showStatus && styles.larger)}>
      <div className={styles.controls}>
        <div>
          <StyledButton
            size="small"
            onClick={handlePlayPause}
            className={styles.playPauseButton}
            disabled={isLoading}
          >
            {isPlaying ? <Pause /> : <PlayArrow />}
            {isLoading && (
              <CircularProgress
                size={40}
                sx={{
                  color: 'var(--primary-color)',
                }}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-20px',
                  marginLeft: '-20px',
                }}
              />
            )}
          </StyledButton>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.wavesContainer} onPointerMove={handleHover}>
          <div ref={waveformRef} className={styles.waveform}>
            {isLoading && <div className={styles.loadingBar} />}
            <div ref={hoverRef} className={styles.hover}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayerSmall;
