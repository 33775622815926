import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import IconNotification from 'assets/icons/iconNotification';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledBadge } from 'components/StyledComponents';
import { useSelector } from 'react-redux';
import { notificationBadgeContent } from 'utils';
import MemoizedNotificationItems from './MemoizedNotificationItems';

const MemoizedMobileNotifications = ({ setOpenDrawer, onNotificationRead }) => {
  const { total: totalNotifications } = useSelector(
    state => state.Notifications
  );
  const [notificationsExpanded, setNotificationsExpanded] = useState(false);

  const toggleNotifications = () => {
    setNotificationsExpanded(prev => !prev);
  };

  return (
    <Accordion
      expanded={notificationsExpanded}
      onChange={toggleNotifications}
      sx={{
        '& .view-all': {
          pl: '12px !important',
        },
        color: 'var(--primary-text-color)',
        svg: {
          fill: 'var(--primary-text-color)',
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <ListItemIcon>
          <StyledBadge
            badgeContent={notificationBadgeContent(totalNotifications)}
            invisible={!totalNotifications}
          >
            <IconNotification />
          </StyledBadge>
        </ListItemIcon>
        <ListItemText>Notifications</ListItemText>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex !important',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 0.5,
          '& svg': { fill: 'lightgray', fontSize: '20px' },
          '& button': { p: 1.5 },
          '& .MuiMenuItem-root:not(.view-all)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            pr: 5,
            pt: '8px !important',
            pl: '20px !important',
          },
        }}
      >
        <MemoizedNotificationItems
          setOpenDrawer={setOpenDrawer}
          onNotificationRead={onNotificationRead}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(MemoizedMobileNotifications);
