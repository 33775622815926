import React from 'react';

const IconCommunity = () => {
  return (
    <svg fill="#000000" width="24" height="24" viewBox="0 0 32 32">
      <path d="M0 22.016q0 0.832 0.576 1.408t1.44 0.576h5.984v6.016q0 0.832 0.576 1.408t1.44 0.576h20q0.8 0 1.408-0.576t0.576-1.408v-20q0-0.832-0.576-1.408t-1.408-0.608h-6.016v-5.984q0-0.832-0.576-1.408t-1.408-0.608h-20q-0.832 0-1.44 0.608t-0.576 1.408v20zM4 20v-16h16v8h8v16h-16v-8h-8z"></path>
    </svg>
  );
};

export default IconCommunity;
