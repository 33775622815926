import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { CircularProgress, IconButton, Slider } from '@mui/material';
import { PlayArrow, Pause, VolumeUp, VolumeOff } from '@mui/icons-material';
import { StyledButton } from 'components/StyledComponents';
import { fixUrl } from 'utils';

import styles from './styles.module.scss';

const AudioPlayer = ({
  audioUrl,
  posterUrl,
  thumbnailUrl,
  nftName,
  ownerName,
}) => {
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const hoverRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState('0:00');
  const [duration, setDuration] = useState('0:00');
  const [isLoading, setIsLoading] = useState(true);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    if (waveformRef.current && !wavesurferRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#eeeeee',
        progressColor: '#b08dff',
        barWidth: 2,
        barHeight: 0.75,
        barRadius: 4,
        cursorWidth: 0,
        height: 80,
        barGap: 1,
        url: audioUrl,
        backend: 'WebAudio',
      });

      wavesurferRef.current.on('play', () => setIsPlaying(true));
      wavesurferRef.current.on('pause', () => setIsPlaying(false));

      wavesurferRef.current.on('interaction', () => {
        wavesurferRef.current.play();
      });

      wavesurferRef.current.on('ready', () => {
        setDuration(formatTime(wavesurferRef.current.getDuration()));
        setIsLoading(false);
      });

      wavesurferRef.current.on('audioprocess', () => {
        setCurrentTime(formatTime(wavesurferRef.current.getCurrentTime()));
      });

      // looping functionality
      wavesurferRef.current.on('finish', () => {
        wavesurferRef.current.play();
      });
    }

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [audioUrl]);

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemainder = Math.round(seconds) % 60;
    const paddedSeconds = `0${secondsRemainder}`.slice(-2);
    return `${minutes}:${paddedSeconds}`;
  };

  const handleHover = e => {
    if (hoverRef.current) {
      hoverRef.current.style.width = `${e.nativeEvent.offsetX}px`;
    }
  };

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
    }
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    setIsMuted(newValue === 0);
    if (wavesurferRef.current) {
      wavesurferRef.current.setVolume(newValue);
      wavesurferRef.current.setMuted(newValue === 0);
    }
  };

  const handleMuteToggle = () => {
    const newMutedState = !isMuted;
    setIsMuted(newMutedState);
    if (wavesurferRef.current) {
      wavesurferRef.current.setMuted(newMutedState);
      if (!newMutedState) {
        wavesurferRef.current.setVolume(volume);
      }
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.topBar}>
        <div
          className={styles.imgContainer}
          style={{
            backgroundImage: `url(${fixUrl(thumbnailUrl)})`,
          }}
        ></div>
        <div className={styles.infoContainer}>
          <div>{nftName}</div>
          <div>{ownerName}</div>
        </div>
      </div>
      <div
        className={styles.poster}
        onPointerMove={e => e.preventDefault()}
        style={{
          backgroundImage: `url(${fixUrl(posterUrl)})`,
        }}
      ></div>
      <div className={styles.posterOverlay}></div>
      <div className={styles.container}>
        <span id="time" className={styles.time}>
          {currentTime}
        </span>
        <div className={styles.wavesContainer} onPointerMove={handleHover}>
          <div id="waveform" ref={waveformRef} className={styles.waveform}>
            {isLoading && <div className={styles.loadingBar} />}
            <div ref={hoverRef} className={styles.hover}></div>
          </div>
        </div>
        <span id="duration" className={styles.duration}>
          {duration}
        </span>
      </div>
      <div className={styles.controls}>
        <div>&nbsp;</div>
        <div>
          <StyledButton
            size="small"
            onClick={handlePlayPause}
            className={styles.playPauseButton}
            disabled={isLoading}
          >
            {isPlaying ? <Pause /> : <PlayArrow />}
            {isLoading && (
              <CircularProgress
                size={50}
                sx={{
                  color: 'var(--primary-color)',
                }}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-25px',
                  marginLeft: '-25px',
                }}
              />
            )}
          </StyledButton>
        </div>
        <div>
          <div className={styles.volumeControl}>
            <IconButton size="small" onClick={handleMuteToggle}>
              {isMuted ? <VolumeOff /> : <VolumeUp />}
            </IconButton>
            <Slider
              value={volume}
              onChange={handleVolumeChange}
              aria-labelledby="volume-slider"
              min={0}
              max={1}
              step={0.01}
              className={styles.volumeSlider}
              sx={{
                color: isMuted ? '#b5b5b5' : 'white',
                '& .MuiSlider-thumb': {
                  opacity: 0,
                  color: isMuted ? '#b5b5b5' : 'white',
                  boxShadow: isMuted
                    ? '0 0 0 0 rgba(20, 20, 20, 0.16)'
                    : '0 0 0 0 rgba(255, 255, 255, 0.16)',
                  '&:hover': {
                    opacity: 1,
                    boxShadow: '0 0 0 4px rgba(255, 255, 255, 0.2)',
                    width: 16,
                    height: 16,
                  },
                },
                '& .MuiSlider-rail': {
                  color: 'rgba(255, 255, 255, 0.3)',
                },
                '& .MuiSlider-track': {
                  color: isMuted ? '#b5b5b5' : 'white',
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
