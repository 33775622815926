import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import Modal from '@mui/material/Modal';
import CreateIcon from '@mui/icons-material/Create';
import { CropImageModal } from 'components/CropImageModal';
import ModalActions from 'actions/modal.actions';
import AuthActions from 'actions/auth.actions';
import { useApi } from 'api';
import toast from 'utils/toast';
import styles from './styles.module.scss';
import { dataUrlToFile } from 'utils';

const AccountModal = () => {
  const { postUpdateUser, uploadUserFile } = useApi();
  const dispatch = useDispatch();

  const { fetching, user, authToken } = useSelector(state => state.Auth);

  const rootRef = useRef(null);
  const inputRef = useRef(null);

  const [website, setWebsite] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [bio, setBio] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarName, setAvatarName] = useState('');
  const [originAvatar, setOriginAvatar] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [saving, setSaving] = useState(false);
  const { accountModalVisible } = useSelector(state => state.Modal);

  useEffect(() => {
    if (accountModalVisible) {
      if (user.avatar) {
        setAvatar(user.avatar);
      } else {
        setAvatar(null);
      }
      setWebsite(user.website || '');
      setInstagram(user.instagram || '');
      setTwitter(user.twitter || '');
      setName(user.name || '');
      setEmail(user.email || '');
      setBio(user.bio || '');
      setEmailError(null);
    }
  }, [accountModalVisible]);

  const handleFileSelect = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = function(e) {
        setOriginAvatar(e.target.result);
      };
      setAvatarName(file.name);
      reader.readAsDataURL(file);
    }
  };

  const isValid = emailError === null;

  const closeModal = () => {
    dispatch(ModalActions.hideAccountModal());
  };

  const clipImage = (image, clipX, clipY, clipWidth, clipHeight, cb) => {
    const CANVAS_SIZE = 128;
    const canvas = document.createElement('canvas');
    canvas.width = CANVAS_SIZE;
    canvas.height = CANVAS_SIZE;
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      image,
      clipX,
      clipY,
      clipWidth,
      clipHeight,
      0,
      0,
      CANVAS_SIZE,
      CANVAS_SIZE
    );
    cb(canvas.toDataURL());
  };

  const onSave = async () => {
    if (saving) return;

    try {
      setSaving(true);
      if (!avatar || avatar.startsWith('https')) {
        const { data, errors } = await postUpdateUser(authToken, {
          name,
          email,
          bio,
          website: website || null,
          instagram: instagram || null,
          twitter: twitter || null,
        });
        if (Object.keys(data).length > 0) {
          dispatch(AuthActions.fetchSuccess(data));
          toast('success', 'Account details saved!');
        } else {
          if (errors && errors.length > 0) {
            toast('error', errors[0].message);
          } else {
            toast('error', 'Something went wrong when updating user');
          }
        }
        setSaving(false);

        closeModal();
      } else {
        const img = new Image();
        img.onload = function() {
          const w = this.width;
          const h = this.height;
          const size = Math.min(w, h);
          const x = (w - size) / 2;
          const y = (h - size) / 2;
          clipImage(img, x, y, size, size, async imgData => {
            try {
              const file = dataUrlToFile(imgData, avatarName);
              file.path = avatarName;
              if (avatarName) {
                await uploadUserFile(authToken, 'avatar', file);
              }
              const { data } = await postUpdateUser(authToken, {
                name,
                email,
                bio,
              });
              if (Object.keys(data).length > 0) {
                dispatch(AuthActions.fetchSuccess(data));
                toast('success', 'Account details saved!');
              } else {
                toast('error', 'Something went wrong when updating user');
              }
              setSaving(false);

              closeModal();
            } catch (error) {
              console.log(error);
              toast('error', error);
            }
          });
        };
        img.src = avatar;
      }
    } catch {
      setSaving(false);
    }
  };

  const onCancel = () => {
    closeModal();
  };

  if (!accountModalVisible) return null;

  return (
    <div className={styles.root} ref={rootRef}>
      <Modal open className={styles.modal} container={() => rootRef.current}>
        <div className={styles.paper}>
          <h2 className={styles.title}>Edit Profile</h2>

          <div className={styles.content}>
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>Your Photo</p>
              <p className={styles.formDesc}>
                Edit your profile account picture that is displayed
              </p>
              <input
                ref={inputRef}
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileSelect}
              />
              <div className={styles.avatarBox}>
                {avatar && (
                  <img
                    src={
                      avatar.includes('mypinata')
                        ? `${avatar}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                        : avatar
                    }
                    className={styles.avatar}
                  />
                )}
                <div
                  className={styles.upload}
                  onClick={() => !fetching && inputRef.current?.click()}
                >
                  <CreateIcon className={styles.uploadIcon} />
                </div>
              </div>
            </div>
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>Social Media</p>
              <p className={styles.formDesc}>Edit your social media details</p>

              <p className={styles.formLabel}>Website</p>
              <input
                type="text"
                className={styles.formInput}
                placeholder="Enter your Website"
                onChange={e => setWebsite(e.target.value)}
                value={website}
              />

              <p className={styles.formLabel}>Instagram</p>
              <input
                type="text"
                className={styles.formInput}
                placeholder="Enter your Instagram"
                value={instagram}
                onChange={e => setInstagram(e.target.value)}
              />

              <p className={styles.formLabel}>X</p>
              <input
                type="text"
                className={styles.formInput}
                placeholder="Enter your X"
                value={twitter}
                onChange={e => setTwitter(e.target.value)}
              />
            </div>
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>Details</p>
              <p className={styles.formDesc}>
                Edit your account profile details that are displayed
              </p>

              <p className={styles.formLabel}>Email Address</p>
              <input
                type="text"
                className={cx(
                  styles.formInput,
                  emailError !== null ? styles.hasError : null
                )}
                placeholder="Enter Email Address"
                value={email}
                disabled={true}
                readOnly
              />
              {emailError !== null && (
                <p className={styles.error}>{emailError}</p>
              )}

              <p className={styles.formLabel}>Profile name</p>
              <input
                type="text"
                className={styles.formInput}
                maxLength={20}
                placeholder="Enter your name"
                value={name}
                onChange={e => setName(e.target.value)}
                disabled={fetching}
              />
              <div className={styles.lengthIndicator}>{name.length}/20</div>

              <p className={styles.formLabel}>Bio</p>
              <textarea
                className={cx(styles.formInput, styles.longInput)}
                maxLength={275}
                placeholder="Enter your bio"
                value={bio}
                onChange={e => setBio(e.target.value)}
                disabled={fetching}
              />
              <div className={styles.lengthIndicator}>{bio.length}/275</div>
            </div>
          </div>
          <div className={styles.footer}>
            <div
              className={cx(
                styles.button,
                styles.cancel,
                saving && styles.disabled
              )}
              onClick={!saving ? onCancel : null}
            >
              Cancel
            </div>

            <div
              className={cx(
                styles.button,
                styles.save,
                (saving || !isValid) && styles.disabled
              )}
              onClick={isValid ? onSave : null}
            >
              {saving ? <ClipLoader color="#FFF" size={16} /> : 'Confirm'}
            </div>
          </div>
        </div>
      </Modal>
      <CropImageModal
        originAvatar={originAvatar}
        setAvatar={setAvatar}
        setOriginAvatar={setOriginAvatar}
      />
    </div>
  );
};

export default AccountModal;
