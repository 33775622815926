import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import styles from './style.module.scss';
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import showToast from 'utils/toast';
import { saveAs } from 'file-saver';
import { formatError, getFileExtension } from 'utils';
const JSZip = require('jszip');

const DownloadModal = ({ visible, onClose, downloadFiles, nftName }) => {
  const [files, setFiles] = useState([]);
  const [zip, setZip] = useState(new JSZip());

  const resetState = () => {
    setZip(new JSZip());
  };

  const startDownloading = downloadFiles => {
    let currentFile = 0;
    downloadFiles.forEach(file => {
      const filename = file.fileName;
      axios({
        url: file.signedURL,
        method: 'get',
        responseType: 'blob',
        onDownloadProgress(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // Create copy of file
          let newFile = file;
          // ... and set the progress
          newFile.progress = percentCompleted;
          // Create copy of files array
          let filesCopy = [...downloadFiles];
          // Find the index of the file object
          let fileIndex = filesCopy.findIndex(
            el => el.fileName === file.fileName
          );
          //... and replace the object with the new one containing the [progress] key
          filesCopy[fileIndex] = newFile;
          // Finally, update the state
          setFiles([...filesCopy]);
          file.progress = percentCompleted;
        },
      })
        .then(response => {
          if (downloadFiles.length === 1) {
            saveAs(
              new Blob([response.data], {
                type: 'application/octet-stream',
              }),
              `${nftName}.${getFileExtension(filename, response.headers)}`
            );

            onClose();
          } else {
            zip.file(
              filename,
              new Blob([response.data], {
                type: 'application/octet-stream',
              })
            );
            if (currentFile === downloadFiles.length - 1) {
              zip.generateAsync({ type: 'blob' }).then(function(content) {
                saveAs(content, `${nftName}.zip`);
                onClose();
                resetState();
                setFiles([]);
              });
            } else {
              currentFile = currentFile + 1;
            }
          }
        })
        .catch(error => {
          console.log('err', error);
          const errorMessage =
            'Something wrong happened trying to download the file.';
          showToast('error', formatError({ data: { message: errorMessage } }));
          onClose();
        });
    });
  };
  useEffect(() => {
    startDownloading(downloadFiles);
  }, [downloadFiles]);

  return (
    <Modal
      visible={visible}
      title={`Download ${nftName}`}
      onClose={() => {}}
      showCloseIcon={false}
    >
      {files.map((item, index) => (
        <DownloadItem
          fileName={item.fileName}
          progress={item.progress}
          key={index}
        />
      ))}
    </Modal>
  );
};

const DownloadItem = ({ fileName, progress }) => {
  return (
    <>
      <div className={styles.title}>
        {progress === 100 ? 'Downloaded' : 'Downloading'} -{' '}
        <span>{fileName}</span>
      </div>
      <LinearProgress
        variant="determinate"
        value={progress}
        className={styles.progress}
      />

      <div className={styles.percentage}>{progress}%</div>
    </>
  );
};
export default DownloadModal;
