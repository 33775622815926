import React, { useState, useEffect } from 'react';
import styles from './../styles.module.scss';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { useApi } from 'api';
import toast from 'utils/toast';
import AuthGrid from '../AuthGrid';
import Input from 'components/Input';
import { StyledButton } from 'components/StyledComponents';

const ResetPassword = ({ isRegister }) => {
  const { postResetPassword } = useApi();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [reseting, setReseting] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const onClose = () => {
    window.scrollTo(0, 0);
    history.push('/auth/sign-in');
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);

  const validateEmail = () => {
    if (email.length === 0) {
      setEmailError("Email field can't be blank");
    } else {
      setEmailError(null);
    }
  };
  const handleResetPassword = async () => {
    if (reseting) return;

    setReseting(true);
    const response = await postResetPassword(email);
    setReseting(false);
    if (response?.error) {
      toast('error', response?.error);
    } else {
      toast('success', 'Reset password email has been successfully sent');
      window.scrollTo(0, 0);
      history.push('/');
    }
  };
  useEffect(() => {
    setEmail('');
    setEmailError(null);
    /*
    if (isRegister) {
      // Log out
    }
    */
  }, [isRegister]);
  const isValid = (() => {
    if (emailError) return false;
    return true;
  })();
  return (
    <AuthGrid>
      <form className={styles.authForm}>
        <div className={styles.title}>Password Recovery</div>
        <div className={styles.subTitle}>
          No worries, you just need to type your email address and we will send
          you recovery email.
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.inputWrapper}>
            <Input
              hasError={emailError}
              placeholder="Enter your email address"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              label=""
              onBlur={validateEmail}
            />
            {emailError && <div className={styles.error}>{emailError}</div>}
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <StyledButton
            className={cx(styles.submitButton, !isValid && styles.disabled)}
            onClick={isValid ? handleResetPassword : null}
            fullWidth
            disabled={reseting}
          >
            {reseting ? <ClipLoader color="#FFF" size={16} /> : 'Send'}
          </StyledButton>
          <div className={styles.formLabelRow}>
            <div className={styles.labelLink} onClick={onClose}>
              Cancel
            </div>
          </div>
        </div>
      </form>
    </AuthGrid>
  );
};
export default ResetPassword;
