import { gql } from '@apollo/client';
import axios from 'axios';
import { getClient } from '../utils/graphqlClient';
import { getResponseData } from 'utils';
import {
  CORE_COMMENT_FIELDS,
  CORE_NFT_FIELDS,
  CORE_TRADE_HISTORY_FIELDS,
  CORE_USER_FIELDS,
  CORE_OFFER_FIELDS,
} from './fragments';
import { ASSET_TYPES } from 'constants/asset.constants';

axios.interceptors.response.use(
  config => {
    // check graphql unauthorized call
    const unauthorized = config.data?.errors?.some(
      e => e?.extensions?.originalError?.statusCode === 401
    );

    if (unauthorized) console.log('unauthorized');

    return config;
  },
  error => {
    return {
      status: error?.response?.status,
      error: error?.response?.data?.error,
    };
  }
);

export const useApi = () => {
  const explorerUrl = 'https://testnet.avascan.info/';

  const storageUrl = 'https://storage.artion.io';
  const postLogin = async (login, password) => {
    const res = await getClient().mutate({
      mutation: gql`
        ${CORE_USER_FIELDS}
        mutation userLogin($input: UserLoginDTO!) {
          userLogin(input: $input) {
            message
            token
            user {
              ...CoreUserFields
            }
          }
        }
      `,
      variables: {
        input: {
          email: login,
          password,
        },
      },
    });

    return getResponseData(res, 'userLogin');
  };

  const postSignUp = async (name, login, password, code = undefined) => {
    const res = await getClient().mutate({
      mutation: gql`
        ${CORE_USER_FIELDS}
        mutation userSignup($input: UserSignupDTO!) {
          userSignup(input: $input) {
            message
            user {
              ...CoreUserFields
            }
          }
        }
      `,
      variables: {
        input: {
          email: login,
          password,
          name,
          ...(code && { referrerCode: code }),
        },
      },
    });

    return getResponseData(res, 'userSignup');
  };

  const resendVerificationEmail = async email => {
    const res = await getClient().mutate({
      mutation: gql`
        mutation userResendVerificationEmail($input: UserEmailDTO!) {
          userResendVerificationEmail(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          email,
        },
      },
    });

    return getResponseData(res, 'userResendVerificationEmail');
  };

  const postConfirmAccount = async confirmToken => {
    const res = await getClient().mutate({
      mutation: gql`
        mutation userConfirmEmail($input: String!) {
          userConfirmEmail(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: confirmToken,
      },
    });

    return getResponseData(res, 'userConfirmEmail');
  };

  const postResetPassword = async email => {
    const res = await getClient().mutate({
      mutation: gql`
        mutation userResetPassword($input: UserEmailDTO!) {
          userResetPassword(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          email,
        },
      },
    });

    return getResponseData(res, 'userResetPassword');
  };

  const checkPassword = async (password, authToken) => {
    const res = await getClient().query({
      query: gql`
        query userCheckPassword($input: UserChangePassDTO!) {
          userCheckPassword(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          token: authToken,
          password,
        },
      },
    });

    return getResponseData(res, 'userCheckPassword');
  };

  const getQuestionTypes = async () => {
    const res = await getClient().query({
      query: gql`
        query getQuestionTypes {
          getQuestionTypes {
            questionTypeId
            type
          }
        }
      `,
    });
    return getResponseData(res, 'getQuestionTypes');
  };

  const getFAQs = async () => {
    const res = await getClient().query({
      query: gql`
        query getFAQs {
          getFAQs {
            qaId
            questionTypeId
            question
            answer
          }
        }
      `,
    });

    return getResponseData(res, 'getFAQs');
  };
  const postSetNewPassword = async (password, authToken) => {
    const res = await getClient().mutate({
      mutation: gql`
        mutation userAcceptPassword($input: UserChangePassDTO!) {
          userAcceptPassword(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          token: authToken,
          password,
        },
      },
    });

    return getResponseData(res, 'userAcceptPassword');
  };

  // admin
  const postWhitelistUser = async (token, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation whitelistUser($input: WhitelistUserDTO!) {
          whitelistUser(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          token,
        },
      },
    });

    return getResponseData(res, 'whitelistUser');
  };

  // admin
  const rejectWhitelistUser = async (token, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation rejectUserWhitelistRequest(
          $input: RejectUserWhitelistRequestDTO!
        ) {
          rejectUserWhitelistRequest(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          token,
        },
      },
    });

    return getResponseData(res, 'rejectUserWhitelistRequest');
  };

  // admin
  const rejectWhitelistUserByAdmin = async (
    whitelistRequestId,
    userId,
    reason,
    authToken
  ) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation rejectUserWhitelistRequestByAdmin(
          $input: RejectUserWhitelistRequestByAdminDTO!
        ) {
          rejectUserWhitelistRequestByAdmin(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          whitelistRequestId,
          userId,
          reason,
        },
      },
    });

    return getResponseData(res, 'rejectUserWhitelistRequestByAdmin');
  };

  const verifyWallet = async (signature, address, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation verifyMetamask($input: VerifyMetamaskDTO!) {
          verifyMetamask(input: $input) {
            result
            message
          }
        }
      `,
      variables: {
        input: {
          address,
          signature,
        },
      },
    });

    return getResponseData(res, 'verifyMetamask');
  };

  const getKYCFee = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getKYCFee {
          getKYCFee
        }
      `,
    });
    return getResponseData(res, 'getKYCFee');
  };

  const getKYCTxHash = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getKYCTxHash {
          getKYCTxHash
        }
      `,
    });
    return getResponseData(res, 'getKYCTxHash');
  };

  const getSumsubToken = async (authToken, txHash) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getSumSubToken($input: String!) {
          getSumSubToken(input: $input) {
            message
            data
          }
        }
      `,
      variables: {
        input: txHash,
      },
    });
    return getResponseData(res, 'getSumSubToken');
  };

  // TODO: expect to have website, instagram and twitter
  const postUpdateUser = async (authToken, userData) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        ${CORE_USER_FIELDS}
        mutation userUpdateAccount($input: UserUpdateAccountDTO!) {
          userUpdateAccount(input: $input) {
            ...CoreUserFields
          }
        }
      `,
      variables: {
        input: {
          name: userData.name,
          email: userData.email,
          bio: userData.bio,
          website: userData.website,
          instagram: userData.instagram,
          twitter: userData.twitter,
        },
      },
    });

    return getResponseData(res, 'userUpdateAccount');
  };

  // user request
  const createShare = async (nftId, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation createShare($input: String!) {
          createShare(source_id: $input)
        }
      `,
      variables: {
        input: nftId,
      },
    });
    return getResponseData(res, 'createShare');
  };

  const redeemShare = async (nftId, code, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation redeemShare($input: RedeemShareDTO!) {
          redeemShare(input: $input) {
            paidShare
          }
        }
      `,
      variables: {
        input: {
          sourceId: nftId,
          code,
        },
      },
    });
    return getResponseData(res, 'redeemShare');
  };

  const getTotalShare = async sourceId => {
    const res = await getClient().query({
      query: gql`
        query getTotalShare($input: InputUUID!) {
          getTotalShare(input: $input)
        }
      `,
      variables: {
        input: {
          id: sourceId,
        },
      },
    });

    return getResponseData(res, 'getTotalShare');
  };

  // user request
  const requestWhitelisted = async (description, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation userWhitelistRequest($input: UserWhitelistRequestDTO!) {
          userWhitelistRequest(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          description,
        },
      },
    });

    return getResponseData(res, 'userWhitelistRequest');
  };

  const getUserNetworkInfo = async walletAddress => {
    const res = await getClient().query({
      query: gql`
        query getUserNetworkInfo($input: WalletAddressDTO!) {
          getUserNetworkInfo(input: $input) {
            followings
            followers
          }
        }
      `,
      variables: {
        input: {
          address: walletAddress,
        },
      },
    });

    return getResponseData(res, 'getUserNetworkInfo');
  };

  const getUserProfile = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        ${CORE_USER_FIELDS}
        query getUserProfile {
          getUserProfile {
            ...CoreUserFields
          }
        }
      `,
    });
    return getResponseData(res, 'getUserProfile');
  };

  const checkUserToken = async token => {
    const res = await getClient().query({
      query: gql`
        query checkUserToken($token: String!) {
          checkUserToken(token: $token)
        }
      `,
      variables: {
        token,
      },
    });

    return getResponseData(res, 'checkUserToken');
  };

  const getUserAccountDetails = async (address, authToken) => {
    const res = await getClient(authToken).query({
      query: gql`
        ${CORE_USER_FIELDS}
        query getUserAccountDetails($input: WalletAddressDTO!) {
          getUserAccountDetails(input: $input) {
            ...CoreUserFields
          }
        }
      `,
      variables: {
        input: {
          address,
        },
      },
    });

    return getResponseData(res, 'getUserAccountDetails');
  };

  const fetchNfts = async (
    authToken,
    {
      from,
      count,
      sortby = 'createdAt',
      searchKey,
      contractTypeId,
      statusFilter,
      categoryIdList,
      payTokenIdList,
      communityIdList,
      userId,
      onlyFavorites,
      onlyLikes,
      onlyMinted,
      statusIdList,
      exceptNftId,
    }
  ) => {
    const res = await getClient(authToken).query({
      query: gql`
        ${CORE_NFT_FIELDS}
        query fetchNfts($input: fetchNftDTO!) {
          fetchNfts(input: $input) {
            nfts {
              ...CoreNftFields
            }
            total
          }
        }
      `,
      variables: {
        input: {
          sortby,
          from,
          count,
          searchKey,
          contractTypeId,
          statusFilter,
          categoryIdList,
          payTokenIdList,
          communityIdList,
          userId,
          onlyFavorites,
          onlyLikes,
          onlyMinted,
          statusIdList,
          exceptNftId,
        },
      },
    });
    return getResponseData(res, 'fetchNfts');
  };

  const searchNames = async text => {
    const res = await getClient().query({
      query: gql`
        query searchNames($input: SearchNamesDTO!) {
          searchNames(input: $input) {
            nfts {
              nftId
              name
              userId
              artistId
              categoryId
              nftContractId
              contractTypeId
              contractAddress
              nftAssets {
                assetId
                url
              }
              tags {
                tagId
                name
              }
              category {
                categoryId
                type
              }
            }
            users {
              userId
              name
              avatar
              address
              email
            }
          }
        }
      `,
      variables: {
        input: {
          search: text,
        },
      },
    });

    return getResponseData(res, 'searchNames');
  };

  const getTransferSignature = async (authToken, amount, nonce) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getTransferSignature($input: SignMessageDTO!) {
          getTransferSignature(input: $input)
        }
      `,
      variables: {
        input: {
          amount,
          nonce,
        },
      },
    });
    return getResponseData(res, 'getTransferSignature');
  };

  const getMonthlyTotalRewardHistories = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getMonthlyTotalRewardHistories {
          getMonthlyTotalRewardHistories
        }
      `,
    });

    return getResponseData(res, 'getMonthlyTotalRewardHistories');
  };

  const getRewardHistory = async (authToken, period) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getRewardTradeHistories($period: String!) {
          getRewardTradeHistories(period: $period) {
            pointEarned {
              amount
              pointType
            }
            totalPointEarned {
              date
              count
            }
            lifetimePointEarned {
              date
              count
            }
            lifetimeGrowth
            totalGrowth
          }
        }
      `,
      variables: {
        period,
      },
    });

    return getResponseData(res, 'getRewardTradeHistories');
  };

  const getReceivedOffers = async (authToken, period) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getReceivedOffers($period: String!) {
          getReceivedOffers(period: $period) {
            offers {
              offerId
              nftId
              nft {
                nftId
                name
                nftContractId
                nftAssets {
                  assetId
                  url
                  assetTypeId
                  assetType {
                    name
                  }
                }
                payToken {
                  symbol
                }
              }
              creatorId
              creator {
                userId
                name
                avatar
                userWallets {
                  address
                }
              }
              price
              blockNumber
              paymentToken
              deadline
              isExpired
              createdAt
            }
            totalOffers {
              date
              count
            }
            lifetimeOffers {
              date
              count
            }
            lifetimeGrowth
            totalGrowth
          }
        }
      `,
      variables: {
        period,
      },
    });

    return getResponseData(res, 'getReceivedOffers');
  };

  const getRefferals = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getUserReferrals {
          getUserReferrals {
            email
            isActive
            isEmailConfirmed
            name
            avatar
            bonusPaid
            userId
            createdAt
          }
        }
      `,
    });
    return getResponseData(res, 'getUserReferrals');
  };

  const sendReferralEmail = async (authToken, email) => {
    const res = await getClient(authToken).query({
      query: gql`
        query sendReferralEmail($email: String!) {
          sendReferralEmail(email: $email)
        }
      `,
      variables: {
        email,
      },
    });
    return getResponseData(res, 'sendReferralEmail');
  };

  const getSentOffers = async (authToken, period) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getSentOffers($period: String!) {
          getSentOffers(period: $period) {
            offers {
              offerId
              nftId
              nft {
                nftId
                name
                nftContractId
                nftAssets {
                  assetId
                  url
                  assetTypeId
                  assetType {
                    name
                  }
                }
                payToken {
                  symbol
                }
                userId
              }
              creatorId
              creator {
                userId
                name
                avatar
              }
              price
              blockNumber
              paymentToken
              deadline
              isExpired
              createdAt
            }
            totalOffers {
              date
              count
            }
            lifetimeOffers {
              date
              count
            }
            lifetimeGrowth
            totalGrowth
          }
        }
      `,
      variables: {
        period,
      },
    });

    return getResponseData(res, 'getSentOffers');
  };

  const getReceivedBids = async (authToken, period) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getReceivedBids($period: String!) {
          getReceivedBids(period: $period) {
            bids {
              bidId
              nftId
              nft {
                nftId
                name
                nftContractId
                nftAssets {
                  assetId
                  url
                  assetTypeId
                  assetType {
                    name
                  }
                }
              }
              bidderId
              bidder {
                userId
                name
                avatar
              }
              bid
              blockNumber
              paymentToken
              auctionActive
              withdrawn
              createdAt
            }
            totalBids {
              date
              count
            }
            lifetimeBids {
              date
              count
            }
            lifetimeGrowth
            totalGrowth
          }
        }
      `,
      variables: {
        period,
      },
    });

    return getResponseData(res, 'getReceivedBids');
  };

  const getPlacedBids = async (authToken, period) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getPlacedBids($period: String!) {
          getPlacedBids(period: $period) {
            bids {
              bidId
              nftId
              nft {
                nftId
                name
                nftContractId
                nftAssets {
                  assetId
                  url
                  assetTypeId
                  assetType {
                    name
                  }
                }
              }
              bidderId
              bidder {
                userId
                name
                avatar
              }
              bid
              blockNumber
              paymentToken
              auctionActive
              withdrawn
              createdAt
            }
            totalBids {
              date
              count
            }
            lifetimeBids {
              date
              count
            }
            lifetimeGrowth
            totalGrowth
          }
        }
      `,
      variables: {
        period,
      },
    });

    return getResponseData(res, 'getPlacedBids');
  };

  const getUserNotificationSettings = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getUserNotificationSettings {
          getUserNotificationSettings {
            notificationId
            notificationTypeId
            notificationType {
              notificationTypeId
              name
            }
          }
        }
      `,
    });

    return getResponseData(res, 'getUserNotificationSettings');
  };

  const userUpdateNotificationSetting = async (authToken, updateList) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation userUpdateNotificationSetting(
          $input: UserUpdateNotificationSettingDTO!
        ) {
          userUpdateNotificationSetting(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: { updateList },
      },
    });

    return getResponseData(res, 'userUpdateNotificationSetting');
  };

  const requestReprocessing = async (authToken, nftId) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation requestReprocessing($input: InputUUID!) {
          requestReprocessing(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: { id: nftId },
      },
    });

    return getResponseData(res, 'requestReprocessing');
  };

  const increaseViewCount = async (authToken, nftId, code) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation userView($input: AddViewDTO!) {
          userView(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          nftId,
          code: code,
        },
      },
    });

    return getResponseData(res, 'userView');
  };

  const getFollowing = async (from, to, authToken) => {
    const res = await getClient(authToken).query({
      query: gql`
        query isUserFollowing(
          $from: WalletAddressDTO!
          $to: WalletAddressDTO!
        ) {
          isUserFollowing(from: $from, to: $to)
        }
      `,
      variables: {
        from: {
          address: from,
        },
        to: {
          address: to,
        },
      },
    });

    return getResponseData(res, 'isUserFollowing');
  };

  const followUser = async (userId, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation toggleUserFollow($input: InputUUID!) {
          toggleUserFollow(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          id: userId,
        },
      },
    });

    return getResponseData(res, 'toggleUserFollow');
  };

  const getFollowers = async (authToken, userId) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getUserFollowerAccounts($input: InputUUID!) {
          getUserFollowerAccounts(input: $input) {
            userId
            address
            name
            avatar
            isFollowing
          }
        }
      `,
      variables: {
        input: {
          id: userId,
        },
      },
    });

    return getResponseData(res, 'getUserFollowerAccounts');
  };

  const getFollowings = async (authToken, userId) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getUserFollowingAccounts($input: InputUUID!) {
          getUserFollowingAccounts(input: $input) {
            userId
            address
            name
            avatar
            isFollowing
          }
        }
      `,
      variables: {
        input: {
          id: userId,
        },
      },
    });

    return getResponseData(res, 'getUserFollowingAccounts');
  };

  const getProfileDetailsCount = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getProfileDetailsCount {
          getOffersTotal {
            receivedOffers
            sentOffers
          }
          getBidsTotal {
            receivedBids
            placedBids
          }
          getInteractionsTotal {
            likes
            favorites
          }
          getUserPayTokensTotal
          getSingleItemsTotal
        }
      `,
    });

    return {
      offers: getResponseData(res, 'getOffersTotal'),
      bids: getResponseData(res, 'getBidsTotal'),
      interactions: getResponseData(res, 'getInteractionsTotal'),
      singleItems: getResponseData(res, 'getSingleItemsTotal'),
      payTokens: getResponseData(res, 'getUserPayTokensTotal'),
    };
  };

  const getOffersTotal = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getOffersTotal {
          getOffersTotal {
            receivedOffers
            sentOffers
          }
        }
      `,
    });

    return getResponseData(res, 'getOffersTotal');
  };

  const getValidationString = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getValidationString {
          getValidationString {
            message
            validationString
          }
        }
      `,
    });

    return getResponseData(res, 'getValidationString');
  };

  const getBidsTotal = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getBidsTotal {
          getBidsTotal {
            receivedBids
            placedBids
          }
        }
      `,
    });

    return getResponseData(res, 'getBidsTotal');
  };

  const getInteractionsTotal = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getInteractionsTotal {
          getInteractionsTotal {
            likes
            favorites
          }
        }
      `,
    });

    return getResponseData(res, 'getInteractionsTotal');
  };

  const fetchNftCount = async (wallet, authToken) => {
    const res = await getClient(authToken).query({
      query: gql`
        query fetchNftCount($input: WalletAddressDTO!) {
          fetchNftCount(input: $input)
        }
      `,
      variables: {
        input: {
          address: wallet,
        },
      },
    });

    return getResponseData(res, 'fetchNftCount');
  };

  // TODO: expecting nftId, NOT nftTokenId
  const toggleLikeItem = async (nftId, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation toggleUserLike($input: InputUUID!) {
          toggleUserLike(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          id: nftId,
        },
      },
    });

    return getResponseData(res, 'toggleUserLike');
  };

  const toggleFavoriteItem = async (nftId, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation toggleUserFavorite($input: InputUUID!) {
          toggleUserFavorite(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          id: nftId,
        },
      },
    });

    return getResponseData(res, 'toggleUserFavorite');
  };

  const setAssetUploaded = async (authToken, assetId) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation setAssetUploaded($input: InputNumber!) {
          setAssetUploaded(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          id: assetId,
        },
      },
    });

    return getResponseData(res, 'setAssetUploaded');
  };

  const fetchNftStatus = async (authToken, nftId) => {
    const res = await getClient(authToken).query({
      query: gql`
        query fetchNftStatus($input: InputUUID!) {
          fetchNftStatus(input: $input) {
            status {
              nftStatusId
              name
            }
            processing
          }
        }
      `,
      variables: {
        input: { id: nftId },
      },
    });
    return getResponseData(res, 'fetchNftStatus');
  };

  const uploadFileFromUrl = async (
    authToken,
    imageUrl,
    fileName,
    progressCb,
    completeCb,
    assetType,
    parentId,
    addWatermark = false
  ) => {
    const REST_API = process.env.REACT_APP_REST_BASE_URL;

    // REST API Call
    const photo = await axios({
      method: 'post',
      // url: `${REST_API}files/documents/watermark-from-url`, // removed watermark
      url: addWatermark
        ? `${REST_API}files/documents/watermark-from-url`
        : `${REST_API}files/documents/downsize-photo-from-url`,
      data: {
        imageUrl,
        fileName,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        responseType: 'json',
      },
      onUploadProgress: () => {
        progressCb(15);
      },
    });
    //check errors
    if (photo.status === 400 || photo.error || !photo.data) {
      throw new Error(photo.error || 'Something went wrong!');
    }

    const fileDataWatermark = new File(
      [Buffer.from(photo.data.image.img.data, 'base64')],
      photo.data.image.fileName,
      {
        type: photo.data.image.mimeType,
      }
    );
    fileDataWatermark.path = photo.data.image.fileName;
    //override the file data for the thumbnail image
    var fileData = fileDataWatermark;

    //1 generate a presigned (put and get) url and persist the asset id in the database
    const { data: resPresignedUrl, errors } = await getClient(authToken).mutate(
      {
        mutation: gql`
          mutation createUploadPresignedUrl(
            $input: CreateUploadPresignedUrlDTO!
          ) {
            createUploadPresignedUrl(input: $input) {
              asset {
                assetId
                parentId
              }
              responsePutSignedUrl
              responseGetUrl
              shortUrl
            }
          }
        `,
        variables: {
          input: {
            assetType,
            fileName: fileData.path,
            parentId: parentId,
          },
        },
      }
    );

    if (errors) {
      throw errors[0].message;
    }

    //getting the put signed url
    const putSignedUrl =
      resPresignedUrl.createUploadPresignedUrl.responsePutSignedUrl;
    //2 upload the file in aws s3 with the signedurl
    try {
      const responseUploadSignedUrl = await axios({
        method: 'put',
        url: putSignedUrl,
        data: fileData,
        headers: {
          'Content-Type': fileData.type,
          token: authToken,
        },
        onUploadProgress: loadingStatus => {
          if (progressCb) {
            progressCb(
              Math.round((100 * loadingStatus.loaded) / loadingStatus.total)
            );
          }
        },
      });
      //check errors

      if (responseUploadSignedUrl.error) {
        throw responseUploadSignedUrl.error;
      }
    } catch (error) {
      console.log(error);
    }

    const data = {
      url: resPresignedUrl.createUploadPresignedUrl.shortUrl,
      assetId: resPresignedUrl.createUploadPresignedUrl.asset.assetId,
      fullUrl: resPresignedUrl.createUploadPresignedUrl.responseGetUrl,
      parentId: resPresignedUrl.createUploadPresignedUrl.asset.parentId,
      type: assetType,
    };
    if (completeCb) {
      //complete the callback
      completeCb(data);
    }
    //returning the data of the presigned method to continue with the app
    return data;
  };

  async function handleCreateUploadPresignedUrl(
    authToken,
    assetType,
    fileData
  ) {
    //1 generate a presigned (put and get) url and persist the asset id in the database
    const { data, errors } = await getClient(authToken).mutate({
      mutation: gql`
        mutation createUploadPresignedUrl(
          $input: CreateUploadPresignedUrlDTO!
        ) {
          createUploadPresignedUrl(input: $input) {
            asset {
              assetId
              parentId
            }
            responsePutSignedUrl
            responseGetUrl
            shortUrl
          }
        }
      `,
      variables: {
        input: {
          assetType: assetType,
          fileName: fileData.path,
        },
      },
    });

    if (errors) {
      throw errors[0].message;
    }

    return data;
  }

  const uploadFile = async (
    authToken,
    assetType,
    fileData,
    progressCb,
    completeCb
  ) => {
    // check if assetType exists
    if (!Object.values(ASSET_TYPES).includes(assetType)) {
      return null;
    }

    const REST_API = process.env.REACT_APP_REST_BASE_URL;
    let resPresignedUrl;

    //0 if assetType == thumbnailImage, then I watermark it
    if (
      assetType === ASSET_TYPES.THUMBNAIL_IMAGE ||
      assetType === ASSET_TYPES.WATERMARKED_THUMBNAIL_IMAGE ||
      (assetType === ASSET_TYPES.PHOTOS &&
        fileData.type &&
        fileData.type.includes('image'))
    ) {
      const formData = new FormData();
      formData.append('data', fileData);
      // REST API Call
      const watermarkedPhoto = await axios({
        method: 'post',
        url: `${REST_API}files/documents/${
          assetType === ASSET_TYPES.THUMBNAIL_IMAGE
            ? 'downsize-photo'
            : 'download-watermark'
        }`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
          responseType: 'json',
        },
        onUploadProgress: () => {
          progressCb(15);
        },
      });
      //check errors
      if (
        watermarkedPhoto.status === 400 ||
        watermarkedPhoto.error ||
        !watermarkedPhoto.data
      ) {
        completeCb();
        return;
      }
      const fileDataWatermark = new File(
        [Buffer.from(watermarkedPhoto.data.image.img.data, 'base64')],
        watermarkedPhoto.data.image.fileName,
        {
          type: watermarkedPhoto.data.image.mimeType,
        }
      );
      fileDataWatermark.path = watermarkedPhoto.data.image.fileName;
      //override the file data for the thumbnail image
      fileData = fileDataWatermark;
    }

    resPresignedUrl = await handleCreateUploadPresignedUrl(
      authToken,
      assetType,
      fileData
    );

    //getting the put signed url
    const putSignedUrl =
      resPresignedUrl.createUploadPresignedUrl.responsePutSignedUrl;
    //2 upload the file in aws s3 with the signedurl
    try {
      const responseUploadSignedUrl = await axios({
        method: 'put',
        url: putSignedUrl,
        data: fileData,
        headers: {
          'Content-Type': fileData.type,
          token: authToken,
        },
        onUploadProgress: loadingStatus => {
          const progress = Math.round(
            (100 * loadingStatus.loaded) / loadingStatus.total
          );
          if (progressCb) {
            progressCb(progress);
          }
        },
      });
      //check errors

      if (responseUploadSignedUrl.error) {
        completeCb();
        return;
      }
    } catch (error) {
      console.log(error);
    }

    const data = {
      url: resPresignedUrl.createUploadPresignedUrl.shortUrl,
      assetId: resPresignedUrl.createUploadPresignedUrl.asset.assetId,
      fullUrl: resPresignedUrl.createUploadPresignedUrl.responseGetUrl,
    };
    if (completeCb) {
      //complete the callback
      completeCb(data);
    }
    //returning the data of the presigned method to continue with the app
    return data;
  };

  const healthCheck = async () => {
    const REST_API = process.env.REACT_APP_REST_BASE_URL;

    const res = await axios({
      method: 'get',
      url: `${REST_API}`,
    });

    return res.data;
  };

  const uploadUserFile = async (authToken, assetType, fileData) => {
    //1 generate a presigned (put and get) url and persist the asset id in the database
    const response = await getClient(authToken).mutate({
      mutation: gql`
        mutation createUserPresignedUrl($input: CreateUserPresignedUrlDTO!) {
          createUserPresignedUrl(input: $input) {
            responsePutSignedUrl
            responseGetUrl
          }
        }
      `,
      variables: {
        input: {
          fileName: fileData.path,
          type: assetType,
        },
      },
    });
    //getting the put signed url
    const putSignedUrl =
      response.data.createUserPresignedUrl.responsePutSignedUrl;
    //2 upload the file in aws s3 with the signedurl
    try {
      const responseUploadSignedUrl = await axios({
        method: 'put',
        url: putSignedUrl,
        data: fileData,
        headers: {
          'Content-Type': fileData.type,
          token: authToken,
        },
      });
      //check errors

      if (responseUploadSignedUrl.error) {
        return responseUploadSignedUrl.error;
      }
    } catch (error) {
      console.log(error);
    }

    //returning the data of the presigned method to continue with the app
    return response.data.createUserPresignedUrl.responseGetUrl;
  };

  const deleteFile = async (authToken, id) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation deleteFile($input: InputNumber!) {
          deleteFile(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          id,
        },
      },
    });
    return getResponseData(res, 'deleteFile');
  };

  const removeUnusedFiles = async authToken => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation removeUnusedFiles {
          removeUnusedFiles {
            message
          }
        }
      `,
    });

    return getResponseData(res, 'removeUnusedFiles');
  };

  const getPendingFiles = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getPendingFiles {
          getPendingFiles {
            assetId
            url
            type
            parentId
            status
            failed
            errors {
              fileName
              error
            }
            uploaded
            nsfw
            likely
            relatedAssets {
              assetId
              type
            }
          }
        }
      `,
    });

    return getResponseData(res, 'getPendingFiles');
  };

  const checkAssetsReady = async (authToken, assets) => {
    const res = await getClient(authToken).query({
      query: gql`
        query checkAssetsReady($input: InputArrayNumber!) {
          checkAssetsReady(input: $input) {
            assetId
            url
            status
            failed
            likely
            nsfw
            parentId
            assetTypeId
          }
        }
      `,
      variables: {
        input: { ids: assets },
      },
    });

    return getResponseData(res, 'checkAssetsReady');
  };

  const getAddERC20PlatformFee = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query getAddERC20PlatformFee {
          getAddERC20PlatformFee
        }
      `,
    });

    return getResponseData(res, 'getAddERC20PlatformFee');
  };

  // TODO: missing form params:
  // collectionId
  // fileType
  // saveDraft
  // artistName, artistWebsite, artistInstagram, artistTwitter
  // attributes
  // actorModelReleases
  const registerNft = async (authToken, formData, isDraft) => {
    // TODO: missing input params
    // artistId
    // contractAddress
    // payTokenId
    // tokenId
    // categoryId
    // metadataUri
    // croppedThumbnailImage
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation registerNft($input: RegisterNftDTO!) {
          registerNft(input: $input)
        }
      `,
      variables: {
        input: {
          ...(formData.nftId && { nftId: formData.nftId }),
          name: formData.name,
          description: formData.description,
          contractAddress: formData.contractAddress,
          tags: formData.tags,
          royalty: formData.royalty,
          categoryId: formData.categoryId,
          contractTypeId: formData.contractTypeId,
          photos: formData.photos,
          frontCover: formData.frontCover,
          ...(formData.relatedFrontCoverAssets && {
            relatedFrontCoverAssets: formData.relatedFrontCoverAssets,
          }),
          ...(formData.thumbnailImage && {
            thumbnailImage: formData.thumbnailImage,
          }),
          ...(formData.watermarkedThumbnailImage && {
            watermarkedThumbnailImage: formData.watermarkedThumbnailImage,
          }),
          additionalDocuments: formData.additionalDocuments,
          actorModelReleases: formData.actorModelReleases,
          subcategories: formData.subcategories,
          attributes: formData.attributes,
          payTokenId: formData.payTokenId,
          isDraft,
        },
      },
    });

    return getResponseData(res, 'registerNft');
  };

  const payTokenExists = async (authToken, address) => {
    const res = await getClient(authToken).query({
      query: gql`
        query payTokenExists($input: InputETH!) {
          payTokenExists(input: $input)
        }
      `,
      variables: {
        input: {
          address,
        },
      },
    });

    return getResponseData(res, 'payTokenExists');
  };

  const communityExists = async (authToken, name) => {
    const res = await getClient(authToken).query({
      query: gql`
        query communityExists($input: InputName!) {
          communityExists(input: $input)
        }
      `,
      variables: {
        input: {
          name,
        },
      },
    });

    return getResponseData(res, 'communityExists');
  };

  const getUserPayTokens = async (authToken, { from, count }) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getUserPayTokens($input: GetPayTokensDTO!) {
          getUserPayTokens(input: $input) {
            payTokens {
              payTokenId
              name
              icon
              symbol
              address
              decimals
              price
              status
              community {
                name
                description
                website
                discord
                twitter
                instagram
                tiktok
                telegram
              }
              createdAt
            }
            total
          }
        }
      `,
      variables: {
        input: {
          from,
          count,
        },
      },
    });

    return getResponseData(res, 'getUserPayTokens');
  };

  const fetchPayTokens = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query fetchPayTokens {
          fetchPayTokens {
            payTokenId
            name
            icon
            symbol
            address
            decimals
            price
            status
            community {
              name
              description
            }
            createdAt
          }
        }
      `,
    });

    return getResponseData(res, 'fetchPayTokens');
  };

  const getPayTokens = async ({ from, count, userNameFilter }) => {
    const res = await getClient().query({
      query: gql`
        query getFinalizedPayTokens($input: GetPayTokensDTO!) {
          getFinalizedPayTokens(input: $input) {
            payTokens {
              payTokenId
              name
              icon
              symbol
              address
              decimals
              price
              status
              community {
                name
                description
              }
              createdAt
            }
            total
          }
        }
      `,
      variables: {
        input: {
          from,
          count,
          userNameFilter,
        },
      },
    });

    return getResponseData(res, 'getFinalizedPayTokens');
  };

  const removePayToken = async (authToken, nftAddress) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation removePayToken($input: InputETH!) {
          removePayToken(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: { address: nftAddress },
      },
    });
    return getResponseData(res, 'removePayToken');
  };

  const addPayToken = async (
    authToken,
    { assetId, name, symbol, address, decimals, community }
  ) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation addPayToken($input: CreatePayTokenDTO!) {
          addPayToken(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          assetId,
          name,
          symbol,
          address,
          decimals,
          community, // object
        },
      },
    });

    return getResponseData(res, 'addPayToken');
  };

  const fetchPayToken = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query fetchPayToken {
          fetchPayToken {
            payTokenId
            name
            icon
            symbol
            address
            decimals
            price
            community {
              name
              description
              website
              discord
              twitter
              instagram
              tiktok
              telegram
            }
          }
        }
      `,
    });

    return getResponseData(res, 'fetchPayToken');
  };

  const savePayTokenTxHash = async (authToken, payTokenAddress, txHash) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation savePayTokenTxHash($input: UpdatePayTokenDTO!) {
          savePayTokenTxHash(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          payTokenAddress,
          txHash,
        },
      },
    });

    return getResponseData(res, 'savePayTokenTxHash');
  };
  const removeNft = async (nftId, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation removeNft($input: InputUUID!) {
          removeNft(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          id: nftId,
        },
      },
    });

    return getResponseData(res, 'removeNft');
  };

  const fetchUserNft = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        ${CORE_NFT_FIELDS}
        query fetchNftsByUser {
          fetchNftsByUser {
            ...CoreNftFields
          }
        }
      `,
    });

    return getResponseData(res, 'fetchNftsByUser');
  };

  const findAllUserNotifications = async (authToken, variables) => {
    const res = await getClient(authToken).query({
      query: gql`
        query findAllUserNotifications($input: FindNotificationDTO!) {
          findAllUserNotifications(input: $input) {
            notifications {
              notificationId
              userId
              title
              message
              link
              status
              createdAt
            }
            total
          }
        }
      `,
      variables: {
        input: variables,
      },
    });

    return getResponseData(res, 'findAllUserNotifications');
  };

  const notificationViewed = async (authToken, { notificationId }) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation notificationViewed($input: InputUUID!) {
          notificationViewed(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          id: notificationId,
        },
      },
    });

    return getResponseData(res, 'notificationViewed');
  };

  const verifyValidTradeAmount = async (payTokenAddress, amount, authToken) => {
    const res = await getClient(authToken).query({
      query: gql`
        query verifyValidTradeAmount($input: VerifyTradeAmountDTO!) {
          verifyValidTradeAmount(input: $input)
        }
      `,
      variables: {
        input: {
          payTokenAddress,
          amount: amount.toString(),
        },
      },
    });

    return getResponseData(res, 'verifyValidTradeAmount');
  };

  const addKYCPaymentReceipt = async (txHash, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation addKYCPaymentReceipt($input: KYCReceiptDTO!) {
          addKYCPaymentReceipt(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          txHash,
        },
      },
    });

    return getResponseData(res, 'addKYCPaymentReceipt');
  };

  const getNftOffers = async nftId => {
    const res = await getClient().query({
      query: gql`
        ${CORE_OFFER_FIELDS}
        query getNftOffers($input: InputUUID!) {
          getNftOffers(input: $input) {
            ...CoreOfferFields
          }
        }
      `,
      variables: {
        input: {
          id: nftId,
        },
      },
    });

    return getResponseData(res, 'getNftOffers');
  };

  const fetchCreatedNftByName = async (authToken, nftName) => {
    const res = await getClient(authToken).query({
      query: gql`
        ${CORE_NFT_FIELDS}
        query getNftByName($input: InputName!) {
          getNftByName(input: $input) {
            ...CoreNftFields
            attributes {
              attribute
              value
            }
            comments
          }
        }
      `,
      variables: {
        input: {
          name: nftName,
        },
      },
    });

    return getResponseData(res, 'getNftByName');
  };

  const fetchCreatedNft = async (authToken, nftId) => {
    const res = await getClient(authToken).query({
      query: gql`
        ${CORE_NFT_FIELDS}
        query getNft($input: InputUUID!) {
          getNft(input: $input) {
            ...CoreNftFields
            comments
          }
        }
      `,
      variables: {
        input: {
          id: nftId,
        },
      },
    });

    return getResponseData(res, 'getNft');
  };

  const addComment = async (
    { commentId, nftId, parentId, comment },
    authToken
  ) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        ${CORE_COMMENT_FIELDS}
        mutation addComment($input: AddCommentDTO!) {
          addComment(input: $input) {
            ...CoreCommentFields
          }
        }
      `,
      variables: {
        input: {
          commentId,
          nftId,
          parentId,
          comment,
        },
      },
    });
    return getResponseData(res, 'addComment');
  };

  const deleteComment = async (commentId, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation deleteComment($input: InputUUID!) {
          deleteComment(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          id: commentId,
        },
      },
    });
    return getResponseData(res, 'deleteComment');
  };

  const addCommentVote = async ({ commentId, vote }, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation addCommentVote($input: AddCommentVoteDTO!) {
          addCommentVote(input: $input) {
            commentVoteId
          }
        }
      `,
      variables: {
        input: {
          commentId,
          vote,
        },
      },
    });
    return getResponseData(res, 'addCommentVote');
  };

  const addCommentFlag = async ({ commentId, violation }, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation addCommentFlag($input: AddCommentFlagDTO!) {
          addCommentFlag(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          commentId,
          violation,
        },
      },
    });
    return getResponseData(res, 'addCommentFlag');
  };

  const getNftComments = async (
    { nftId, parentId, from, count },
    authToken
  ) => {
    const res = await getClient(authToken).query({
      query: gql`
        ${CORE_COMMENT_FIELDS}
        query getNftComments($input: GetNFTCommentsDTO!) {
          getNftComments(input: $input) {
            comments {
              ...CoreCommentFields
            }
            total
          }
        }
      `,
      variables: {
        input: {
          nftId,
          parentId,
          from,
          count: count || 10,
        },
      },
    });
    return getResponseData(res, 'getNftComments');
  };

  const addNftFlag = async ({ nftId, violation }, authToken) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation addNftFlag($input: AddNftFlagDTO!) {
          addNftFlag(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          nftId,
          violation,
        },
      },
    });
    return getResponseData(res, 'addNftFlag');
  };

  const getNftTradeHistories = async nftId => {
    const res = await getClient().query({
      query: gql`
        ${CORE_TRADE_HISTORY_FIELDS}
        query getNftTradeHistories($input: InputUUID!) {
          getNftTradeHistories(input: $input) {
            ...CoreTradeHistoryFields
          }
        }
      `,
      variables: {
        input: {
          id: nftId,
        },
      },
    });
    return getResponseData(res, 'getNftTradeHistories');
  };

  const getCategories = async () => {
    const res = await getClient().query({
      query: gql`
        query fetchCategories {
          fetchCategories {
            categoryId
            type
            subcategories {
              categoryId
              parentId
              type
            }
          }
        }
      `,
    });
    return getResponseData(res, 'fetchCategories');
  };

  const getContractTypes = async () => {
    const res = await getClient().query({
      query: gql`
        query fetchContractTypes {
          fetchContractTypes {
            contractTypeId
            name
          }
        }
      `,
    });
    return getResponseData(res, 'fetchContractTypes');
  };

  const getNftStatuses = async () => {
    const res = await getClient().query({
      query: gql`
        query fetchNftStatuses {
          fetchNftStatuses {
            nftStatusId
            name
          }
        }
      `,
    });
    return getResponseData(res, 'fetchNftStatuses');
  };

  const saveTxHash = async (authToken, nftId, nftStatusId, txHash) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation saveNftTxHash($input: TransactionHashDTO!) {
          saveNftTxHash(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          nftId,
          txHash,
          nftStatusId,
        },
      },
    });

    return getResponseData(res, 'saveNftTxHash');
  };

  const getUserByName = async name => {
    const res = await getClient().query({
      query: gql`
        ${CORE_USER_FIELDS}
        query getUserProfileByName($input: InputName!) {
          getUserProfileByName(input: $input) {
            ...CoreUserFields
          }
        }
      `,
      variables: {
        input: {
          name: name,
        },
      },
    });

    return getResponseData(res, 'getUserProfileByName');
  };

  const getUserByWalletAddress = async walletAddress => {
    const res = await getClient().query({
      query: gql`
        ${CORE_USER_FIELDS}
        query getUserProfileByWalletAddress($input: WalletAddressDTO!) {
          getUserProfileByWalletAddress(input: $input) {
            ...CoreUserFields
          }
        }
      `,
      variables: {
        input: {
          address: walletAddress,
        },
      },
    });

    return getResponseData(res, 'getUserProfileByWalletAddress');
  };

  const checkIfWalletIsVerifiedByUser = async (authToken, walletAddress) => {
    const res = await getClient(authToken).query({
      query: gql`
        query checkIfWalletIsVerifiedByUser($input: WalletAddressDTO!) {
          checkIfWalletIsVerifiedByUser(wallet_address: $input) {
            message
            isVerified
          }
        }
      `,
      variables: {
        input: {
          address: walletAddress,
        },
      },
    });

    return getResponseData(res, 'checkIfWalletIsVerifiedByUser');
  };

  const uploadIpfs = async (authToken, nftId) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation uploadIpfs($input: InputUUID!) {
          uploadIpfs(input: $input)
        }
      `,
      variables: {
        input: {
          id: nftId,
        },
      },
    });

    return getResponseData(res, 'uploadIpfs');
  };

  const check2faCode = async (token, twoFACode) => {
    const res = await getClient(token).mutate({
      mutation: gql`
        mutation userCheck2faCode($input: Check2faDTO!) {
          userCheck2faCode(input: $input)
        }
      `,
      variables: {
        input: {
          code: twoFACode,
        },
      },
    });

    return getResponseData(res, 'userCheck2faCode');
  };

  const fetchQrcode = async authToken => {
    const res = await getClient(authToken).query({
      query: gql`
        query userFetchQrCode {
          userFetchQrCode
        }
      `,
    });

    return getResponseData(res, 'userFetchQrCode');
  };

  const getNftMetadata = async (tokenID, authToken) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getNftMetadata($input: InputUUID!) {
          getNftMetadata(input: $input) {
            contentLength
            name
          }
        }
      `,
      variables: {
        input: {
          id: tokenID,
        },
      },
    });

    return getResponseData(res, 'getNftMetadata');
  };

  const addTransaction = async (
    authToken,
    nftId,
    orderId,
    amount,
    amountInUSD,
    currency
  ) => {
    const res = await getClient(authToken).mutate({
      mutation: gql`
        mutation addTransaction($input: TransactionDTO!) {
          addTransaction(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          nftId,
          orderId,
          amount,
          amountInUSD,
          currency,
        },
      },
    });
    return getResponseData(res, 'addTransaction');
  };

  const getTransaction = async nftId => {
    const res = await getClient().query({
      query: gql`
        query getPendingTransaction($input: InputUUID!) {
          getPendingTransaction(input: $input) {
            transactionId
            orderId
            status
            amount
            amountInUSD
            currency
            userId
            user {
              name
              avatar
            }
          }
        }
      `,
      variables: {
        input: {
          id: nftId,
        },
      },
    });
    return getResponseData(res, 'getPendingTransaction');
  };
  const getNftImageOrAsset = async (tokenID, authToken) => {
    const res = await getClient(authToken).query({
      query: gql`
        query getNftImageOrAsset($input: InputUUID!) {
          getNftImageOrAsset(input: $input) {
            signedURL
            fileName
            contentLength
          }
        }
      `,
      variables: {
        input: {
          id: tokenID,
        },
      },
    });

    return getResponseData(res, 'getNftImageOrAsset');
  };

  return {
    explorerUrl,
    storageUrl,
    getNftOffers,
    getValidationString,
    getUserAccountDetails,
    getUserNetworkInfo,
    getReceivedOffers,
    getSentOffers,
    getReceivedBids,
    getPlacedBids,
    getUserNotificationSettings,
    userUpdateNotificationSetting,
    requestReprocessing,
    fetchNfts,
    searchNames,
    increaseViewCount,
    getFollowing,
    followUser,
    getFollowers,
    getFollowings,
    getOffersTotal,
    getBidsTotal,
    getInteractionsTotal,
    getProfileDetailsCount,
    fetchNftCount,
    toggleLikeItem,
    toggleFavoriteItem,
    postLogin,
    postSignUp,
    resendVerificationEmail,
    postConfirmAccount,
    postResetPassword,
    checkPassword,
    postSetNewPassword,
    verifyWallet,
    getSumsubToken,
    postUpdateUser,
    uploadFile,
    setAssetUploaded,
    uploadFileFromUrl,
    checkAssetsReady,
    getAddERC20PlatformFee,
    registerNft,
    addPayToken,
    payTokenExists,
    communityExists,
    getUserPayTokens,
    fetchPayTokens,
    getPayTokens,
    removeNft,
    deleteFile,
    fetchUserNft,
    findAllUserNotifications,
    notificationViewed,
    fetchCreatedNft,
    fetchNftStatus,
    getCategories,
    getContractTypes,
    getNftStatuses,
    saveTxHash,
    getUserByWalletAddress,
    checkIfWalletIsVerifiedByUser,
    uploadIpfs,
    fetchQrcode,
    check2faCode,
    getNftMetadata,
    requestWhitelisted,
    postWhitelistUser,
    rejectWhitelistUser,
    rejectWhitelistUserByAdmin,
    removeUnusedFiles,
    uploadUserFile,
    getUserProfile,
    getNftImageOrAsset,
    getNftTradeHistories,
    healthCheck,
    getQuestionTypes,
    getFAQs,
    fetchCreatedNftByName,
    getUserByName,
    addComment,
    deleteComment,
    addCommentVote,
    addCommentFlag,
    getNftComments,
    addNftFlag,
    getRewardHistory,
    getMonthlyTotalRewardHistories,
    getTransferSignature,
    getRefferals,
    sendReferralEmail,
    createShare,
    redeemShare,
    getTotalShare,
    getPendingFiles,
    checkUserToken,
    addTransaction,
    getTransaction,
    getKYCFee,
    getKYCTxHash,
    verifyValidTradeAmount,
    addKYCPaymentReceipt,
    savePayTokenTxHash,
    fetchPayToken,
    removePayToken,
  };
};
