import { useApi } from 'api';
import React, { useCallback, useEffect, useState } from 'react';

import styles from './styles.module.scss';

const PAGE_SIZE = 5;

const index = ({ userName }) => {
  const { getPayTokens } = useApi();
  const [payTokens, setPayTokens] = useState([]);
  const [total, setTotal] = useState(0);

  const loadPayTokens = useCallback(
    async (count = PAGE_SIZE) => {
      const variables = {
        from: 0,
        count,
        userNameFilter: userName,
      };
      const { data } = await getPayTokens(variables);

      return data;
    },
    [userName]
  );

  const filterCommunity = payTokens => payTokens?.filter(a => a.community);

  useEffect(() => {
    if (userName) {
      (async () => {
        const data = await loadPayTokens();

        setPayTokens(filterCommunity(data.payTokens));
        setTotal(data.total);
      })();
    }
  }, [userName, loadPayTokens]);

  const toggleSeeMore = async () => {
    const data = await loadPayTokens(total);

    setPayTokens(filterCommunity(data.payTokens));
    setTotal(data.total);
  };

  const showLoadMore = (payTokens.length || 0) < total;

  return (
    <div className={styles.container}>
      {payTokens?.map(token => {
        const { community } = token;

        if (community) {
          return (
            <a key={token.payTokenId} href="#">
              #{community.name}
            </a>
          );
        } else {
          return <></>;
        }
      })}

      {showLoadMore && (
        <button onClick={toggleSeeMore} className={styles.seeMore}>
          Show more...
        </button>
      )}
    </div>
  );
};

export default index;
