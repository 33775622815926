import React, { memo, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import Identicon from 'components/Identicon';
import { Box, Stack } from '@mui/material';
import { StyledButton } from 'components/StyledComponents';
import IconThumbup from 'assets/icons/nft/iconThumbup';
import { formatDate } from 'utils';
import showToast from 'utils/toast';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  totalCommentsCount,
} from '../../utils';
import ActionButtons from '../ActionButtons';
import AddComment from '../AddComment';
import { useApi } from 'api';

import styles from './styles.module.scss';

const ReplyItem = ({
  nft,
  user: me,
  parentId,
  comment,
  setRepliesCount,
  setComments,
  authToken,
  onCommentDeleted,
  isMobile,
}) => {
  const history = useHistory();
  const [createdAt, setCreatedAt] = useState('');
  const [upvotes, setUpvotes] = useState(comment.upvotes);
  const [upvoted, setUpvoted] = useState(comment.upvoted);
  const [commentText, setCommentText] = useState(comment.comment);
  const [isReplying, setIsReplying] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isNewComment, setIsNewComment] = useState(false);
  const [isEdited, setIsEdited] = useState(comment.edited);
  const [commentSelected, setCommentSelected] = useState(false);

  const newCommentRef = useRef(null);

  const handleRedirectToProfile = name => {
    if (name) {
      history.push(`/account/${name.replaceAll(' ', '+')}`);
    }
  };

  const { addCommentVote, addCommentFlag, deleteComment } = useApi();

  useEffect(() => {
    const formatted = formatDate(comment.createdAt);
    setCreatedAt(formatted);
    setIsNewComment(formatted === 'just now');
  }, [comment.createdAt]);

  useEffect(() => {
    if (newCommentRef.current) {
      newCommentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isNewComment]);

  const handleUpvoteComment = async () => {
    if (!authToken || comment.userId === me.userId) return;

    const model = {
      commentId: comment.commentId,
      vote: 'upvote',
    };

    const { data } = await addCommentVote(model, authToken);

    if (data?.commentVoteId) {
      setUpvoted(true);
      setUpvotes(prev => prev + 1);
    } else {
      setUpvoted(false);
      setUpvotes(prev => prev - 1);
    }
  };

  const onNewCommentAdded = comment => {
    setComments(prev => [...prev, comment]);
    setIsReplying(false);

    // increse total comments count
    totalCommentsCount.value = totalCommentsCount.value + 1;

    // increase reply count
    setRepliesCount(prev => prev + 1);
  };

  const handleReportComment = async violation => {
    if (!authToken) return;

    const model = {
      commentId: comment.commentId,
      violation,
    };

    const { data } = await addCommentFlag(model, authToken);

    if (data?.message === 'OK') {
      showToast('success', 'Your report submitted successfully.');
    } else {
      showToast('success', 'You have already reported this comment.');
    }
  };

  const handleDeleteComment = async () => {
    if (!authToken) return;

    const { data } = await deleteComment(comment.commentId, authToken);

    if (data?.message === 'OK') {
      setIsDeleted(true);
      // to add animation
      setTimeout(() => {
        onCommentDeleted && onCommentDeleted(comment.commentId); // notify parent
        // update total count
        totalCommentsCount.value = totalCommentsCount.value - 1;
      }, 1000);
    } else {
      showToast('error', 'Something went wrong!');
    }
  };

  const handleEditComment = comment => {
    setCommentText(comment.comment);
    setIsEdited(true);
  };

  return (
    <Stack
      className={cx(
        styles.root,
        isNewComment && styles.flash,
        isDeleted && styles.deleted,
        commentSelected && styles.selected
      )}
      ref={isNewComment ? newCommentRef : null}
    >
      <Stack className={styles.firstRow}>
        <Stack
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          flexShrink={1}
          gap={1}
        >
          <Box
            display="flex"
            alignItems="center"
            onClick={() => handleRedirectToProfile(comment.user.name)}
            sx={{
              cursor: 'pointer',
            }}
          >
            {comment.user?.avatar ? (
              <img
                src={`${
                  comment.user?.avatar.includes('mypinata')
                    ? `${comment.user?.avatar}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                    : comment.user?.avatar
                }`}
                width="30"
                height="30"
                className={cx(styles.avatarBig)}
              />
            ) : (
              <Identicon
                account={comment.user?.userId}
                size={36}
                className={cx(styles.avatarBig)}
              />
            )}
          </Box>

          <Box
            className={styles.address}
            onClick={() => handleRedirectToProfile(comment.user.name)}
          >
            {comment.user.name}
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {/* x mins ago */}
            <Box className={styles.when}>{createdAt}</Box>

            {isEdited && <Box className={styles.when}>Edited</Box>}
          </Box>
        </Stack>

        <Box
          sx={{
            position: 'absolute',
            right: 0,
          }}
        >
          {authToken && (
            <ActionButtons
              comment={comment}
              authToken={authToken}
              isOwner={me.userId === comment.userId}
              onCommentSelected={setCommentSelected}
              onReport={handleReportComment}
              onDelete={handleDeleteComment}
              onEdit={handleEditComment}
            />
          )}
        </Box>
      </Stack>

      <Stack className={styles.secondRow}>{commentText}</Stack>

      <Accordion
        expanded={authToken && isReplying}
        sx={{
          backgroundColor: 'var(--background-color)',
        }}
      >
        <AccordionSummary
          expandIcon={<></>}
          sx={{
            backgroundColor: 'var(--background-color)',
          }}
        >
          <Stack display="flex" flexDirection="row" alignItems="center">
            <StyledButton
              sx={{
                width: 50,
                px: 0,
                py: '4px',
                minWidth: 0,
                borderColor: 'var(--border-color)',
                backgroundColor: upvoted
                  ? 'var(--secondary-background-color) !important'
                  : 'var(--background-color) !important',
                '&:hover': {
                  bgcolor: 'var(--secondary-background-color) !important',
                  borderColor: 'var(--border-color) !important',
                },
                '& svg': {
                  width: 20,
                  marginRight: '5px',
                },
              }}
              onClick={handleUpvoteComment}
            >
              <IconThumbup />
              <Box>{upvotes}</Box>
            </StyledButton>

            {authToken && (
              <StyledButton
                sx={{
                  ml: 2,
                  py: '1px',
                  minWidth: 0,
                  borderColor: 'var(--border-color)',
                  height: '100%',

                  '&:hover': {
                    bgcolor: 'var(--secondary-background-color) !important',
                    borderColor: 'var(--border-color) !important',
                  },
                }}
                cancel="true"
                onClick={() => setIsReplying(prev => !prev)}
              >
                Reply
              </StyledButton>
            )}
          </Stack>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            backgroundColor: 'var(--background-color)',
          }}
        >
          <Stack className={styles.replies}>
            {authToken && (
              <AddComment
                nft={nft}
                user={me}
                parentId={parentId}
                authToken={authToken}
                onCommentSaved={onNewCommentAdded}
                onCanceled={() => setIsReplying(false)}
                isMobile={isMobile}
                isReplying={isReplying}
              />
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.nft.nftId === nextProps.nft.nftId &&
    prevProps.me?.userId === nextProps.me?.userId &&
    prevProps.comment.comment === nextProps.comment.comment &&
    prevProps.parentId === nextProps.parentId &&
    prevProps.authToken === nextProps.authToken &&
    prevProps.isMobile === nextProps.isMobile
  );
};

export default memo(ReplyItem, areEqual);
