import React from 'react';

const IconGain = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99935 15.8337V4.16699M9.99935 4.16699L4.16602 10.0003M9.99935 4.16699L15.8327 10.0003"
      stroke="#12B76A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconGain;
