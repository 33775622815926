import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Player,
  ControlBar,
  VolumeMenuButton,
  CurrentTimeDisplay,
  TimeDivider,
  DurationDisplay,
  ProgressControl,
  PlaybackRateMenuButton,
  FullscreenToggle,
} from 'video-react';
import 'video-react/dist/video-react.css';
import styles from './styles.module.scss';
import IconLoading from 'assets/icons/iconLoading';

const VideoPlayer = ({
  videoUrl,
  posterUrl,
  playerRef,
  isMobile,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (playerRef?.current) {
      setLoading(true);
      const player = playerRef.current;
      const videoElement = player.video.video;

      const handleLoadedMetadata = () => {
        let width = videoElement.videoWidth;
        let height = videoElement.videoHeight;

        // when the video is small, display it bigger w/o change in aspect ratio
        if (width < 500 || height < 500) {
          width *= 2;
          height *= 2;
        }

        setVideoDimensions({ width, height });
        setLoading(false);
      };

      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        videoElement.removeEventListener(
          'loadedmetadata',
          handleLoadedMetadata
        );
      };
    }
  }, [videoUrl, isMobile]);

  const getAspectRatio = useCallback(
    () => videoDimensions.height / videoDimensions.width,
    [videoDimensions, isMobile]
  );

  const getVh = useCallback(() => {
    const aspectRatio = getAspectRatio();
    const vh = 100 / aspectRatio;

    return Math.round(vh);
  }, [videoDimensions, isMobile]);

  return (
    <Box className={styles.videoPlayer}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
          }}
        >
          Loading
          <IconLoading />
        </Box>
      )}
      <Box
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          width: `min(${videoDimensions.width}px, ${getVh() ||
            100}vh, 100vw, 100%) !important`,
          height: `min(${videoDimensions.height}px, ${
            isMobile ? '300px' : '750px, 100%'
          }) !important`,
          ...(getAspectRatio() > 1 && {
            '& .video-react-time-control, .video-react-playback-rate': {
              display: 'none',
            },
          }),
        }}
      >
        <Player
          ref={playerRef}
          src={videoUrl}
          fluid={false}
          width="100%"
          height="100%"
          loop
          poster={posterUrl}
        >
          {disabled && <div className={styles.disable} />}
          <ControlBar>
            <VolumeMenuButton />
            <CurrentTimeDisplay />
            <TimeDivider />
            <DurationDisplay />
            <ProgressControl />
            <PlaybackRateMenuButton rates={[2.0, 1.5, 1.0, 0.5]} order={7.1} />
            <FullscreenToggle />
          </ControlBar>
        </Player>
      </Box>
    </Box>
  );
};

export default VideoPlayer;
