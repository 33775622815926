import React, { useState, useCallback } from 'react';
import Modal from '@mui/material/Modal';
import styles from './styles.module.scss';
import cx from 'classnames';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import { getCroppedImg } from 'utils';

export const CropImageModal = ({
  originAvatar,
  setAvatar,
  saveBanner,
  setOriginAvatar,
  isBanner = false,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        originAvatar,
        croppedAreaPixels,
        0,
        { horizontal: false, vertical: false },
        setAvatar ? false : true,
        isBanner
      );

      setOriginAvatar(null);

      if (setAvatar) {
        setAvatar(croppedImage);
      } else {
        await saveBanner(croppedImage);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);
  return (
    <Modal open={originAvatar !== null} className={styles.modal}>
      <div className={styles.cropperContainer}>
        <div className={styles.cropper}>
          <Cropper
            image={originAvatar}
            crop={crop}
            zoom={zoom}
            aspect={!isBanner ? 1 : 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className={styles.sliderContainer}>
          <p className={styles.sliderLabel}>Zoom</p>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: styles.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div className={styles.btnGroup}>
          <div
            className={cx(styles.button, styles.cancel)}
            onClick={() => setOriginAvatar(null)}
          >
            Cancel
          </div>
          <div
            className={cx(styles.button, styles.saveCrop)}
            onClick={saveCroppedImage}
          >
            Save
          </div>
        </div>
      </div>
    </Modal>
  );
};
