import React, { useState } from 'react';
import cx from 'classnames';
import { StyledButton } from 'components/StyledComponents';
import {
  Drawer,
  List,
  ListItem,
  Menu,
  MenuItem,
  darken,
  useMediaQuery,
} from '@mui/material';
import IconSort from '@mui/icons-material/Sort';
import styles from './styles.module.scss';

const sortByOptions = [
  { key: 'oldest', value: 'Oldest' },
  { key: 'listedAt', value: 'Recently Listed' },
  { key: 'soldAt', value: 'Recently Sold' },
  { key: 'saleEndsAt', value: 'Ending Soon' },
  { key: 'priceHighest', value: 'Highest Price' },
  { key: 'priceLowest', value: 'Lowest Price' },
  { key: 'lastSaleHighest', value: 'Highest Last Price' },
  { key: 'mostViewed', value: 'Most Viewed' },
];

const SortByButton = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const isMobile = useMediaQuery('(max-width: 900px)');

  const { sortBy, setSortBy, className } = props;

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = sortBy => {
    if (setSortBy) {
      setSortBy(sortBy);
    }
    handleClose();
  };

  const selectedOption = sortByOptions.find(a => a.key === sortBy);
  const selectedName = selectedOption?.value || 'Sort By';

  return (
    <>
      <StyledButton
        className={cx(className, selectedOption && styles.active)}
        endIcon={<IconSort />}
        onClick={isMobile ? () => setOpenDrawer(true) : handleClick}
      >
        {selectedName}
      </StyledButton>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 250,
            maxHeight: 400,
            border: '1px solid #f4f4f4',
            filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
            backgroundColor: 'var(--primary-text-color)',
            mt: 1,
            p: 1,
            '& .MuiMenuItem-root': {
              mr: 1,
              ml: 1,
              borderRadius: 2,
              pt: 1.5,
              pb: 1.5,
              color: '#757575',
            },
            '& .selected': {
              bgcolor: '#f8f5fe',
              '&:hover': {
                bgcolor: darken('#f8f5fe', 0.05),
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => handleFilter('createdAt')}
          className={cx(sortBy === 'createdAt' && 'selected')}
        >
          Recently Created
        </MenuItem>
        {sortByOptions.map(item => (
          <MenuItem
            key={item.key}
            onClick={() => handleFilter(item.key)}
            className={cx(sortBy === item.key && 'selected')}
          >
            {item.value}
          </MenuItem>
        ))}
      </Menu>
      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          '.MuiPaper-root': {
            backgroundColor: 'var(--background-color)',
            borderRadius: '10px 0 10px 0',
          },
        }}
      >
        <List className={styles.drawerList}>
          <ListItem
            onClick={() => {
              handleFilter('createdAt');
              setOpenDrawer(false);
            }}
            className={cx(sortBy === 'createdAt' && styles.selected)}
          >
            Recently Created
          </ListItem>
          {sortByOptions.map(item => (
            <ListItem
              key={item.key}
              onClick={() => {
                handleFilter(item.key);
                setOpenDrawer(false);
              }}
              className={cx(sortBy === item.key && styles.selected)}
            >
              {item.value}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default SortByButton;
