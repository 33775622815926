import React, { memo, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Box, Stack } from '@mui/material';
import Identicon from 'components/Identicon';
import { StyledButton, StyledTextField } from 'components/StyledComponents';
import Reactions from '../Reactions';
import { useApi } from 'api';
import showToast from 'utils/toast';

import styles from './styles.module.scss';

const AddComment = ({
  nft,
  user,
  parentId,
  className,
  authToken,
  onCommentSaved,
  onCanceled,
  isMobile,
  isReplying,
}) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [showButtons, setShowButtons] = useState(false);
  const containerRef = useRef(null);
  const textFieldRef = useRef(null);

  const { addComment } = useApi();

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowButtons(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  const handleCancel = () => {
    setShowButtons(false);
    setComment('');
    if (onCanceled) {
      onCanceled();
    }
  };

  const handleFocus = () => {
    setShowButtons(true);
  };

  useEffect(() => {
    if (isReplying) {
      setTimeout(() => {
        if (textFieldRef.current) {
          textFieldRef.current.focus();
        }
      }, 200);
    }
  }, [isReplying]);

  const handleAddComment = async e => {
    e.preventDefault();

    if (!authToken || !comment.trim()) {
      return;
    }

    const model = {
      nftId: nft.nftId,
      parentId,
      comment: comment.trim(),
    };

    textFieldRef.current.blur();
    setLoading(true);
    const { data, errors } = await addComment(model, authToken);
    if (!errors) {
      onCommentSaved(data); // notify parent
      setComment('');
      setShowButtons(false);
    } else if (errors.at(0)?.message === 'Bad word') {
      showToast('error', 'Comment contains inappropriate language!');
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleAddComment}>
      <Stack className={cx(styles.root, className)} ref={containerRef}>
        <Stack>
          {user?.avatar ? (
            <img
              src={`${
                user?.avatar.includes('mypinata')
                  ? `${user?.avatar}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                  : user?.avatar
              }`}
              width="30"
              height="30"
              className={cx(styles.avatarBig)}
            />
          ) : (
            <Identicon
              account={user?.userId}
              size={36}
              className={cx(styles.avatarBig)}
            />
          )}
        </Stack>

        <Box className={styles.commentBox}>
          <Box className={styles.textbox}>
            <StyledTextField
              inputRef={textFieldRef}
              onFocus={handleFocus}
              sx={{
                mt: 0,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputLabel-root': {
                  fontSize: '10px',
                  color: 'var(--primary-text-color)',
                },
                '& .MuiInputBase-root': {
                  maxHeight: '35px',
                  color: 'var(--primary-text-color)',
                },
              }}
              fullWidth
              InputLabelProps={{
                shrink: false,
              }}
              inputProps={{
                maxLength: 1000, // maximum input length
              }}
              autoComplete="off"
              id="comment-text"
              placeholder="Add a comment"
              name="comment"
              label=""
              value={comment}
              onChange={e => setComment(e.target.value)}
            />

            <Box className={cx(styles.emojis)}>
              {!isMobile && showButtons && (
                <Reactions
                  onEmojiClick={e => {
                    setComment(prev => prev + e.emoji);
                  }}
                />
              )}
            </Box>
          </Box>
          {showButtons && (
            <Box className={styles.buttons}>
              <StyledButton
                sx={{ borderColor: 'white' }}
                cancel="true"
                onClick={handleCancel}
              >
                Cancel
              </StyledButton>
              <StyledButton disabled={loading} type="submit">
                Comment
              </StyledButton>
            </Box>
          )}
        </Box>
      </Stack>
    </form>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.nft.nftId === nextProps.nft.nftId &&
    prevProps.user.userId === nextProps.user.userId &&
    prevProps.parentId === nextProps.parentId &&
    prevProps.isReplying === nextProps.isReplying
  );
};

export default memo(AddComment, areEqual);
