import React, { useState, useEffect, useRef } from 'react';
import styles from './../styles.module.scss';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { useApi } from 'api';
import showToast from 'utils/toast';
import AuthGrid from '../AuthGrid';
import { StyledButton } from 'components/StyledComponents';
import PasswordChecklist from 'react-password-checklist';
import PasswordInput from 'components/Input/PasswordInput';

const NewPassword = ({ isRegister }) => {
  const { postSetNewPassword } = useApi();
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(null);
  const [reseting, setReseting] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const confirmButton = useRef(null);
  const [isValid, setIsValid] = useState(false);
  const onClose = () => {
    window.scrollTo(0, 0);
    history.push('/');
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [isLoading]);

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        confirmButton.current.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const validateNewPassword = () => {
    if (newPassword.length === 0) {
      setNewPasswordError("New Password field can't be blank");
    } else {
      setNewPasswordError(null);
    }
  };
  const validateConfirmNewPassword = () => {
    if (confirmNewPassword.length === 0) {
      setConfirmNewPasswordError("Confirm New Password field can't be blank");
    } else if (confirmNewPassword !== newPassword) {
      setConfirmNewPasswordError(
        'Field New Password and Confirm New Password should match'
      );
    } else {
      setConfirmNewPasswordError(null);
    }
  };
  const handleSetPassword = async () => {
    if (reseting) return;
    try {
      const response = await postSetNewPassword(newPassword, token);
      if (response?.data?.message === 'OK') {
        window.scrollTo(0, 0);
        history.push('/');
      } else if (response?.error) {
        showToast('error', response.error);
      }
    } catch (error) {
      showToast('error', error);
    } finally {
      setReseting(false);
    }
  };
  useEffect(() => {
    setNewPassword('');
    setConfirmNewPassword('');
    setNewPasswordError(null);
    setConfirmNewPasswordError(null);
  }, [isRegister]);
  return (
    <AuthGrid>
      <form className={styles.authForm}>
        <div className={styles.title}>Change Password</div>
        <div className={styles.subTitle}>Create your new password to login</div>
        <div className={styles.inputGroup}>
          <div className={styles.inputWrapper}>
            <PasswordInput
              label="Enter new password*"
              hasError={newPasswordError}
              placeholder=""
              value={newPassword}
              onChange={e => {
                setNewPassword(e.target.value);
              }}
              onBlur={validateNewPassword}
            />
            {newPasswordError && (
              <div className={styles.error}>{newPasswordError}</div>
            )}
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.inputWrapper}>
            <PasswordInput
              label="Confirm password*"
              hasError={confirmNewPasswordError}
              placeholder=""
              value={confirmNewPassword}
              onChange={e => setConfirmNewPassword(e.target.value)}
              onBlur={validateConfirmNewPassword}
            />
            {confirmNewPasswordError && (
              <div className={styles.error}>{confirmNewPasswordError}</div>
            )}
          </div>
        </div>
        <PasswordChecklist
          rules={['minLength', 'number', 'capitalAndLowercase', 'match']}
          minLength={6}
          value={newPassword}
          valueAgain={confirmNewPassword}
          onChange={isValid => {
            setIsValid(isValid);
          }}
          className={styles.passwordCheckList}
        />
        <div className={styles.buttonsWrapper}>
          <StyledButton
            className={cx(styles.submitButton, !isValid && styles.disabled)}
            onClick={isValid ? handleSetPassword : null}
            disabled={reseting || !isValid}
            ref={confirmButton}
          >
            {reseting ? <ClipLoader color="#FFF" size={16} /> : 'Save'}
          </StyledButton>
          <div className={styles.formLabelRow}>
            <div className={styles.labelLink} onClick={onClose}>
              Cancel
            </div>
          </div>
        </div>
      </form>
    </AuthGrid>
  );
};
export default NewPassword;
