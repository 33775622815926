import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const GradientCircularProgress = props => {
  return (
    <div {...props}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient
            id="circular-gradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#FFF000" />
            <stop offset="33%" stopColor="#D900D9" />
            <stop offset="67%" stopColor="#4B0082" />
            <stop offset="100%" stopColor="#00B0FF" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ 'svg circle': { stroke: 'url(#circular-gradient)' } }}
        size={props.size || '2rem'}
      />
    </div>
  );
};

export default GradientCircularProgress;
