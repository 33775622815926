import React from 'react';

const IconShare = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2221 5.8127V2.72559L13.5795 6.08304L16.2715 8.77499L13.5317 11.0582L10.2221 13.8165V10.7957C4.41163 10.3578 2.37591 13.7202 2.37591 13.7202C2.37591 11.6253 2.54852 9.45118 4.19551 7.80491C6.10997 5.88973 8.88253 5.75421 10.2221 5.8127Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};

export default IconShare;
